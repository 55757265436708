import DebtHeloanConsolidateOfferPopup from "../../modules/home-value/personalized-offers-v2/debt-heloan-consolidate-offer/debt-heloan-consolidate-offer-popup";
import { buildModal, updateModal } from "../../stores/modal-slice";
import { OfferModalConfig } from "../../helpers/modal-configs";
import { dispatch } from "../../stores";

import { GenericBaseModalProps } from "../../atomic/organisms/modal/generic-base-modal";

const useDebtHeloanConsolidateOfferPopup = ({
  isDetails = true,
}: {
  isDetails?: boolean;
} = {}) => {
  const buildDebtHeloanConsolidateOfferPopup = ({
    isFromModal = false,
  }: {
    isFromModal?: boolean;
  } = {}) => {
    const modalProps: GenericBaseModalProps = {
      children: <DebtHeloanConsolidateOfferPopup isDetails={isDetails} />,
    };

    dispatch(
      isFromModal
        ? updateModal({ ...modalProps })
        : buildModal({ ...OfferModalConfig, ...modalProps })
    );
  };

  return { buildDebtHeloanConsolidateOfferPopup };
};

export default useDebtHeloanConsolidateOfferPopup;
