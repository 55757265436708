import { PlayButton } from "ui";

import { getYoutubeVideoHQThumbnail } from "../../../helpers/utils";
import { LAKEVIEW_VIDEO_URL } from "../../../helpers/constants";

const LakeviewVideoThumbnailV2 = ({ onClick }: { onClick: () => void }) => {
  const thumbnailUrl = getYoutubeVideoHQThumbnail(LAKEVIEW_VIDEO_URL);

  return (
    <div
      className="tw-relative tw-cursor-pointer tw-w-full tw-rounded tw-overflow-hidden"
      onClick={onClick}
    >
      <img
        src={thumbnailUrl}
        alt="Video thumbnail"
        className="tw-object-cover tw-w-full tw-h-full"
      />
      <div className="tw-absolute tw-inset-0 tw-bg-[rgba(0,0,0,0.15)] tw-flex tw-items-center tw-justify-center">
        <PlayButton />
      </div>
    </div>
  );
};

export default LakeviewVideoThumbnailV2;
