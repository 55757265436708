import { Suspense } from "react";
import { lazyWithRefresh } from "../../../helpers/utils";
import usePopupRules from "../../../hooks/use-popup-rules";

const TermsAndConditionsModal = lazyWithRefresh(
  () => import("../../../atomic/organisms/terms-and-conditions-modal"),
  "terms-and-conditions-modal"
);

const InitialTermsConditions = () => {
  const { shouldShowInitialTermsAndConditionsModal } = usePopupRules();

  return (
    shouldShowInitialTermsAndConditionsModal && (
      <Suspense fallback={null}>
        <TermsAndConditionsModal isUpdatedTermsAndConditions />
      </Suspense>
    )
  );
};

export default InitialTermsConditions;
