// Include our external dependencies.
import { useSelector } from "react-redux";
import type { RootState } from "../../stores";
import Alert from "react-bootstrap/Alert";
import { translate as t } from "../../helpers/i18n";

// - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -|
// <Banners> Component
// - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -|
const Banners: React.FC = () => {
  const banners = useSelector((state: RootState) => state.banner.banners);

  return (
    <>
      {banners.map((banner) => (
        <Alert
          key={banner.hed}
          variant="warning"
          dismissible
          className="fade show mb-0 py-0"
          role="alert"
        >
          <div className="align-items-center tw-container d-flex">
            <div>
              <strong>{banner.hed}</strong> You should check in on some of those
              fields below.
            </div>
            <button
              type="button"
              className="btn-close position-relative ms-auto"
              data-bs-dismiss="alert"
              aria-label={t("accessibility.close")}
            ></button>
          </div>
        </Alert>
      ))}
    </>
  );
};

export default Banners;
