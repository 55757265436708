import { useState, useEffect } from "react";
import { FEATURE_GATES, useFeatureGate } from "./use-feature-gate";
import { Notification } from "../types";
import {
  CURRENT_SESSION_POPUP,
  NOTIFICATION_TYPES,
  POPUP_TYPES,
} from "../helpers/constants";
import { useSelector } from "react-redux";
import { RootState } from "../stores";
import { pages } from "../helpers/pages";
import { useSSOValues } from "./use-sso-values";
import { useLocation } from "react-router-dom";
import { getTermsVersions, isMajorVersionDifferent } from "../helpers/utils";

const usePopupRules = () => {
  const {
    user: { user: userData },
    personalizeOffers: personalizeOffersState,
  } = useSelector((state: RootState) => state);
  const { pathname } = useLocation();
  const { isPendingFastlaneAccount } = useSSOValues();
  const { notifications = [] } = useSelector(
    (state: RootState) => state.notifications
  );
  const { showVideoPopup } = useSelector(
    (state: RootState) => state.lakeviewVideoState
  );
  const isHomePage = pathname === pages.home;
  const { userTermsVersion, latestTermsVersion } = getTermsVersions(userData);

  /**
   * SSO Banner conditions
   * - Is first time SSO user
   * - Is the home page
   * - Has pending account
   */
  const [shouldShowSSOBanner, setShouldShowSSOBanner] =
    useState<boolean>(false);

  useEffect(() => {
    const showSSOBanner =
      userData?.is_first_time_sso && isHomePage && isPendingFastlaneAccount;

    if (!!showSSOBanner) {
      sessionStorage.setItem(CURRENT_SESSION_POPUP, POPUP_TYPES.SSO_BANNER);
    }

    setShouldShowSSOBanner(!!showSSOBanner);
  }, [isPendingFastlaneAccount, isHomePage, userData?.is_first_time_sso]);

  /**
   * Initial T&C modal conditions
   * - Has not a pending account
   * - Has user data
   * - Is a new terms version
   */
  const [
    shouldShowInitialTermsAndConditionsModal,
    setShouldShowInitialTermsAndConditionsModal,
  ] = useState<boolean>(false);

  useEffect(() => {
    const showInitialTermsAndConditionsModal =
      !isPendingFastlaneAccount &&
      userData &&
      isMajorVersionDifferent(latestTermsVersion, userTermsVersion);

    setShouldShowInitialTermsAndConditionsModal(
      !!showInitialTermsAndConditionsModal
    );
  }, [
    isPendingFastlaneAccount,
    latestTermsVersion,
    shouldShowSSOBanner,
    userData,
    userTermsVersion,
  ]);

  /**
   * Personalized Offers Popup conditions
   * - Is showOfferPopup flag from the store enabled
   */
  const [
    shouldShowPersonalizedOffersPopup,
    setShouldShowPersonalizedOffersPopup,
  ] = useState<boolean>(false);

  useEffect(() => {
    const showPersonalizedOffersPopup =
      !!personalizeOffersState?.showOfferPopup &&
      // Popup dependent conditions
      !shouldShowSSOBanner &&
      !shouldShowInitialTermsAndConditionsModal;

    if (showPersonalizedOffersPopup) {
      sessionStorage.setItem(
        CURRENT_SESSION_POPUP,
        POPUP_TYPES.PERSONALIZED_OFFERS_POPUP
      );
    }

    setShouldShowPersonalizedOffersPopup(showPersonalizedOffersPopup);
  }, [
    shouldShowSSOBanner,
    shouldShowInitialTermsAndConditionsModal,
    personalizeOffersState?.showOfferPopup,
  ]);

  /**
   * Lakeview Video Popup conditions
   * - ENABLE_LAKEVIEW_VIDEO_BANNER Feature Gate
   * - VIDEO_LAKEVIEW_INTRO_POPUP notification
   * - Is showVideoPopup flag from the store enabled
   * - Is the home page
   */
  const [shouldShowVideoPopup, setShouldShowVideoPopup] =
    useState<boolean>(false);

  const isVideoPopupEnabled = useFeatureGate(
    FEATURE_GATES.ENABLE_LAKEVIEW_VIDEO_BANNER
  );

  const isVideoPopupNotification = notifications?.some(
    (notification: Notification) =>
      notification.notification_type ===
      NOTIFICATION_TYPES.VIDEO_LAKEVIEW_INTRO_POPUP
  );

  useEffect(() => {
    const currentSessionPopup = sessionStorage.getItem(CURRENT_SESSION_POPUP);

    const showVideo =
      isVideoPopupEnabled &&
      isVideoPopupNotification &&
      showVideoPopup &&
      isHomePage &&
      // Popup dependent conditions
      !shouldShowSSOBanner &&
      !shouldShowInitialTermsAndConditionsModal &&
      !shouldShowPersonalizedOffersPopup &&
      personalizeOffersState?.refinanceStatus !== "loading" &&
      (!currentSessionPopup || currentSessionPopup === POPUP_TYPES.VIDEO_POPUP);

    if (showVideo) {
      sessionStorage.setItem(CURRENT_SESSION_POPUP, POPUP_TYPES.VIDEO_POPUP);
    }

    setShouldShowVideoPopup(showVideo);
  }, [
    isVideoPopupEnabled,
    isVideoPopupNotification,
    showVideoPopup,
    isHomePage,
    shouldShowSSOBanner,
    shouldShowInitialTermsAndConditionsModal,
    shouldShowPersonalizedOffersPopup,
    personalizeOffersState?.refinanceStatus,
  ]);

  return {
    shouldShowSSOBanner,
    shouldShowInitialTermsAndConditionsModal,
    shouldShowPersonalizedOffersPopup,
    shouldShowVideoPopup,
  };
};

export default usePopupRules;
