import { useEffect } from "react";
import { MIXPANEL_EVENTS } from "analytics";
import mixpanel from "mixpanel-browser";
import { Link } from "react-router-dom";
import { Trans } from "react-i18next";
import { UITheme } from "ui";

import { translate as t } from "../../../helpers/i18n";
import { kebabCase } from "../../../helpers/utils";

import { LakeviewEventParams } from "../../../components/confirm-leave-modal";
import ModalButtonsWrapper from "../modal/components/modal-buttons-wrapper";
import GenericBaseModal from "../modal/generic-base-modal";
import AfBAContentV2 from "../afba/afba-content-v2";
import { Button } from "../../atoms/button";

import { ABADModalType } from "../abad-dialog";

type ABADModality = "basic" | ABADModalType;

export interface AcceptAfBAModalProps {
  show: boolean;
  onCancel?: () => void;
  onAccept?: () => void;
  isLoading?: boolean;
  modality?: ABADModality;
  shouldClearModalState?: boolean;
  eventParams?: LakeviewEventParams;
}

const AcceptAfBAModaV2 = ({
  show,
  onCancel,
  onAccept,
  isLoading,
  shouldClearModalState,
  modality = "basic",
  eventParams = {},
}: AcceptAfBAModalProps) => {
  const disclosurePDFPath = "/assets/afba/Afba_Disclosure.pdf";

  const handleDisclosureClick = () => {
    const updateEventParams = {
      ...eventParams,
      link: `${process.env.REACT_APP_DOMAIN}${disclosurePDFPath}`,
    };

    mixpanel.track(MIXPANEL_EVENTS.CLICK, {
      ...updateEventParams,
      module: "abad-popup",
      button: "disclosure-pdf",
    });
  };

  const cancelButtonText =
    modality === "lhis_insurance_ad_abad" || modality === "lkv_realty_abad"
      ? t("afba.content.cancel")
      : t("afba_modal.cancel_button");

  const renderABADExtraContent = () => {
    const acknowledgement = (trans) => (
      <p className="tw-pt-4 tw-text-primary">
        <Trans i18nKey={trans} components={{ b: <b /> }} />
      </p>
    );

    switch (modality) {
      case "lhis_insurance_ad_abad":
        return acknowledgement("afba.content.acknowledgement_insurance");
      case "lkv_realty_abad":
        return acknowledgement("afba.content.acknowledgement_lkv_realty");
      default:
        return null;
    }
  };

  useEffect(() => {
    if (show) {
      const button =
        eventParams?.view_source === "application-lifecycle" ||
        eventParams?.view_source === "personal-goals"
          ? {
              button: eventParams?.button,
            }
          : {};
      const step = eventParams?.step ? { step: eventParams?.step } : {};
      mixpanel.track(MIXPANEL_EVENTS.MODULE_SERVED, {
        module: "abad-popup",
        path: eventParams?.path,
        view_source: eventParams?.view_source,
        ...button,
        ...step,
      });
    }
  }, [show, eventParams]);

  const handleOnAccept = () => {
    if (typeof onAccept === "function") {
      mixpanel.track(MIXPANEL_EVENTS.CLICK, {
        ...eventParams,
        module: "abad-popup",
        button: "agree",
      });
      onAccept();
    }
  };

  const handleCloseClick = () => {
    if (typeof onCancel === "function") {
      mixpanel.track(MIXPANEL_EVENTS.CLICK, {
        ...eventParams,
        module: "abad-popup",
        button: "close",
      });
      onCancel();
    }
  };

  const handleCancelClick = () => {
    if (typeof onCancel === "function") {
      mixpanel.track(MIXPANEL_EVENTS.CLICK, {
        ...eventParams,
        module: "abad-popup",
        button: kebabCase(cancelButtonText),
      });
      onCancel();
    }
  };
  return (
    <GenericBaseModal
      show={show}
      title={
        <>
          <span className="md:tw-hidden">{t("afba_modal.mobile_title")}</span>
          <span className="tw-hidden md:tw-block">
            {t("afba_modal.desktop_title")}
          </span>
        </>
      }
      titleClassName="tw-text-h3 tw-font-serif tw-font-normal"
      onClose={handleCloseClick}
      onCloseButtonClick={handleCloseClick}
      gridSize={10}
      fullscreen="md-down"
      isLoading={isLoading}
      variant
      buttons={
        <>
          <div className="tw-pb-6 tw-text-primary tw-font-sans">
            <Trans
              i18nKey="afba.content.acknowledgement"
              components={{
                b: <b />,
                br: <br />,
                pdf: (
                  <Link
                    to={disclosurePDFPath}
                    onClick={handleDisclosureClick}
                    target="_blank"
                    rel="noreferrer"
                    className="tw-text-link-rest"
                  />
                ),
              }}
            />
            {renderABADExtraContent()}
          </div>
          <ModalButtonsWrapper>
            <Button
              variant="primary"
              onClick={handleOnAccept}
              isLoading={!!isLoading}
              theme={UITheme.LAKEVIEW_BLUE}
            >
              {t("afba_modal.agree_button")}
            </Button>
            <Button
              variant="secondary"
              onClick={handleCancelClick}
              theme={UITheme.LAKEVIEW_BLUE}
            >
              {cancelButtonText}
            </Button>
          </ModalButtonsWrapper>
        </>
      }
      shouldClearModalState={shouldClearModalState}
    >
      <AfBAContentV2 />
    </GenericBaseModal>
  );
};

export default AcceptAfBAModaV2;
