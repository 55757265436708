import { Trans } from "react-i18next";
import { Link, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../../stores";
import { ExternalLink } from "../../../components/external-link";
import { translate as t } from "../../../helpers/i18n";
import { pages } from "../../../helpers/pages";
import { useIsUserAuthenticated } from "../../../hooks/use-is-user-authenticated";
import AccessibilityAdaComplianceV2 from "./accessibility-ada-compliance-v2";
import { useEffect } from "react";
import mixpanel from "mixpanel-browser";
import { MIXPANEL_EVENTS } from "analytics";
import { useDashboardVersion } from "../../../hooks";
import NMLSFooterV2 from "./nmls-footer-v2";

const LegalDisclaimerV2 = () => {
  const { pathname } = useLocation();
  const isUserAuthenticated = useIsUserAuthenticated();
  const { user: userState } = useSelector((state: RootState) => state);
  const { user } = userState;
  const { mixpanelPageName } = useDashboardVersion();

  const isDigitalAccountCreationFlow =
    pathname === pages.digitalAccountCreation;
  const isDigitalAccount =
    user?.disclaimer_acceptances?.DIGITAL_ACCOUNT_TERMS_AND_CONDITIONS?.version;
  const showDigitalAccountDisclaimers =
    isDigitalAccountCreationFlow || isDigitalAccount;

  const disclosure = showDigitalAccountDisclaimers
    ? "digital_account"
    : isUserAuthenticated
      ? "authenticated"
      : "unauthenticated";

  useEffect(() => {
    mixpanel.track(MIXPANEL_EVENTS.MODULE_SERVED, {
      disclosure,
      module: "footer",
    });
  }, [disclosure]);

  const handleLinkClick = (button: string) => {
    mixpanel.track(MIXPANEL_EVENTS.CLICK, {
      disclosure,
      button,
      module: "footer",
      page: mixpanelPageName,
    });
  };

  return (
    <div className="tw-flex tw-flex-col tw-gap-y-12">
      <div className="tw-mb-0 tw-text-small tw-text-deepBlue-950 [&_a]:tw-text-deepBlue-625">
        <Trans
          i18nKey={`layout.footer.legal_disclaimer_cobranded.${disclosure}.content`}
          values={{
            lakeviewTel: t(
              "layout.footer.legal_disclaimer_cobranded.lakeviewTel"
            ),
            nmlsLink: t("layout.footer.legal_disclaimer_cobranded.nmlsHref"),
          }}
          components={{
            lakeviewLink: (
              <ExternalLink
                module="legal-disclaimer"
                button="lakeview"
                href={t(
                  "layout.footer.legal_disclaimer_cobranded.lakeviewHref"
                )}
                targetBlank
              />
            ),
            nmlsLinkTag: (
              <ExternalLink
                className="text-break"
                module="legal-disclaimer"
                button="nmls"
                href={t("layout.footer.legal_disclaimer_cobranded.nmlsHref")}
                targetBlank
              />
            ),
            llsLink: (
              <Link
                to={pages.index}
                onClick={() => handleLinkClick("lls-index")}
              />
            ),
            termsLink: (
              <Link
                to={pages.termsConditions}
                onClick={() => handleLinkClick("terms-and-conditions")}
              />
            ),
          }}
          shouldUnescape
        />
      </div>
      <hr className="tw-w-full tw-text-slateGray-125 tw-opacity-100 tw-m-0" />
      <div className="tw-flex tw-flex-col md:tw-flex-row md:tw-justify-between tw-gap-y-6">
        <AccessibilityAdaComplianceV2 />
        <NMLSFooterV2 />
      </div>
    </div>
  );
};

export default LegalDisclaimerV2;
