import { ReactComponent as AdaAccessibilitySVG } from "../../../images/ada-accessibility.svg";
import { Link } from "react-router-dom";
import { pages } from "../../../helpers/pages";
import { translate as t } from "../../../helpers/i18n";

const AccessibilityAdaComplianceV2 = () => (
  <div className="tw-flex tw-flex-col tw-gap-2">
    <AdaAccessibilitySVG className="tw-text-slateGray-625" />
    <Link
      className="tw-text-small tw-text-deepBlue-625"
      to={pages.adaAccessibility}
    >
      {t("layout.footer.ada_accessibility.title")}
    </Link>
  </div>
);

export default AccessibilityAdaComplianceV2;
