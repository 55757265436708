// int-numberformat dependencies (support for ios v13)
import "@formatjs/intl-locale/polyfill";
import "@formatjs/intl-getcanonicallocales/polyfill";
// int-pluralrules dependencies (support for ios v12)
import "@formatjs/intl-pluralrules/polyfill";
import "@formatjs/intl-pluralrules/locale-data/en";
/*
  Note: `notation` & `compactDisplay` properties are not supported in some version of Safari.
  Those require the use of a polyfill: https://www.npmjs.com/package/@formatjs/intl-numberformat
*/
import "@formatjs/intl-numberformat/polyfill";
import "@formatjs/intl-numberformat/locale-data/en";

const EMPTY_CURRENCY_PLACEHOLDER = "-";

export const mortgageValueCurrencyFormat = {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
};

const currencyFormatter = (options = {}) =>
  new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
    ...options,
  });

const rateFormatter = (options = {}) =>
  new Intl.NumberFormat("en-US", {
    style: "percent",
    minimumFractionDigits: 3,
    maximumFractionDigits: 3,
    ...options,
  });

export const formatCurrency = (
  value: number | undefined,
  scdValue?: number,
  options?: object
) => {
  if (typeof value !== "number") {
    return EMPTY_CURRENCY_PLACEHOLDER;
  }

  const realValue = value / 100;
  return currencyFormatter(options).format(
    scdValue ? realValue / scdValue : realValue
  );
};

export const formatRate = (value: number | undefined, options?: object) => {
  if (typeof value !== "number") {
    return EMPTY_CURRENCY_PLACEHOLDER;
  }

  const realValue = value / 100000;
  return rateFormatter(options).format(realValue);
};

/**
 * If someone edits a formatted input field, this is what it would look
 * like without this util:
 *
 * $4,000 (type in 5) --> $4,0005
 *
 * And after pushing the new value through this function,
 *
 * $4,000 (type in 5) --> $40,005
 *
 * It also sanitizes non-numeric characters from the input.
 *
 * @param value {string} will likely be fed from event.target.value
 * @returns {string} a formatted value
 */
export const updateCurrencyInput = (value: string) => {
  const sanitizedValue = value.replace(/[^0-9.]/g, "");
  const valueWithCents = `${sanitizedValue}00`; // the currency formatter will assume that the last two digits are cents, and crop them
  const parsedValue = Number(valueWithCents);
  return formatCurrency(parsedValue);
};
