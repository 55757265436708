import { Trans } from "react-i18next";
import { PASSWORD_MIN_LENGTH } from "../../helpers/constants";
import { translate as t } from "../../helpers/i18n";
import usePasswordValidation from "../../hooks/use-password-validation";
import { cn } from "ui";

export interface PasswordChecklistProps {
  password: string;
}

const PasswordChecklistV2 = ({ password }: PasswordChecklistProps) => {
  const {
    hasLength,
    hasAtLeast3Conditions,
    hasLowercase,
    hasUpperCase,
    hasNumber,
    hasSpecialChar,
  } = usePasswordValidation(password);

  const handlePasswordRequirementsClasses = (condition: boolean) =>
    cn("tw-mb-0", condition ? "tw-text-coreGreen-625" : "tw-text-deepBlue-950");

  return (
    <div className="tw-col-span-12 tw-border tw-border-solid tw-border-slateGray-125 tw-rounded tw-p-4 tw-bg-white">
      <p className="tw-mb-0 tw-text-deepBlue-950">
        {t("password_validation.password_checklist.contain")}
      </p>
      <p className={handlePasswordRequirementsClasses(hasLength)}>
        <Trans
          i18nKey="password_validation.password_checklist.min_length"
          values={{
            minLength: PASSWORD_MIN_LENGTH,
            check: t("password_validation.password_checklist.check", {
              count: +hasLength,
            }),
          }}
        />
      </p>
      <p className={handlePasswordRequirementsClasses(hasAtLeast3Conditions)}>
        <Trans
          i18nKey="password_validation.password_checklist.extra_checklist.at_least"
          values={{
            check: t("password_validation.password_checklist.check", {
              count: +hasAtLeast3Conditions,
            }),
          }}
        />
      </p>
      <p
        className={`${handlePasswordRequirementsClasses(hasLowercase)} tw-ml-4`}
      >
        <Trans
          i18nKey="password_validation.password_checklist.extra_checklist.lower_cases"
          values={{
            check: t("password_validation.password_checklist.check", {
              count: +hasLowercase,
            }),
          }}
        />
      </p>
      <p
        className={`${handlePasswordRequirementsClasses(hasUpperCase)} tw-ml-4`}
      >
        <Trans
          i18nKey="password_validation.password_checklist.extra_checklist.upper_cases"
          values={{
            check: t("password_validation.password_checklist.check", {
              count: +hasUpperCase,
            }),
          }}
        />
      </p>
      <p className={`${handlePasswordRequirementsClasses(hasNumber)} tw-ml-4`}>
        <Trans
          i18nKey="password_validation.password_checklist.extra_checklist.numbers"
          values={{
            check: t("password_validation.password_checklist.check", {
              count: +hasNumber,
            }),
          }}
        />
      </p>
      <p
        className={`${handlePasswordRequirementsClasses(hasSpecialChar)} tw-ml-4`}
      >
        <Trans
          i18nKey="password_validation.password_checklist.extra_checklist.special_chars"
          values={{
            check: t("password_validation.password_checklist.check", {
              count: +hasSpecialChar,
            }),
          }}
        />
      </p>
    </div>
  );
};

export default PasswordChecklistV2;
