import dashApi from "../helpers/dash-api";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  AsyncStatus,
  HomeRenovationPlan,
  HomeRenovationPlanRequest,
} from "../types";

// - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -|
// Endpoints
// - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -|
export const postHomeRenovationPlan = dashApi
  .path("/renovations/v1/plans")
  .method("post")
  .create();

export const getHomeRenovationPlan = dashApi
  .path("/renovations/v1/plans")
  .method("get")
  .create();

// - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -|
// State
// - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -|
export interface HomeRenovationPlanState {
  plan: HomeRenovationPlan | undefined;
  status: AsyncStatus;
  initialized: boolean;
}

const initialHomeRenovationPlanState: HomeRenovationPlanState = {
  plan: undefined,
  status: "idle",
  initialized: false,
};

export interface HomeRenovationExitModalState {
  isExitModalVisible?: boolean;
  isExitModalAvailable?: boolean;
}
const initialHomeRenovationExitModalState: HomeRenovationExitModalState = {
  isExitModalVisible: false,
  isExitModalAvailable: true,
};

type HomeRenovationCombinedState = HomeRenovationPlanState &
  HomeRenovationExitModalState;

const initialState: HomeRenovationCombinedState = {
  ...initialHomeRenovationPlanState,
  ...initialHomeRenovationExitModalState,
};

// - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -|
// API
// - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -|
const SLICE_NAME = "homeRenovationPlan";
const ACTION_TYPES = {
  CREATE: `${SLICE_NAME}/createPlan`,
  FETCH: `${SLICE_NAME}/getPlan`,
} as const;

interface ApiResponse<T> {
  data: T;
}

export const createHomeRenovationPlan = createAsyncThunk(
  ACTION_TYPES.CREATE,
  async (props: HomeRenovationPlanRequest, { rejectWithValue }) => {
    try {
      const response = await postHomeRenovationPlan(props);
      return response.data;
    } catch (error) {
      return rejectWithValue({ status: error.status });
    }
  }
);

export const fetchHomeRenovationPlan = createAsyncThunk<HomeRenovationPlan>(
  ACTION_TYPES.FETCH,
  async (_, { rejectWithValue }) => {
    try {
      const response: ApiResponse<HomeRenovationPlan> =
        await getHomeRenovationPlan({});
      return response.data;
    } catch (error) {
      if (error.status === 404) {
        return rejectWithValue({ status: 404, showToast: false });
      }
      return rejectWithValue({ status: error.status });
    }
  }
);

// - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -|
// Slices
// - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -|
export const homeRenovationSlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    updateHomeRenovationExitModalState(
      state,
      action: PayloadAction<HomeRenovationExitModalState>
    ) {
      Object.assign(state, action.payload);
    },
  },
  extraReducers: (builder) => {
    const setPending = (state: HomeRenovationPlanState) => {
      state.status = "loading";
    };

    const setFulfilled = (
      state: HomeRenovationPlanState,
      action: PayloadAction<HomeRenovationPlan>
    ) => {
      state.status = "loaded";
      state.initialized = true;
      state.plan = action.payload;
    };

    const setRejected = (state: HomeRenovationPlanState) => {
      state.status = "failed";
      state.plan = undefined;
    };

    builder
      .addCase(createHomeRenovationPlan.pending, setPending)
      .addCase(createHomeRenovationPlan.fulfilled, setFulfilled)
      .addCase(createHomeRenovationPlan.rejected, setRejected)
      .addCase(fetchHomeRenovationPlan.pending, setPending)
      .addCase(fetchHomeRenovationPlan.fulfilled, setFulfilled)
      .addCase(fetchHomeRenovationPlan.rejected, setRejected);
  },
});

export const { updateHomeRenovationExitModalState } =
  homeRenovationSlice.actions;
