import { useSelector } from "react-redux";
import { DashboardType } from "../types";
import { RootState } from "../stores";
import { useIsUserAuthenticated } from "./use-is-user-authenticated";

/**
 * This hook contains business logic to determine what kind of dashboard is
 * being rendered.
 * User primarily for analytics reporting purposes
 * Accurate as of August 2024; the terms & conditions / account
 * structure is likely to change going forward
 */
export const useDashboardVersion = (): {
  mixpanelPageName: `/${DashboardType}`;
  dashboardVersion: DashboardType;
} => {
  const { user } = useSelector((state: RootState) => state.user);
  const isUserAuthenticated = useIsUserAuthenticated();

  const isDigitalAccount =
    user?.disclaimer_acceptances?.DIGITAL_ACCOUNT_TERMS_AND_CONDITIONS?.version;

  /** business rules for dashboard classification */
  const isUnauthDashboard = !isUserAuthenticated;
  const isDigitalHomepage = isDigitalAccount && !user.logged_in_with_sso;
  const isPreviewDashboard =
    !user?.disclaimer_acceptances?.TERMS_AND_CONDITIONS?.version;
  const isPreviewDashboardPlus =
    isPreviewDashboard && isDigitalAccount && user.logged_in_with_sso;

  let dashboardVersion: DashboardType = "full-dashboard";

  if (isUnauthDashboard) {
    dashboardVersion = "unauth-dashboard";
  } else if (isDigitalHomepage) {
    dashboardVersion = "digital-homepage";
  } else if (isPreviewDashboardPlus) {
    dashboardVersion = "preview-dashboard-plus";
  } else if (isPreviewDashboard) {
    dashboardVersion = "preview-dashboard";
  }

  return {
    mixpanelPageName: `/${dashboardVersion}`,
    dashboardVersion,
  };
};
