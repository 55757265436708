import { cx } from "class-variance-authority";
import { t } from "i18next";
import { ReactNode } from "react";
import { Trans } from "react-i18next";
import { Link } from "react-router-dom";
import { pages } from "../../../helpers/pages";
import BannerLetsGetStartedButton from "./banner-lets-get-started-button";
import { useSelector } from "react-redux";
import { RootState } from "../../../stores";
import FloatingCTABannerSection from "./floating-cta-banner-section";
import { cn } from "ui";
import FloatingCTABannerSectionV2 from "./floating-cta-banner-section-v2";

export type Steps = "introduction" | "password_setup";

export interface BannerContainerProps {
  show: boolean;
  showSteps: boolean;
  boxContent: ReactNode;
  extraContent?: ReactNode;
  step: Steps;
  isLakeviewV2Enabled?: boolean;
}

const TitleSection = ({
  showSteps,
  step,
  isLakeviewV2Enabled,
}: Partial<BannerContainerProps>) => (
  <>
    {showSteps && (
      <p
        className={cn(
          "typeface-body-small mb-4",
          isLakeviewV2Enabled ? " tw-uppercase" : ""
        )}
      >
        {t(`sso_banner.${step}.step`)}
      </p>
    )}
    <h1 className="typeface-h1 mb-0 ">
      <Trans i18nKey={`sso_banner.${step}.title_cobranded`} />
    </h1>
  </>
);

const BannerContainer = ({
  show,
  showSteps,
  boxContent,
  step,
  isLakeviewV2Enabled,
}: BannerContainerProps) => {
  const isFloatingButton = useSelector(
    (state: RootState) => state.app.isFloatingButton
  );
  const isLetsGetStartedButtonEnabled = step === "introduction";

  const LetsGetStartedButton = BannerLetsGetStartedButton(
    isLakeviewV2Enabled
      ? { buttonProps: { variant: "secondary", theme: "lakeview-blue" } }
      : {}
  );

  /**
   Since the SSO banner now displays two floating buttons when there's a current user with enabled mortgage payments:
   - The original floating button remains when Mortgage Payment button is disabled
   - The second button (Pay my mortgage) is handled in the Mortgage card component, as it requires all current account data
   */
  const isPaymentFloatingButtonEnabled = isFloatingButton;

  return (
    show && (
      <div
        className={cn(
          "sso-banner tw-text-white ",
          isLakeviewV2Enabled ? "tw-bg-gradient-brand" : "bg-gradient"
        )}
        data-testid="sso-banner-container"
      >
        <div className="container banner-container py-40px py-xl-7 px-xl-0 ">
          <div className="d-flex flex-column d-none d-md-block d-lg-none mb-5">
            <TitleSection
              showSteps={showSteps}
              step={step}
              isLakeviewV2Enabled={isLakeviewV2Enabled}
            />
          </div>
          <div className="d-flex justify-content-between flex-md-row flex-column gap-4 gap-md-6 gap-100px">
            <div
              className={cx(
                "d-flex flex-column gap-4 align-self-start align-self-md-center align-self-lg-start",
                !showSteps && "pt-xl-7"
              )}
            >
              <div className="d-block d-md-none d-lg-block">
                <TitleSection
                  showSteps={showSteps}
                  step={step}
                  isLakeviewV2Enabled={isLakeviewV2Enabled}
                />
              </div>
              <p className="m-0">
                <Trans
                  i18nKey={`sso_banner.${step}.description_cobranded`}
                  components={{
                    linkTag: (
                      <Link
                        className="tw-text-white fw-bold"
                        to={pages.index}
                      />
                    ),
                  }}
                />
              </p>
              {isLetsGetStartedButtonEnabled ? (
                <div className="d-none d-md-block pt-xl-3">
                  {LetsGetStartedButton}
                </div>
              ) : null}
            </div>
            <div
              className={cn(
                "password-input-container align-self-center tw-border tw-border-solid rounded-3 p-4 col-12 col-md-6 col-xl-5 ",
                isLakeviewV2Enabled
                  ? "tw-border-mutedBlue-600"
                  : "tw-border-primary-500"
              )}
            >
              {boxContent}
            </div>
          </div>
        </div>
        {isLetsGetStartedButtonEnabled && !isPaymentFloatingButtonEnabled && (
          <>
            {isLakeviewV2Enabled ? (
              <FloatingCTABannerSectionV2 step={step} />
            ) : (
              <FloatingCTABannerSection step={step} />
            )}
          </>
        )}
      </div>
    )
  );
};

export default BannerContainer;
