import { useStatsigClient } from "@statsig/react-bindings";
import { useCallback } from "react";
import {
  experimentManualExposure,
  ExperimentNameValue,
  NewExperimentNameGroup,
  NoneExperimentGroup,
} from "../helpers/experiment-exposure";

const useExperimentManualExposure = () => {
  const { client } = useStatsigClient();
  const logExposure = useCallback(
    (
      experimentName: ExperimentNameValue,
      experimentGroupName: NewExperimentNameGroup | NoneExperimentGroup,
      selectedGroupsToMakeExposure: (
        | NewExperimentNameGroup
        | NoneExperimentGroup
      )[],
      experimentConditionToMakeExposure: boolean = experimentGroupName !==
        "None"
    ) => {
      if (!client) return;
      experimentManualExposure(
        experimentName,
        experimentGroupName,
        selectedGroupsToMakeExposure,
        experimentConditionToMakeExposure,
        client
      );
    },
    [client]
  );
  return logExposure;
};

export default useExperimentManualExposure;
