/**
 * COMPONENT - Disclosure
 *
 * Display page disclosures inside footer section based on a state list
 */
import { useSelector } from "react-redux";
import { RootState } from "../../../stores";
import { useEffect, useState } from "react";
import { useLocation } from "react-router";
import ContentBlockOverlay from "../content-block-overlay";
import {
  DISCLOSURES,
  DISCLOSURES_TYPES,
  DisclosureTypeKey,
} from "./disclosures-content";
import { Trans } from "react-i18next";
import { BackLink } from "./disclosure-link";
import { sortDisclosures } from "../../../helpers/utils";
import { formatRate } from "src/helpers/currencyFormatter";

const sortedDisclosures = sortDisclosures(DISCLOSURES);

const DisclosuresV2 = () => {
  const location = useLocation();

  const [activeDisclosures, setActiveDisclosures] = useState<
    DisclosureTypeKey[]
  >([]);
  const { disclosures } = useSelector((state: RootState) => state.disclosures);

  const refinance = useSelector(
    (state: RootState) => state.personalizeOffers?.refinance
  );
  const offer_debt_new_apr = refinance?.offer_debt_new_apr;

  const debtHeloanConsolidateAPR = (activeDisclosure) =>
    activeDisclosure ===
    DISCLOSURES_TYPES.PERSONALIZED_OFFER_DEBT_HELOAN_CONSOLIDATE
      ? { apr: formatRate(offer_debt_new_apr) }
      : {};

  useEffect(() => {
    if (disclosures) {
      const activeDisclosures = Object.keys(sortedDisclosures).filter(
        (disclosure) => {
          const disclosureType = disclosure as DisclosureTypeKey;

          return (
            disclosures.includes(disclosureType) &&
            [DISCLOSURES[disclosureType].activePath]
              .flat()
              .includes(location.pathname)
          );
        }
      );
      setActiveDisclosures(activeDisclosures as DisclosureTypeKey[]);
    }
  }, [disclosures, location]);

  return (
    <ContentBlockOverlay>
      {activeDisclosures && activeDisclosures.length > 0 && (
        <div id="disclosure-content" className="tw-container tw-pt-16">
          {activeDisclosures.map((activeDisclosure) => (
            <p
              key={activeDisclosure}
              className="tw-mt-0 tw-text-fine tw-font-sans tw-text-deepBlue-950 [&_a]:tw-text-deepBlue-625"
            >
              <Trans
                i18nKey={DISCLOSURES[activeDisclosure].translationKey}
                components={{
                  backLink: <BackLink disclosureType={activeDisclosure} />,
                  br: <br />,
                  ...DISCLOSURES[activeDisclosure]?.components,
                }}
                values={{
                  ...(DISCLOSURES[activeDisclosure]?.values || {}),
                  ...debtHeloanConsolidateAPR(activeDisclosure),
                }}
              />
            </p>
          ))}
          <hr className="tw-w-full tw-text-slateGray-125 tw-opacity-100 tw-m-0 tw-mt-12" />
        </div>
      )}
    </ContentBlockOverlay>
  );
};

export default DisclosuresV2;
