import DisclosuresV2 from "../disclosures/disclosures-v2";
import { useSelector } from "react-redux";
import { RootState } from "../../../stores";

interface FooterBaseProps {
  children: React.ReactNode;
  includeDisclosures?: boolean;
}

const FooterBaseV2 = ({ children, includeDisclosures }: FooterBaseProps) => {
  const { heroButtonsVisible } = useSelector((state: RootState) => state.app);

  const extraSpaceClasses = !heroButtonsVisible
    ? "tw-pb-16 tw-mb-16 md:tw-mb-12"
    : "";

  return (
    <footer
      className={`tw-relative tw-bg-slateGray-50 tw-overflow-hidden ${extraSpaceClasses}`}
    >
      {includeDisclosures ? <DisclosuresV2 /> : null}
      <div className="tw-container tw-relative tw-flex tw-flex-col tw-items-start tw-gap-12 tw-my-12 tw-font-sans tw md:tw-mt-16">
        {children}
      </div>
    </footer>
  );
};

export default FooterBaseV2;
