import { Button } from "../../../atomic/atoms/button";
import { translate as t } from "../../../helpers/i18n";
import ModalButtonsWrapper from "../../../atomic/organisms/modal/components/modal-buttons-wrapper";
import mixpanel from "mixpanel-browser";
import { MIXPANEL_EVENTS } from "analytics";
import { dispatch } from "../../../stores";
import { closeModal } from "../../../stores/modal-slice";
import { LakeviewEventParams } from "../../../components/confirm-leave-modal";

interface Props {
  link?: string;
  subservicerName?: string;
  eventParams?: LakeviewEventParams;
}

const PayMortgageModalButtons = ({
  link,
  subservicerName,
  eventParams,
}: Props) => {
  const offerType = eventParams?.offer_type
    ? { offer_type: eventParams?.offer_type }
    : {};

  const handleCTAClick = () => {
    if (link) {
      mixpanel.track(MIXPANEL_EVENTS.CLICK, {
        button: "pay-my-mortgage-okay",
        module: "leave-dialog",
        path: eventParams?.module,
        link,
        subservicer_name: subservicerName,
        product: eventParams?.product,
        ...offerType,
      });

      window.open(link, "_blank");
    }
    dispatch(closeModal());
  };

  const handleCancelClick = () => {
    mixpanel.track(MIXPANEL_EVENTS.CLICK, {
      button: "pay-my-mortgage-cancel",
      module: "leave-dialog",
      path: eventParams?.module,
      subservicer_name: subservicerName,
      ...offerType,
    });

    dispatch(closeModal());
  };

  return (
    <ModalButtonsWrapper>
      <Button onClick={handleCTAClick}>
        {t("leaving_modal.subservicer_link.cta")}
      </Button>
      <Button variant="secondary" onClick={handleCancelClick}>
        {t("leaving_modal.subservicer_link.cancel")}
      </Button>
    </ModalButtonsWrapper>
  );
};

export default PayMortgageModalButtons;
