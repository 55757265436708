import { UILoader } from "ui";
import { translate as t } from "../helpers/i18n";

const Loader = () => (
  <div className="tw-h-screen tw-flex tw-flex-coln tw-items-center tw-justify-center tw-bg-surface-primary">
    <UILoader title={t("global.helpers.wait_a_moment")} size="lg" />
  </div>
);

export default Loader;
