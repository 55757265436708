import { useSelector } from "react-redux";
import { Trans } from "react-i18next";

import {
  PERSONALIZED_OFFER_DEBT_HELOAN_CONSOLIDATE_POPUP,
  PERSONALIZED_OFFER_DEBT_HELOAN_CONSOLIDATE_POPUP_ESTIMATED_AMOUNT,
  PERSONALIZED_OFFER_DEBT_HELOAN_CONSOLIDATE_POPUP_LOWN_AMOUNT,
} from "../../../../helpers/constants";
import { formatCurrency } from "../../../../helpers/currencyFormatter";
import { translate as t } from "../../../../helpers/i18n";
import { RootState } from "../../../../stores";

import DisclosureLink from "../../../layout/disclosures/disclosure-link";
import { GridCol, GridContainer, GridRow } from "ui";

const DISCLOSURE_24 = PERSONALIZED_OFFER_DEBT_HELOAN_CONSOLIDATE_POPUP;
const DISCLOSURE_25 =
  PERSONALIZED_OFFER_DEBT_HELOAN_CONSOLIDATE_POPUP_ESTIMATED_AMOUNT;
const DISCLOSURE_26 =
  PERSONALIZED_OFFER_DEBT_HELOAN_CONSOLIDATE_POPUP_LOWN_AMOUNT;

const DebtHeloanConsolidateOfferPopupDetails = () => {
  const refinance = useSelector(
    (state: RootState) => state.personalizeOffers?.refinance
  );

  const estimatedDebt = formatCurrency(
    refinance?.offer_debt_current_debt_balance
  );
  const monthlyPayment = formatCurrency(
    refinance?.offer_debt_current_debt_payment
  );
  const newLoanEstimatedDebt = formatCurrency(
    refinance?.offer_debt_new_loan_amount
  );
  const newLoanMonthlyPayment = formatCurrency(
    refinance?.offer_debt_new_monthly_payment
  );

  const offer_debt_monthly_savings = refinance?.offer_debt_monthly_savings;
  const offer_debt_yearly_savings = refinance?.offer_debt_yearly_savings;

  return (
    <section className="tw-w-full">
      <GridContainer containerClassName="tw-p-0">
        <GridCol
          xs={12}
          lg={8}
          className="tw-bg-surface-secondary tw-rounded-md tw-p-4 lg:tw-p-6"
        >
          <GridContainer containerClassName="tw-p-0">
            {/* Desktop view */}
            <GridCol className="tw-hidden lg:tw-grid">
              <GridRow className="tw-py-1">
                <GridCol xs={8} className="tw-text-end tw-font-bold">
                  {t(
                    "product_offers.personalized.heloan_debt_consolidate_popup.body.tables.estimated_debt"
                  )}
                </GridCol>
                <GridCol xs={4} className="tw-text-end tw-font-bold">
                  {t(
                    "product_offers.personalized.heloan_debt_consolidate_popup.body.tables.new_loan"
                  )}
                </GridCol>
              </GridRow>
              <GridRow className="tw-border tw-border-solid tw-border-0 tw-border-b tw-border-border-lines tw-mb-4 tw-py-1">
                <GridCol xs={4} className="tw-text-start">
                  {t(
                    "product_offers.personalized.heloan_debt_consolidate_popup.body.tables.estimated_debt_balance"
                  )}
                </GridCol>
                <GridCol
                  xs={4}
                  className="tw-flex tw-items-center tw-justify-end"
                >
                  <Trans
                    i18nKey="product_offers.personalized.heloan_debt_consolidate_popup.body.tables.value_disclosure"
                    values={{ amount: estimatedDebt }}
                    components={{
                      amountTag: <span />,
                      disclosureTag: (
                        <DisclosureLink
                          disclosureType={DISCLOSURE_25}
                          isModal
                        />
                      ),
                    }}
                  />
                </GridCol>
                <GridCol
                  xs={4}
                  className="tw-flex tw-items-center tw-justify-end"
                >
                  <Trans
                    i18nKey="product_offers.personalized.heloan_debt_consolidate_popup.body.tables.value_disclosure"
                    values={{
                      amount: newLoanEstimatedDebt,
                    }}
                    components={{
                      amountTag: <span className="tw-font-bold" />,
                      disclosureTag: (
                        <DisclosureLink
                          disclosureType={DISCLOSURE_26}
                          isModal
                        />
                      ),
                    }}
                  />
                </GridCol>
              </GridRow>
              <GridRow className="tw-py-1">
                <GridCol xs={4} className="tw-text-start">
                  {t(
                    "product_offers.personalized.heloan_debt_consolidate_popup.body.tables.monthly_paymnet"
                  )}
                </GridCol>
                <GridCol
                  xs={4}
                  className="tw-flex tw-items-center tw-justify-end"
                >
                  <Trans
                    i18nKey="product_offers.personalized.heloan_debt_consolidate_popup.body.tables.value_disclosure"
                    values={{
                      amount: monthlyPayment,
                    }}
                    components={{
                      amountTag: <span />,
                      disclosureTag: (
                        <DisclosureLink
                          disclosureType={DISCLOSURE_25}
                          isModal
                        />
                      ),
                    }}
                  />
                </GridCol>
                <GridCol
                  xs={4}
                  className="tw-flex tw-items-center tw-justify-end"
                >
                  <Trans
                    i18nKey="product_offers.personalized.heloan_debt_consolidate_popup.body.tables.value_disclosure"
                    values={{
                      amount: newLoanMonthlyPayment,
                    }}
                    components={{
                      amountTag: <span className="tw-font-bold" />,
                      disclosureTag: (
                        <DisclosureLink
                          disclosureType={DISCLOSURE_24}
                          isModal
                        />
                      ),
                    }}
                  />
                </GridCol>
              </GridRow>
            </GridCol>
            {/* Mobile view */}
            <GridCol className="lg:tw-hidden">
              <div className="tw-w-full tw-mb-2 tw-font-bold">
                {t(
                  "product_offers.personalized.heloan_debt_consolidate_popup.body.tables.estimated_debt"
                )}
              </div>
              <GridRow className="tw-border tw-border-solid tw-border-0 tw-border-b tw-border-border-lines tw-pb-1 tw-mb-4">
                <GridCol xs={5} className="tw-text-start">
                  {t(
                    "product_offers.personalized.heloan_debt_consolidate_popup.body.tables.estimated_debt_balance"
                  )}
                </GridCol>
                <GridCol
                  xs={7}
                  className="tw-flex tw-justify-end tw-items-center"
                >
                  <span>
                    <Trans
                      i18nKey="product_offers.personalized.heloan_debt_consolidate_popup.body.tables.value_disclosure"
                      values={{
                        amount: estimatedDebt,
                      }}
                      components={{
                        amountTag: <span />,
                        disclosureTag: (
                          <DisclosureLink
                            disclosureType={DISCLOSURE_25}
                            isModal
                          />
                        ),
                      }}
                    />
                  </span>
                </GridCol>
              </GridRow>
              <GridRow>
                <GridCol xs={5} className="tw-text-start">
                  {t(
                    "product_offers.personalized.heloan_debt_consolidate_popup.body.tables.monthly_paymnet"
                  )}
                </GridCol>
                <GridCol
                  xs={7}
                  className="tw-flex tw-justify-end tw-items-center"
                >
                  <span>
                    <Trans
                      i18nKey="product_offers.personalized.heloan_debt_consolidate_popup.body.tables.value_disclosure"
                      values={{
                        amount: monthlyPayment,
                      }}
                      components={{
                        amountTag: <span />,
                        disclosureTag: (
                          <DisclosureLink
                            disclosureType={DISCLOSURE_25}
                            isModal
                          />
                        ),
                      }}
                    />
                  </span>
                </GridCol>
              </GridRow>
              <div className="tw-w-full tw-mb-2 tw-font-bold tw-mt-6">
                {t(
                  "product_offers.personalized.heloan_debt_consolidate_popup.body.tables.new_loan"
                )}
              </div>
              <GridRow className="tw-border tw-border-solid tw-border-0 tw-border-b tw-border-border-lines tw-pb-1 tw-mb-4">
                <GridCol xs={5} className="tw-text-start">
                  {t(
                    "product_offers.personalized.heloan_debt_consolidate_popup.body.tables.estimated_debt_balance"
                  )}
                </GridCol>
                <GridCol
                  xs={7}
                  className="tw-flex tw-justify-end tw-items-center"
                >
                  <span>
                    <Trans
                      i18nKey="product_offers.personalized.heloan_debt_consolidate_popup.body.tables.value_disclosure"
                      values={{
                        amount: newLoanEstimatedDebt,
                      }}
                      components={{
                        amountTag: <span className="tw-font-bold" />,
                        disclosureTag: (
                          <DisclosureLink
                            disclosureType={DISCLOSURE_26}
                            isModal
                          />
                        ),
                      }}
                    />
                  </span>
                </GridCol>
              </GridRow>
              <GridRow>
                <GridCol xs={5} className="tw-text-start">
                  {t(
                    "product_offers.personalized.heloan_debt_consolidate_popup.body.tables.monthly_paymnet"
                  )}
                </GridCol>
                <GridCol
                  xs={7}
                  className="tw-flex tw-justify-end tw-items-center"
                >
                  <span>
                    <Trans
                      i18nKey="product_offers.personalized.heloan_debt_consolidate_popup.body.tables.value_disclosure"
                      values={{
                        amount: newLoanMonthlyPayment,
                      }}
                      components={{
                        amountTag: <span className="tw-font-bold" />,
                        disclosureTag: (
                          <DisclosureLink
                            disclosureType={DISCLOSURE_24}
                            isModal
                          />
                        ),
                      }}
                    />
                  </span>
                </GridCol>
              </GridRow>
            </GridCol>
          </GridContainer>
        </GridCol>
        <GridCol
          xs={12}
          lg={4}
          className="tw-flex tw-flex-col tw-justify-between tw-bg-surface-secondary tw-rounded-md tw-p-4 lg:tw-p-6"
        >
          <p className="tw-font-bold !tw-mb-0">
            {t(
              "product_offers.personalized.heloan_debt_consolidate_popup.body.tables.monthly_savings"
            )}
          </p>
          <span className="tw-text-large">
            <Trans
              i18nKey="product_offers.personalized.heloan_debt_consolidate_popup.body.tables.value_disclosure"
              values={{
                amount: formatCurrency(offer_debt_monthly_savings),
              }}
              components={{
                amountTag: <span className="tw-text-success tw-font-bold" />,
                disclosureTag: (
                  <DisclosureLink disclosureType={DISCLOSURE_24} isModal />
                ),
              }}
            />
          </span>

          <hr className="tw-text-border-lines tw-opacity-100" />
          <p className="tw-font-bold">
            {t(
              "product_offers.personalized.heloan_debt_consolidate_popup.body.tables.annual_savings"
            )}
          </p>
          <span className="tw-text-large">
            <Trans
              i18nKey="product_offers.personalized.heloan_debt_consolidate_popup.body.tables.value_disclosure"
              values={{
                amount: formatCurrency(offer_debt_yearly_savings),
              }}
              components={{
                amountTag: <span className="tw-text-success tw-font-bold" />,
                disclosureTag: (
                  <DisclosureLink disclosureType={DISCLOSURE_24} isModal />
                ),
              }}
            />
          </span>
        </GridCol>
      </GridContainer>
    </section>
  );
};

export default DebtHeloanConsolidateOfferPopupDetails;
