import { useEffect } from "react";
import { dispatch, RootState } from "../stores";
import { updateUIState } from "../stores/app-slice";
import { getTermsVersions, isMajorVersionDifferent } from "../helpers/utils";
import { useSSOValues } from "./use-sso-values";
import { useSelector } from "react-redux";
import usePopupRules from "./use-popup-rules";

const useOverlay = () => {
  const { isPendingFastlaneAccount } = useSSOValues();
  const { user } = useSelector((state: RootState) => state.user);
  const { userTermsVersion, latestTermsVersion } = getTermsVersions(user);
  const { showSSOBanner } = useSelector((state: RootState) => state.ssoUser);

  const showTermsAndConditionsModal =
    !isPendingFastlaneAccount &&
    user &&
    isMajorVersionDifferent(latestTermsVersion, userTermsVersion);

  const { shouldShowVideoPopup } = usePopupRules();

  useEffect(() => {
    dispatch(
      updateUIState({
        isContentBlockOverlay:
          showSSOBanner || showTermsAndConditionsModal || shouldShowVideoPopup,
      })
    );
  }, [showSSOBanner, showTermsAndConditionsModal, shouldShowVideoPopup]);
};

export default useOverlay;
