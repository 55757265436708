import { Trans } from "react-i18next";
import { useSelector } from "react-redux";
import { getSessionId } from "analytics";

import offerBackgroundModal from "dashboard/src/images/personalized-offers/offer-modal-bg.jpg";
import useExperimentManualExposure from "../../../../hooks/use-experiment-manual-exposure";
import { ReactComponent as LakeSVG } from "purple-rain/icons/white-lake.svg";
import { PRODUCT_OFFERS_TYPES } from "../../../../helpers/product-offers";
import { EXPERIMENTS, useABTest } from "../../../../hooks/use-ab-test";
import { formatDate } from "../../../../helpers/dateFormatter";
import { closeModal } from "../../../../stores/modal-slice";
import { translate as t } from "../../../../helpers/i18n";
import { dispatch, RootState } from "../../../../stores";
import { useSSOValues } from "../../../../hooks";
import {
  FEATURE_GATES,
  useFeatureGate,
} from "../../../../hooks/use-feature-gate";
import {
  buildFlidLakeviewUrl,
  getArdleyHeloanUrl,
} from "../../../../helpers/urlHelpers";
import {
  FLID_MAP,
  LAKEVIEW_URLS,
  PERSONALIZED_OFFER_DEBT_HELOAN_CONSOLIDATE_POPUP,
} from "../../../../helpers/constants";
import {
  formatCurrency,
  formatRate,
} from "../../../../helpers/currencyFormatter";

import DebtHeloanConsolidateOfferPopupDisclaimer from "./debt-heloan-consolidate-offer-popup-disclaimer";
import DebtHeloanConsolidateOfferPopupDetails from "./debt-heloan-consolidate-offer-popup-details";
import PayMortgageButton from "../../../../atomic/molecules/pay-mortgage-button";
import DisclosureLink from "../../../layout/disclosures/disclosure-link";
import { Button } from "../../../../atomic/atoms/button";
import { UIIconProps } from "ui/dist/components/ui-icon";
import GenericOfferCard from "../generic-offer-card-v2";
import { UIIcon, UITheme } from "ui";

const CURRENT_OFFER_TYPE = PRODUCT_OFFERS_TYPES.DEBT_HELOAN_CONSOLIDATE;
const DISCLOSURE_24 = PERSONALIZED_OFFER_DEBT_HELOAN_CONSOLIDATE_POPUP;

const DebtHeloanConsolidateOfferPopup = ({
  isDetails = true,
}: {
  isDetails: boolean;
}) => {
  const { groupName } = useABTest(EXPERIMENTS.TAVANT_DIGITAL_HELOAN);
  const experimentManualExposure = useExperimentManualExposure();
  const { isPendingFastlaneAccount } = useSSOValues();
  const isLakeviewHeloanLeadLinksFG = useFeatureGate(
    FEATURE_GATES.ENABLE_LAKEVIEW_HELOAN_LEAD_LINKS
  );

  const { user } = useSelector((state: RootState) => state.user);
  const refinance = useSelector(
    (state: RootState) => state.personalizeOffers?.refinance
  );

  const offer_debt_yearly_savings = refinance?.offer_debt_yearly_savings;
  const offer_debt_expire_date = refinance?.offer_debt_expire_date;
  const offer_debt_new_apr = refinance?.offer_debt_new_apr;
  const offer_debt_source_date = refinance?.offer_debt_source_date;

  const isInDigitalHeloanTreatment = groupName === "Treatment1";

  const handleGetStartedButtonClick = () => {
    experimentManualExposure(EXPERIMENTS.TAVANT_DIGITAL_HELOAN, groupName, [
      "Control1",
      "Treatment1",
    ]);

    const link = isInDigitalHeloanTreatment
      ? buildFlidLakeviewUrl({
          isModal: !isDetails,
          offerType: CURRENT_OFFER_TYPE,
          isPendingFastlaneAccount,
          baseUrl: LAKEVIEW_URLS.tavant_digital_heloan,
        })
      : isLakeviewHeloanLeadLinksFG
        ? LAKEVIEW_URLS.lakeview_heloan
        : getArdleyHeloanUrl({
            flID: isDetails
              ? FLID_MAP.OFFER_DEBT_HELOAN_CONSOLIDATE_DASHBOARD_CONTROL
              : FLID_MAP.OFFER_DEBT_HELOAN_CONSOLIDATE_POPUP_CONTROL,
            sessionID: getSessionId() || "",
            user: user,
          });

    // TODO: Implement in mixpanel ticket
    // mixpanel.track(MIXPANEL_EVENTS.CLICK, {
    //   path: pages.home,
    //   offer_type: kebabCase(CURRENT_OFFER_TYPE),
    //   button: "get-started",
    //   module: isModal
    //     ? PERSONALIZED_OFFERS_POPUP_MIXPANEL_MODULE
    //     : PERSONALIZED_OFFERS_MIXPANEL_MODULE,
    //   link: href,
    //   product: PRODUCT_OFFERS_TO_MIXPANEL_PRODUCT[CURRENT_OFFER_TYPE],
    // });

    window.open(link, "_blank");
    dispatch(closeModal());
  };

  const handleMaybeLaterClick = () => {
    // TODO: Implement in mixpanel ticket
    // mixpanel.track(MIXPANEL_EVENTS.CLICK, {
    //   offer_type: kebabCase(offerType),
    //   module: moduleName,
    //   path: pages.home,
    //   button: "maybe-later",
    //   link: pages.home,
    // });

    dispatch(closeModal());
  };

  return (
    <GenericOfferCard
      superTitle={t(
        "product_offers.personalized.heloan_debt_consolidate_popup.super_title"
      )}
      primaryTitle={
        <Trans
          i18nKey="product_offers.personalized.heloan_debt_consolidate_popup.primary_title"
          values={{
            amount: formatCurrency(offer_debt_yearly_savings),
          }}
          components={{
            amountTag: <span className="tw-text-success" />,
            disclosureTag: (
              <DisclosureLink disclosureType={DISCLOSURE_24} isModal />
            ),
          }}
        />
      }
      body={
        <div className="tw-flex tw-flex-col tw-items-center tw-w-full tw-text-regular tw-gap-6 md:tw-gap-8">
          <section className="tw-text-start">
            <p className="!tw-mb-4">
              {t(
                "product_offers.personalized.heloan_debt_consolidate_popup.body.initial_section.title"
              )}
            </p>
            <div className="tw-flex tw-flex-col tw-gap-2 tw-ml-4">
              {["cloudPercent", "atm", "checkBagde"].map((icon, idx) => (
                <div className="tw-flex tw-items-center" key={idx}>
                  <UIIcon
                    icon={icon as UIIconProps["icon"]}
                    className="tw-fill-secondary tw-w-6 tw-h-6 tw-mr-4"
                  />
                  <p>
                    {t(
                      `product_offers.personalized.heloan_debt_consolidate_popup.body.initial_section.bullet_${idx + 1}`
                    )}
                  </p>
                </div>
              ))}
            </div>
          </section>
          <DebtHeloanConsolidateOfferPopupDetails />
          <section className="tw-p-4 tw-border tw-border-solid tw-border-border-info tw-rounded tw-text-start tw-text-wrap tw-font-bold">
            {t(
              "product_offers.personalized.heloan_debt_consolidate_popup.body.opt_out_disclaimer"
            )}
          </section>
          <section>
            <p className="tw-text-secondary">
              <Trans
                i18nKey="product_offers.personalized.heloan_debt_consolidate_popup.body.offer_debt_expire_date"
                values={{ date: formatDate(offer_debt_expire_date) }}
              />
            </p>
          </section>
        </div>
      }
      ctaSection={
        <div className="tw-flex tw-flex-col tw-gap-4">
          <Button
            fullWidth="full"
            size="lg"
            theme={UITheme.LAKEVIEW_BLUE}
            onClick={handleGetStartedButtonClick}
          >
            <LakeSVG />
            {t(
              `product_offers.personalized.heloan_debt_consolidate_popup.cta_section.${isDetails ? "continue" : "get_started"}`
            )}
          </Button>
          <Button
            variant="secondary"
            fullWidth="full"
            size="lg"
            theme={UITheme.LAKEVIEW_BLUE}
            onClick={handleMaybeLaterClick}
          >
            {t(
              "product_offers.personalized.heloan_debt_consolidate_popup.cta_section.maybe_later"
            )}
          </Button>
          {!isDetails && (
            <PayMortgageButton
              text={t("product_offers.pay_my_mortgage")}
              // TODO: Implement in mixpanel ticket
              // eventParams={{
              //   module: PERSONALIZED_OFFERS_POPUP_MIXPANEL_MODULE,
              //   offer_type: kebabCase(CURRENT_OFFER_TYPE),
              //   product: PRODUCT_OFFERS_TO_MIXPANEL_PRODUCT[CURRENT_OFFER_TYPE],
              // }}
              theme={UITheme.LAKEVIEW_BLUE}
            />
          )}
          <Button
            variant="ghost"
            fullWidth="full"
            size="lg"
            theme={UITheme.LAKEVIEW_BLUE}
            // TODO: Implement in print preview ticket
            // onClick={handleMaybeLaterClick}
          >
            <UIIcon icon="atm" />
            {t(
              "product_offers.personalized.heloan_debt_consolidate_popup.cta_section.print_records"
            )}
          </Button>
        </div>
      }
      disclaimerSection={
        <DebtHeloanConsolidateOfferPopupDisclaimer
          apr={formatRate(offer_debt_new_apr)}
          sourceDate={formatDate(offer_debt_source_date)}
        />
      }
      backgroundImageSrc={offerBackgroundModal}
      isModal
    />
  );
};

export default DebtHeloanConsolidateOfferPopup;
