import { useState } from "react";
import { t } from "i18next";

import {
  escapeRegExp,
  isTestPasswordValid,
  PASSWORD_MIN_LENGTH,
} from "../../../helpers/constants";

import PasswordChecklistV2 from "../../../atomic/atoms/password-checklist-component-v2";

import { cn, UIPasswordInput } from "ui";

export interface PasswordValidationGroupProps {
  password: string;
  handlePassword: (e: React.ChangeEvent<HTMLInputElement>) => void;
  className?: string;
  isDarkBackground?: boolean;
  passwordPlaceholder?: string;
  passwordDataId?: string;
  confirmPasswordDataId?: string;
  isValid?: boolean;
}

const PasswordValidationGroupV2 = ({
  password,
  handlePassword,
  className = "",
  isDarkBackground = false,
  passwordPlaceholder = t("password_validation.new_password"),
  passwordDataId,
  confirmPasswordDataId,
}: PasswordValidationGroupProps) => {
  const [confirmPassword, setConfirmPassword] = useState("");

  const handleConfirmPassword = (e: React.ChangeEvent<HTMLInputElement>) => {
    setConfirmPassword(e.target.value);
  };

  return (
    <div className="tw-flex tw-flex-col tw-gap-4 tw-w-full">
      <div className={cn("tw-flex tw-flex-col tw-gap-4", className)}>
        <div className="form-floating tw-w-full">
          <UIPasswordInput
            password={password}
            handlePassword={handlePassword}
            placeholder={passwordPlaceholder}
            id="password"
            invalidFeedback={t("password_validation.validation.new_password")}
            invalidFeedbackClassName={isDarkBackground ? "tw-text-white" : ""}
            minLength={PASSWORD_MIN_LENGTH}
            isTestPasswordValid={isTestPasswordValid}
            accessibilityToggleText={t("accessibility.toggle_password")}
            accessibilityHidePasswordText={t("accessibility.hide_password")}
            accessibilityShowPasswordText={t("accessibility.show_password")}
            dataTestId={passwordDataId}
          />
        </div>
        <div className="form-floating tw-w-full">
          <UIPasswordInput
            password={confirmPassword}
            handlePassword={handleConfirmPassword}
            placeholder={t("password_validation.confirm_password")}
            id="confirm-password"
            invalidFeedback={t(
              "password_validation.validation.confirm_password"
            )}
            pattern={escapeRegExp(password)}
            invalidFeedbackClassName={isDarkBackground ? "tw-text-white" : ""}
            dataTestId={confirmPasswordDataId}
            accessibilityToggleText={t("accessibility.toggle_password")}
            accessibilityHidePasswordText={t("accessibility.hide_password")}
            accessibilityShowPasswordText={t("accessibility.show_password")}
            isTestPasswordValid={isTestPasswordValid}
          />
        </div>
      </div>
      {password && <PasswordChecklistV2 password={password} />}
    </div>
  );
};

export default PasswordValidationGroupV2;
