import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { AppDispatch, RootState } from "../stores";
import { useRef } from "react";
import { TERMS_CONDITIONS_OPEN_QUERY_PARAM_NAME } from "../helpers/constants";
import { updateTCModal } from "../stores/sso-user-slice";

export const useSSOValues = () => {
  const [, setSearchParams] = useSearchParams();
  const dispatch = useDispatch<AppDispatch>();

  const { user } = useSelector((state: RootState) => state.user);
  const confirmedPasswordRef = useRef("");

  /**
   * Indicates if the user has a pending fastlane account
   */
  const isPendingFastlaneAccount =
    !!user && !!user.did_sso && !user.has_fastlane_account;

  const openTermsModal = (password: string) => {
    /**
     *  We set the password in this way to prevent the modal from opening when
     *  the user refreshes the page with the 'modal=true' parameter and has only typed one character.
     */
    confirmedPasswordRef.current = password;
    setSearchParams({ [TERMS_CONDITIONS_OPEN_QUERY_PARAM_NAME]: "true" });
    dispatch(updateTCModal(true));
  };

  return {
    isPendingFastlaneAccount,
    openTermsModal,
    confirmedPassword: confirmedPasswordRef.current,
  };
};
