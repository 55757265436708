import { ReactNode } from "react";
import { useSelector } from "react-redux";
import { dispatch, RootState } from "../../stores";
import { FEATURE_GATES, useFeatureGate } from "../../hooks/use-feature-gate";
import { cn } from "ui";
import usePopupRules from "../../hooks/use-popup-rules";
import { setShowVideoPopup } from "../../stores/lakeview-video-slice";
import mixpanel from "mixpanel-browser";
import { MIXPANEL_EVENTS } from "analytics";
import { LAKEVIEW_VIDEO_POPUP_MIXPANEL_MODULE } from "../../helpers/constants";
import { pages } from "../../helpers/pages";

interface ContentBlockOverlayProps {
  children: ReactNode;
  className?: string;
}
const ContentBlockOverlay = ({
  children,
  className,
}: ContentBlockOverlayProps) => {
  const { isContentBlockOverlay } = useSelector(
    (state: RootState) => state.app
  );
  const { timeWatched, watchedFullVideo } = useSelector(
    (state: RootState) => state.lakeviewVideoState
  );
  const { shouldShowVideoPopup } = usePopupRules();

  const isLakeviewV2Enabled = useFeatureGate(FEATURE_GATES.ENABLE_LAKEVIEW_2_0);

  const handleOverlayClick = () => {
    if (shouldShowVideoPopup) {
      dispatch(setShowVideoPopup({ showVideoPopup: false }));

      mixpanel.track(MIXPANEL_EVENTS.CLICK, {
        module: LAKEVIEW_VIDEO_POPUP_MIXPANEL_MODULE,
        button: "overlay",
        path: pages.home,
        time_watched: timeWatched,
        watched_full_video: watchedFullVideo,
      });
    }
  };

  return (
    <div
      className={`position-relative ${className} ${
        isContentBlockOverlay && !shouldShowVideoPopup
          ? "pe-none user-select-none"
          : ""
      }`}
    >
      {/* Overlay */}
      {isContentBlockOverlay && (
        <div
          className={cn(
            "w-100 h-100 position-absolute z-3 ",
            isLakeviewV2Enabled
              ? "tw-bg-surface-overlay"
              : "content-block-overlay bg-typography-surface-disabled"
          )}
          onClick={handleOverlayClick}
        />
      )}

      {/* Blocked content */}
      {children}
    </div>
  );
};

export default ContentBlockOverlay;
