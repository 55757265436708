import BaseModal, { BaseModalProps } from "./index";
import React from "react";
import ModalLoadingMessage from "./components/modal-loading-message";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../stores";
import {
  clearModal,
  closeModal,
  GenericModalInitialConfig,
} from "../../../stores/modal-slice";
import { cva, cx } from "class-variance-authority";
import { cn, UILoader } from "ui";
import { FEATURE_GATES, useFeatureGate } from "../../../hooks/use-feature-gate";

export type GridSizeType = 6 | 8 | 10 | 12;

export interface GenericBaseModalProps extends BaseModalProps {
  gridSize?: GridSizeType;
  isLoading?: boolean;
  loadingMessage?: string;
  isOverlay?: boolean;
  shouldClearModalState?: boolean;
}

const gridSizeClasses = cva("tw-col-span-12", {
  variants: {
    gridSize: {
      6: "md:tw-col-span-8 md:tw-col-start-3 lg:tw-col-span-6 lg:tw-col-start-4",
      8: "md:tw-col-span-8 md:tw-col-start-3",
      10: "md:tw-col-span-10 md:tw-col-start-2",
      12: "",
    },
  },
  defaultVariants: {
    gridSize: 6,
  },
});

const getContentClassName = (
  contentClassName?: string,
  gridSize?: GridSizeType
) => {
  const gridClasses = gridSizeClasses({ gridSize });

  return contentClassName
    ? cn(contentClassName, gridClasses)
    : cn(GenericModalInitialConfig.contentClassName, gridClasses);
};

const GenericBaseModal = ({
  gridSize,
  isLoading,
  loadingMessage,
  isOverlay = true,
  shouldClearModalState = true,
  ...props
}: GenericBaseModalProps) => {
  const dispatch = useDispatch<AppDispatch>();
  const isLakeviewV2Enabled = useFeatureGate(FEATURE_GATES.ENABLE_LAKEVIEW_2_0);

  const handleOnExited = () => {
    if (shouldClearModalState) {
      dispatch(clearModal());
    }
  };

  const backdropClassName = isOverlay
    ? isLakeviewV2Enabled
      ? "tw-bg-surface-overlay"
      : "tw-bg-darkMutedBlue-800/50"
    : "";

  return (
    <BaseModal
      backdropClassName={backdropClassName}
      dialogClassName={cn(
        "tw-container tw-grid tw-grid-cols-12 tw-gap-6",
        isLakeviewV2Enabled ? "tw-px-0 md:tw-px-4" : ""
      )}
      titleClassName="tw-text-h3 tw-mb-0 tw-mr-4 tw-text-blue-900"
      bodyClassName="tw-p-0 tw-text-regular tw-text-blue-900"
      footerClassName="tw-w-full tw-flex tw-gap-0 tw-justify-start tw-p-0 tw-border-none tw-shadow-0"
      onCloseButtonClick={
        props.onCloseButtonClick
          ? props.onCloseButtonClick
          : () => dispatch(closeModal())
      }
      onEscapeKeyDown={() => dispatch(closeModal())}
      {...props}
      contentClassName={getContentClassName(props.contentClassName, gridSize)}
      headerClassName={cx(
        props.headerClassName ||
          "tw-w-full tw-p-0 tw-flex tw-items-start tw-justify-between",
        props.title || "tw-flex-row-reverse"
      )}
      buttons={
        isLoading ? (
          isLakeviewV2Enabled ? (
            <UILoader
              size="sm"
              title={loadingMessage}
              variant="secondary"
              direction="row"
            />
          ) : (
            <ModalLoadingMessage loadingMessage={loadingMessage} />
          )
        ) : (
          props.buttons
        )
      }
      onExited={handleOnExited}
    />
  );
};

export default GenericBaseModal;
