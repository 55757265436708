import { getSessionId } from "analytics";
import { EXPERIMENTS } from "../hooks/use-ab-test";
import { User } from "../types";
import {
  FLID_MAP,
  LAKEVIEW_APP_TYPE,
  LAKEVIEW_LINK_TYPE,
  LAKEVIEW_SUBCHANNEL,
  LAKEVIEW_URLS,
  LakeviewLinkType,
} from "./constants";
import { PRODUCT_OFFERS_TYPES } from "./product-offers";
import {
  buildUrlWithParams,
  getArdleySubservicerValue,
  getStateTerritoryCode,
} from "./utils";
import { UserState } from "../stores/user-slice";

const SITE_ID = "FLLVDB";

interface OfferConfig {
  flid: string;
  baseUrl: string;
  modalFlid?: string;
}

const OFFER_CONFIGS: Record<string, OfferConfig> = {
  [PRODUCT_OFFERS_TYPES.FHA_SL_POST]: {
    flid: FLID_MAP.OFFER_FHA_SL_POST,
    modalFlid: FLID_MAP.OFFER_FHA_SL_POST_MODAL,
    baseUrl: LAKEVIEW_URLS.apply_now,
  },
  [PRODUCT_OFFERS_TYPES.VA_IRRRL]: {
    flid: FLID_MAP.OFFER_VA_IRRRL,
    modalFlid: FLID_MAP.OFFER_VA_IRRRL_MODAL,
    baseUrl: LAKEVIEW_URLS.apply_now,
  },
  [PRODUCT_OFFERS_TYPES.CONV_RT]: {
    flid: FLID_MAP.OFFER_CONV_RT,
    modalFlid: FLID_MAP.OFFER_CONV_RT_MODAL,
    baseUrl: LAKEVIEW_URLS.apply_now,
  },
  [PRODUCT_OFFERS_TYPES.CAO_ONLY]: {
    flid: FLID_MAP.OFFER_CAO_ONLY,
    modalFlid: FLID_MAP.OFFER_CAO_ONLY_MODAL,
    baseUrl: LAKEVIEW_URLS.apply_now,
  },
  [PRODUCT_OFFERS_TYPES.DEBT_CONSOLIDATE]: {
    flid: FLID_MAP.OFFER_DEBT_CONSOLIDATE,
    modalFlid: FLID_MAP.OFFER_DEBT_CONSOLIDATE_MODAL,
    baseUrl: LAKEVIEW_URLS.apply_now,
  },
  [PRODUCT_OFFERS_TYPES.DEBT_PMT_SAVINGS]: {
    flid: FLID_MAP.OFFER_DEBT_PMT_SAVINGS,
    modalFlid: FLID_MAP.OFFER_DEBT_PMT_SAVINGS_MODAL,
    baseUrl: LAKEVIEW_URLS.apply_now,
  },
  [PRODUCT_OFFERS_TYPES.CAO_HELOAN]: {
    flid: FLID_MAP.OFFER_CAO_HELOAN,
    modalFlid: FLID_MAP.OFFER_CAO_HELOAN_MODAL,
    baseUrl: LAKEVIEW_URLS.apply_now,
  },
  [PRODUCT_OFFERS_TYPES.DEBT_HELOAN_CONSOLIDATE]: {
    flid: FLID_MAP.OFFER_DEBT_HELOAN_CONSOLIDATE_DASHBOARD_TREATMENT,
    modalFlid: FLID_MAP.OFFER_DEBT_HELOAN_CONSOLIDATE_POPUP_TREATMENT,
    baseUrl: LAKEVIEW_URLS.apply_now,
  },
};

export interface UrlParams {
  linkType: LakeviewLinkType;
  lakeviewSubchannel?: string;
  lakeviewModule: string;
  lakeviewProduct: string;
  user?: User;
  isPendingFastlaneAccount: boolean;
  sessionId: string | null;
  experimentDetails?: {
    experimentName: string;
    isActive: boolean;
    module: string;
  };
  lakeviewProductId?: string;
  lakeviewFlid?: FLID_MAP;
}

export interface FlidLakeviewUrlParams {
  offerType: string;
  isPendingFastlaneAccount: boolean;
  baseUrl?: string;
  isModal?: boolean;
}

export function buildLakeviewUrl({
  linkType,
  lakeviewSubchannel = LAKEVIEW_SUBCHANNEL.FLD,
  lakeviewModule,
  lakeviewProduct,
  user,
  isPendingFastlaneAccount,
  sessionId,
  experimentDetails,
  lakeviewProductId,
  lakeviewFlid,
}: UrlParams): string {
  const isSSOUserWithoutFastlaneAccount =
    user?.did_sso && !user?.has_fastlane_account;

  // since we are in a regular function, it is not possible to call the useIsUserAuthenticated hook
  const isUserAuthenticated = !!user?.id;

  const isSSOLink =
    linkType === LAKEVIEW_LINK_TYPE.APPLY_NOW ||
    linkType === LAKEVIEW_LINK_TYPE.CASH_OUT_APPLY;

  /**
   * Condition to display the leave modal:
   * * Should be logged in and have a Fastlane Account
   * * The link should be an "apply now" link
   */
  const shouldDisplayModal = !!user && isSSOLink && !isPendingFastlaneAccount;
  /**
   * Condition to determine whether to use the Heloan format for URL parameters:
   * * Link type should be "offers"
   */
  const isLinkTypeOffers = linkType === "offers";
  const flId =
    lakeviewFlid ??
    `${lakeviewSubchannel}-${lakeviewModule}-${lakeviewProduct}`;

  let url = new URL(LAKEVIEW_URLS[linkType]);

  url.searchParams.append(
    "APP_TYPE",
    shouldDisplayModal
      ? LAKEVIEW_APP_TYPE.LOGIN
      : LAKEVIEW_APP_TYPE.LOAN_REQUEST
  );

  if (experimentDetails && experimentDetails.isActive) {
    if (
      experimentDetails.experimentName === EXPERIMENTS.TAVANT_DIGITAL_HELOAN
    ) {
      url = new URL(LAKEVIEW_URLS.tavant_digital_heloan);
      if (!isSSOUserWithoutFastlaneAccount && isUserAuthenticated)
        url.searchParams.append("login", "true");

      url.searchParams.append("siteId", SITE_ID);
      url.searchParams.append("flId", experimentDetails.module);
      if (sessionId) url.searchParams.append("flsesId", sessionId);
    }
  } else if (isLinkTypeOffers) {
    const marketingCode = `siteId|${SITE_ID}|flId|${flId}|flsesId|${sessionId}`;
    url.searchParams.append("marketingCode", marketingCode);
  } else {
    url.searchParams.append("siteId", SITE_ID);
    url.searchParams.append("flId", flId);

    if (sessionId) {
      url.searchParams.append("flsesId", sessionId);
    }

    if (lakeviewProductId) {
      url.searchParams.append("productId", lakeviewProductId);
    }
  }

  return url.toString();
}

export function buildFlidLakeviewUrl({
  offerType,
  isPendingFastlaneAccount,
  isModal,
  baseUrl,
}: FlidLakeviewUrlParams): string {
  const config = OFFER_CONFIGS[offerType];
  const currentBaseUrl = baseUrl || config.baseUrl;
  const flId = isModal ? config.modalFlid : config.flid;

  const flsesId = getSessionId() as string;

  const appType = !isPendingFastlaneAccount
    ? LAKEVIEW_APP_TYPE.LOGIN
    : LAKEVIEW_APP_TYPE.LOAN_REQUEST;

  const url = new URL(currentBaseUrl);
  url.searchParams.append("APP_TYPE", appType);
  url.searchParams.append("siteId", SITE_ID);
  url.searchParams.append("flId", flId!);
  url.searchParams.append("flsesId", flsesId);

  return url.toString();
}

/**
 * A util to determine the APP_TYPE for the Cruise redirect urls
 *
 * @param isPendingFastlaneAccount
 * @returns {login | loan-request}
 */
export const getAppType = (isPendingFastlaneAccount: boolean) => {
  const appType = isPendingFastlaneAccount
    ? LAKEVIEW_APP_TYPE.LOAN_REQUEST
    : LAKEVIEW_APP_TYPE.LOGIN;

  return appType;
};

/**
 * Generates the Tavant Cruise Portal URL based on user state, flight ID, and session ID.
 *
 * @param {Object} args - The arguments required to generate the URL.
 * @param {UserState|undefined} args.user - The current state of the user, which may include authentication status and account information.
 * @param {string} args.flID - The flight ID used for the portal.
 * @param {string} args.sessionID - The session ID for the user's session.
 * @returns {string} The constructed Tavant Cruise Portal URL.
 */
export const getTavantCruisePortalUrl = ({
  user,
  flID,
  sessionID,
}: {
  user: UserState;
  flID: string;
  sessionID: string;
}): string => {
  const baseUrl = LAKEVIEW_URLS.apply_now;

  const appType =
    user?.status === "authenticated" && user.user?.has_fastlane_account
      ? LAKEVIEW_APP_TYPE.LOGIN
      : LAKEVIEW_APP_TYPE.LOAN_REQUEST;

  return buildUrlWithParams(baseUrl, {
    APP_TYPE: appType,
    flId: flID,
    siteId: SITE_ID,
    flsesId: sessionID,
  });
};

/**
 * Constructs and returns a URL for Tavant Digital HELOAN based on the provided parameters.
 *
 * @param {Object} param0 - An object containing the necessary parameters for URL generation.
 * @param {UserState | undefined} param0.user - The user state, which includes authentication and account details.
 * @param {string} param0.flID - The fastlane ID used to identify the user.
 * @param {string} param0.sessionID - The session ID associated with the current user session.
 * @returns {string} Returns the constructed URL for accessing Tavant Digital HELOAN.
 */
export const getTavantDigitalHeloanUrl = ({
  user,
  flID,
  sessionID,
}: {
  user: UserState;
  flID: string;
  sessionID: string;
}): string => {
  const baseUrl = LAKEVIEW_URLS.tavant_digital_heloan;

  return buildUrlWithParams(baseUrl, {
    login:
      user?.status === "authenticated" && user.user?.has_fastlane_account
        ? "true"
        : undefined,
    flId: flID,
    siteId: SITE_ID,
    flsesId: sessionID,
  });
};

/**
 * Generates a URL for the Ardley HELOAN service based on the provided user details, flID, and sessionID.
 *
 * @param {Object} params - The parameters used to construct the URL.
 * @param {string} params.flID - A unique identifier associated with the request for HELOAN.
 * @param {string} [params.sessionID] - An optional session identifier.
 * @param {User} [params.user] - An optional user object containing user details such as name, email, address, and mortgage data.
 * @returns {string} Returns the complete URL with the necessary parameters appended.
 */
export const getArdleyHeloanUrl = ({
  user,
  flID,
  sessionID,
}: {
  flID: string;
  sessionID: string;
  user?: User;
}): string => {
  const baseUrl = LAKEVIEW_URLS.offers;

  const userState = user?.address?.state;
  const userStateValue =
    userState && userState?.length === 2
      ? userState
      : getStateTerritoryCode(userState);
  const servicerValue = getArdleySubservicerValue(user?.mortgage?.subservicer);

  const marketingCodeValue = `flId|${flID}|siteId|${SITE_ID}|flsesId|${sessionID}`;

  return buildUrlWithParams(baseUrl, {
    marketingCode: marketingCodeValue,
    loanNumber: user?.mortgage?.subservicer_loan_number,
    servicer: servicerValue,
    state: userStateValue?.toUpperCase(),
    zip: user?.address?.zip_code,
  });
};
