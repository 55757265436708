/** Wrapper component for the Error Boundary  */

import { ErrorBoundary as ReactErrorBoundary } from "react-error-boundary";
import { datadogRum } from "@datadog/browser-rum";
import ErrorFallback from "./error-fallback";
import { ErrorInfo } from "react";

type ErrorBoundaryProps = {
  children?: React.ReactNode;
};

const ErrorBoundary = ({ children }: ErrorBoundaryProps) => {
  const handleError = (error: Error, info: ErrorInfo) => {
    // Send error to DatadogRum
    datadogRum.addError(error, info);
  };

  return (
    <ReactErrorBoundary FallbackComponent={ErrorFallback} onError={handleError}>
      {children}
    </ReactErrorBoundary>
  );
};

export default ErrorBoundary;
