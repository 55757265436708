import React, {
  Children,
  cloneElement,
  isValidElement,
  PropsWithChildren,
} from "react";
import {
  FEATURE_GATES,
  useFeatureGate,
} from "../../../../hooks/use-feature-gate";
import { cn, UITheme } from "ui";

const ModalButtonsWrapper = ({ children }: PropsWithChildren) => {
  const isLakeviewRebrand = useFeatureGate(FEATURE_GATES.ENABLE_LAKEVIEW_2_0);

  const clonedChildren = Children.map(children, (child) => {
    if (isValidElement(child)) {
      return cloneElement(child, {
        ...child.props,
        theme: isLakeviewRebrand ? UITheme.LAKEVIEW_BLUE : child.props.theme,
      });
    }

    return child;
  });

  return (
    <div
      className={cn(
        "tw-flex tw-flex-col md:tw-flex-row tw-flex-wrap tw-w-full tw-gap-4",
        isLakeviewRebrand ? "tw-pt-1.5" : ""
      )}
    >
      {clonedChildren}
    </div>
  );
};

export default ModalButtonsWrapper;
