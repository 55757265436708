import { MIXPANEL_EVENTS } from "analytics";
import mixpanel from "mixpanel-browser";
import { dispatch } from "../../stores";
import {
  buildModal,
  closeModal,
  GenericModalInitialConfig,
} from "../../stores/modal-slice";
import { translate as t } from "../../helpers/i18n";
import { Trans } from "react-i18next";
import PayMortgageModalButtons from "../../atomic/molecules/pay-mortgage-button/pay-mortgage-modal-buttons";
import { MODALS_IDS } from "../../helpers/constants";
import { LakeviewEventParams } from "../confirm-leave-modal";

interface Props {
  subservicerName?: string;
  isLoading?: boolean;
  eventParams?: LakeviewEventParams;
}

export const buildPayMortgageModal = ({
  subservicerName,
  isLoading,
  eventParams,
}: Props) => {
  const offerType = eventParams?.offer_type
    ? { offer_type: eventParams?.offer_type }
    : {};

  mixpanel.track(MIXPANEL_EVENTS.MODULE_SERVED, {
    module: "leave-dialog",
    view_source: eventParams?.module,
    button: "pay-my-mortgage",
    subservicer_name: subservicerName,
    ...offerType,
  });

  const handleXClick = () => {
    mixpanel.track(MIXPANEL_EVENTS.CLICK, {
      button: "pay-my-mortgage-close",
      module: "leave-dialog",
      path: eventParams?.module,
      ...offerType,
    });

    dispatch(closeModal());
  };

  const handleBackDropClick = () => {
    mixpanel.track(MIXPANEL_EVENTS.CLICK, {
      button: "pay-my-mortgage-off-module-click-to-close",
      module: "leave-dialog",
      path: eventParams?.module,
      ...offerType,
    });

    dispatch(closeModal());
  };

  // TODO: Find a better way to handle this, if not update all buildModals to add the id property
  // https://fastlanefi.atlassian.net/browse/CLF-4221

  dispatch(
    buildModal({
      ...GenericModalInitialConfig,
      id: MODALS_IDS.PAY_MORTGAGE_MODAL,
      title: <Trans i18nKey="leaving_modal.subservicer_link.title" />,
      onCloseButtonClick: handleXClick,
      onClose: handleBackDropClick,
      buttons: (
        <PayMortgageModalButtons
          eventParams={eventParams}
          subservicerName={subservicerName}
        />
      ),
      children: (
        <p className="m-0">
          <Trans
            i18nKey="leaving_modal.subservicer_link.content"
            values={{
              subservicer_name: subservicerName,
            }}
            shouldUnescape
          />
        </p>
      ),
      isLoading: isLoading,
      loadingMessage: t("leaving_modal.global.loading_link"),
    })
  );
};
