import { MIXPANEL_PRODUCT_NAME } from "./constants";

export const PRODUCT_OFFERS_MODULE_NAME = "personalized-offer";

export const PRODUCT_OFFERS_TYPES = {
  CAO_ONLY: "CaO Only",
  CAO_HELOAN: "CaO HELoan",
  DEBT_CONSOLIDATE: "Debt Consolidate",
  DEBT_PMT_SAVINGS: "Debt Pmt Savings",
  ASSUMABLE_MORTGAGE: "Assumable Mortgage",
  CONV_RT: "Conv RT",
  FHA_SL_POST: "FHA SL Post",
  VA_IRRRL: "VA IRRRL",
  DEBT_HELOAN_CONSOLIDATE: "Debt Heloan",
};

export const PRODUCT_OFFERS_LOAN_TYPES = {
  CONV: "CONV",
  FHA: "FHA",
  VA: "VA",
};

export const PRODUCT_OFFERS_TO_MIXPANEL_PRODUCT: Record<
  (typeof PRODUCT_OFFERS_TYPES)[keyof typeof PRODUCT_OFFERS_TYPES],
  MIXPANEL_PRODUCT_NAME
> = {
  [PRODUCT_OFFERS_TYPES.CAO_ONLY]: MIXPANEL_PRODUCT_NAME.CASH_OUT,
  [PRODUCT_OFFERS_TYPES.CAO_HELOAN]: MIXPANEL_PRODUCT_NAME.HELOAN,
  [PRODUCT_OFFERS_TYPES.DEBT_CONSOLIDATE]:
    MIXPANEL_PRODUCT_NAME.DEBT_CONSOLIDATE,
  [PRODUCT_OFFERS_TYPES.DEBT_PMT_SAVINGS]:
    MIXPANEL_PRODUCT_NAME.DEBT_PAYMENT_SAVINGS,
  [PRODUCT_OFFERS_TYPES.ASSUMABLE_MORTGAGE]:
    MIXPANEL_PRODUCT_NAME.ASSUMABLE_MORTGAGE,
  [PRODUCT_OFFERS_TYPES.CONV_RT]: MIXPANEL_PRODUCT_NAME.RATE_TERM_REFINANCE,
  [PRODUCT_OFFERS_TYPES.FHA_SL_POST]: MIXPANEL_PRODUCT_NAME.RATE_TERM_REFINANCE,
  [PRODUCT_OFFERS_TYPES.VA_IRRRL]: MIXPANEL_PRODUCT_NAME.RATE_TERM_REFINANCE,
};
