import { Trans } from "react-i18next";
import { translate as t } from "../../../helpers/i18n";
import { GridCol, GridContainer, GridRow } from "ui";

const AfBAContentV2 = () => {
  return (
    <div className="tw-text-primary tw-font-sans">
      <p className="!tw-mb-4">{t("afba_modal.content")}</p>
      <div
        className="tw-overflow-x-hidden tw-h-full md:tw-h-[350px]"
        style={{ scrollbarGutter: "stable" }}
      >
        <div className="tw-flex tw-flex-col tw-gap-6 tw-p-6 md:tw-mr-2 tw-rounded tw-bg-surface-secondary">
          <h4 className="tw-text-h4 tw-text-heading tw-font-sans tw-font-normal tw-mb-0">
            {t("afba.content.title")}
          </h4>
          <GridContainer
            containerClassName="tw-p-0 tw-m-0"
            isVerticalGap={false}
          >
            <GridRow>
              <GridCol xs={2} md={1}>
                {t("afba.content.to")}
              </GridCol>
              <GridCol xs={10} md={11}>
                {t("afba.content.allCustomers")}
              </GridCol>
            </GridRow>
            <GridRow>
              <GridCol xs={2} md={1}>
                {t("afba.content.date")}
              </GridCol>
              <GridCol xs={10} md={11}>
                {t("afba.content.asOf")}
              </GridCol>
            </GridRow>
            <GridRow>
              <GridCol xs={2} md={1}>
                {t("afba.content.from")}
              </GridCol>
              <GridCol xs={10} md={11}>
                {t("afba.content.sender")}
              </GridCol>
            </GridRow>
          </GridContainer>
          <div>
            <Trans
              i18nKey="afba.content.notice"
              components={{ b: <></>, br: <br /> }}
            />
          </div>
          {/**Mobile Table */}
          <div className="md:tw-hidden tw-flex tw-flex-col tw-gap-6">
            <div className="tw-border tw-border-solid tw-border-border-lines tw-rounded">
              <div className="tw-border tw-border-solid tw-border-x-0 tw-border-t-0 tw-border-border-lines tw-p-4">
                <h6 className="tw-text-h6 tw-font-sans tw-uppercase tw-text-primary tw-mb-4">
                  {t("afba.content.providerServiceTitle")}
                </h6>
                <div>{t("afba.content.insuranceProvider")}</div>
              </div>
              <div className="tw-p-4">
                <h6 className="tw-text-h6 tw-font-sans tw-uppercase tw-text-primary tw-mb-4">
                  {t("afba.content.chargeRangeTitle")}
                </h6>
                <div>{t("afba.content.insuranceCharge")}</div>
              </div>
            </div>
            <div className="tw-border tw-border-solid tw-border-border-lines tw-rounded">
              <div className="tw-border tw-border-solid tw-border-x-0 tw-border-t-0 tw-border-border-lines tw-p-4">
                <h6 className="tw-text-h6 tw-font-sans tw-uppercase tw-text-primary tw-mb-4">
                  {t("afba.content.providerServiceTitle")}
                </h6>
                <div>{t("afba.content.realtyProvider")}</div>
              </div>
              <div className="tw-p-4">
                <h6 className="tw-text-h6 tw-font-sans tw-uppercase tw-text-primary tw-mb-4">
                  {t("afba.content.chargeRangeTitle")}
                </h6>
                <div>{t("afba.content.realtyCharge")}</div>
              </div>
            </div>
          </div>
          {/**Desktop Table */}
          <GridContainer
            containerClassName="tw-hidden md:tw-block tw-border tw-border-solid tw-border-border-lines tw-rounded tw-p-0 tw-m-0"
            isVerticalGap={false}
          >
            <GridRow className="tw-border tw-border-solid tw-border-x-0 tw-border-t-0 tw-border-border-lines">
              <GridCol xs={6} className="tw-p-4">
                <h6 className="tw-text-h6 tw-font-sans tw-uppercase tw-text-primary tw-mb-0">
                  {t("afba.content.providerServiceTitle")}
                </h6>
              </GridCol>
              <GridCol xs={6} className="tw-p-4">
                <h6 className="tw-text-h6 tw-font-sans tw-uppercase tw-text-primary tw-mb-0">
                  {t("afba.content.chargeRangeTitle")}
                </h6>
              </GridCol>
            </GridRow>
            <GridRow className="tw-border tw-border-solid tw-border-x-0 tw-border-t-0 tw-border-border-lines">
              <GridCol xs={6} className="tw-p-4">
                {t("afba.content.insuranceProvider")}
              </GridCol>
              <GridCol xs={6} className="tw-p-4">
                {t("afba.content.insuranceCharge")}
              </GridCol>
            </GridRow>
            <GridRow>
              <GridCol xs={6} className="tw-p-4">
                {t("afba.content.realtyProvider")}
              </GridCol>
              <GridCol xs={6} className="tw-p-4">
                {t("afba.content.realtyCharge")}
              </GridCol>
            </GridRow>
          </GridContainer>
        </div>
      </div>
    </div>
  );
};

export default AfBAContentV2;
