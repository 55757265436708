import { locale } from "./i18n";

const EMPTY_DATE_PLACEHOLDER = "-";

// Format mm/dd/yyyy - example: 01/31/2023
export const digitDateFormat: Intl.DateTimeFormatOptions = {
  year: "numeric",
  month: "2-digit",
  day: "2-digit",
};

// Format mm/dd/yy - example: 01/31/23
export const shortDateFormat: Intl.DateTimeFormatOptions = {
  year: "2-digit",
  month: "2-digit",
  day: "2-digit",
};

// Format YYYY - example: 2023
export const shortYearFormat: Intl.DateTimeFormatOptions = {
  year: "numeric",
};

// Format MMM YYYY - example: Jan, 2023
export const shortMonthYearFormat: Intl.DateTimeFormatOptions = {
  month: "short",
  year: "numeric",
};

// Format MMM d, YYYY - example: Jan 31, 2023
export const mediumFormat: Intl.DateTimeFormatOptions = { dateStyle: "medium" };

/**
 * Formats a given date string or timestamp into a localized string representation
 * based on the provided formatting options.
 *
 * @param {string | number | undefined} dateString - The input date in string or numeric timestamp format.
 *        It can be a string in `YYYY-MM-DD` format, an ISO 8601 string, or a Unix timestamp in milliseconds.
 * @param {object} [options=mediumFormat] - Options for formatting the date, typically passed to
 *        the `toLocaleString()` method. Defaults to a pre-defined formatting option `mediumFormat`.
 * @returns {string} The formatted date as a localized string. If the input date is invalid or
 *          undefined, a default placeholder string defined by `EMPTY_DATE_PLACEHOLDER` is returned.
 */
export const formatDate = (
  dateString: string | number | undefined,
  options: object = mediumFormat
): string => {
  if (!dateString) {
    return EMPTY_DATE_PLACEHOLDER;
  }

  let initialDate: Date;

  // If it's a simple date string (YYYY-MM-DD), parse it manually to avoid timezone shifts
  if (
    typeof dateString === "string" &&
    /^\d{4}-\d{2}-\d{2}$/.test(dateString)
  ) {
    const [year, month, day] = dateString.split("-").map(Number);
    initialDate = new Date(year, month - 1, day);
  } else {
    initialDate = new Date(dateString);
  }

  if (isNaN(initialDate.getTime())) {
    return EMPTY_DATE_PLACEHOLDER;
  }

  // If the input explicitly includes UTC (Z or +00:00), treat it as UTC
  if (
    typeof dateString === "string" &&
    (dateString.includes("Z") || dateString.includes("+00:00"))
  ) {
    const utcDate = new Date(initialDate.toISOString().slice(0, -1));
    return utcDate.toLocaleString(locale, options);
  }

  // Otherwise, treat it as a local date
  return initialDate.toLocaleString(locale, options);
};
