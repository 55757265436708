import { useSelector } from "react-redux";
import { Steps } from "./banner-container";
import { RootState } from "../../../stores";
import { Account } from "../../../types";
import { SubservicerLink } from "../../../components/subservicer-link";
import { FLOATING_BUTTON_MODULE_NAME } from "../../../modules/home-value/mortgage/mortgage-card";
import { REDIRECT_TARGET } from "../../../helpers/subservicer";
import { useDashboardVersion } from "../../../hooks";
import BannerLetsGetStartedButton from "./banner-lets-get-started-button";

interface FloatingCTABannerSectionProps {
  step: Steps;
  account?: Account;
}

/**
   Since the SSO banner now displays two floating buttons when there's a current user with enabled mortgage payments:
   - The original floating button remains when Mortgage Payment button is disabled
   - The second button (Pay my mortgage) is handled in the Mortgage card component, as it requires all current account data
   */
const FloatingCTABannerSection = ({
  step,
  account,
}: FloatingCTABannerSectionProps) => {
  const isFloatingButton = useSelector(
    (state: RootState) => state.app.isFloatingButton
  );
  const { mixpanelPageName } = useDashboardVersion();

  const isPaymentFloatingButtonEnabled = isFloatingButton;
  const isLetsGetStartedButtonEnabled = step === "introduction";

  if (!isLetsGetStartedButtonEnabled && !isPaymentFloatingButtonEnabled)
    return null;

  return (
    <div className="md:!tw-hidden bg-gradient mortgage-card__floating-button">
      <div className="container tw-my-4">
        <div className="tw-flex tw-flex-col tw-w-full tw-justify-center tw-gap-4">
          {isLetsGetStartedButtonEnabled && (
            <BannerLetsGetStartedButton
              buttonProps={{
                variant: "primary",
                size: "md",
                fullWidth: "mobile",
              }}
            />
          )}
          {isPaymentFloatingButtonEnabled && (
            <SubservicerLink
              isLinkTag={false}
              translationKey="home_value.mortgage.pay_my_mortgage"
              module={FLOATING_BUTTON_MODULE_NAME}
              subservicerLoanNumber={account?.subservicer_loan_number}
              redirectTarget={REDIRECT_TARGET.PAYMENT}
              hasIcon
              isButton
              eventParams={{
                button: "pay-my-mortgage",
                subservicer_loan_number: account?.subservicer_loan_number,
                page: mixpanelPageName,
                path: location.pathname,
              }}
              buttonProps={{
                variant: "secondary",
                size: "md",
                fullWidth: "mobile",
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default FloatingCTABannerSection;
