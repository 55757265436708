import { MIXPANEL_EVENTS } from "analytics";
import mixpanel from "mixpanel-browser";
import { useDispatch } from "react-redux";
import { Button, ButtonProps } from "../../atoms/button";
import { useDashboardVersion } from "../../../hooks";
import { AppDispatch } from "../../../stores";
import { displayPasswordSetup } from "../../../stores/sso-user-slice";
import { translate as t } from "../../../helpers/i18n";
import { useLocation } from "react-router-dom";

interface BannerLetsGetStartedButtonProps {
  buttonProps?: ButtonProps;
}

const BannerLetsGetStartedButton = ({
  buttonProps = {},
}: BannerLetsGetStartedButtonProps) => {
  const { mixpanelPageName } = useDashboardVersion();
  const dispatch = useDispatch<AppDispatch>();
  const { pathname } = useLocation();

  // reset scroll to display form at the top
  const handleDisplayPasswordSetup = () => {
    // Track click and display events
    mixpanel.track(MIXPANEL_EVENTS.CLICK, {
      module: "subservice-sso-banner",
      step: 1,
      button: "unlock-dashboard",
      page: mixpanelPageName,
      path: pathname,
    });
    dispatch(displayPasswordSetup(true));
    window.scrollTo({ top: 0, left: 0, behavior: "auto" });
  };

  return (
    <Button onClick={handleDisplayPasswordSetup} {...buttonProps}>
      {t("sso_banner.introduction.cta")}
    </Button>
  );
};
export default BannerLetsGetStartedButton;
