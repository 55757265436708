import { t } from "i18next";
import React, { useState } from "react";
import {
  Tooltip,
  TooltipArrow,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@radix-ui/react-tooltip";
import PasswordValidationGroup from "../password-validation-form/password-validation-group";
import { handleValidation } from "../../../helpers/form-validation";
import { Button } from "../../atoms/button";

interface PasswordFormProps {
  email: string;
  eyebrow?: string;
  showEmailText?: boolean;
  saveButtonText: string;
  cancelButtonText: string;
  onSubmit: (isFormValid: boolean, password: string) => void;
  onDismiss: () => void;
}

// Max char number for Email that fits in the password box without ellipsis
const MAX_EMAIL_STR_SIZE_FIT = 40;

const PasswordForm = ({
  email = "",
  eyebrow = "",
  showEmailText = false,
  saveButtonText,
  cancelButtonText,
  onSubmit,
  onDismiss,
}: PasswordFormProps) => {
  const [password, setPassword] = useState("");

  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const isFormValid = handleValidation(event);
    onSubmit(isFormValid, password);
  };

  const emailLabel = showEmailText ? `${t("global.email")}:` : "";
  const emailEyebrowLength = showEmailText
    ? emailLabel.length + email.length
    : email.length;

  return (
    <form onSubmit={handleSubmit} noValidate className="w-100">
      <div className="d-flex flex-column gap-3">
        <div>
          {eyebrow && (
            <p className="m-0 text-uppercase typeface-body-small fw-semibold box-title">
              {eyebrow}
            </p>
          )}
          {
            <TooltipProvider>
              <Tooltip delayDuration={0}>
                <TooltipTrigger asChild>
                  <p className="tw-text-ellipsis tw-overflow-x-hidden tw-text-nowrap">
                    {emailLabel} {email}
                  </p>
                </TooltipTrigger>
                {emailEyebrowLength > MAX_EMAIL_STR_SIZE_FIT ? (
                  <TooltipContent
                    align="start"
                    side="top"
                    className="tw-bg-white tw-py-1 tw-px-3 tw-rounded-lg"
                    id="subservicer-sso-banner-email"
                  >
                    <TooltipArrow className="tw-fill-white" />
                    <p className="tw-text-primary tw-text-small">{email}</p>
                  </TooltipContent>
                ) : (
                  <></>
                )}
              </Tooltip>
            </TooltipProvider>
          }
        </div>

        <PasswordValidationGroup
          password={password}
          handlePassword={handlePasswordChange}
          isDarkBackground
        />

        <div className="d-flex flex-column gap-3">
          <Button size="lg" type="submit">
            {saveButtonText}
          </Button>
          <Button size="lg" variant="secondary" onClick={onDismiss}>
            {cancelButtonText}
          </Button>
        </div>
      </div>
    </form>
  );
};

export default PasswordForm;
