import useDebtHeloanConsolidateOfferPopup from "../../../../components/modals/debt-heloan-consolidate-offer-popup";

const DebtHeloanConsolidateDisclaimerCta = ({
  children,
}: {
  children?: React.ReactNode | string;
}) => {
  const { buildDebtHeloanConsolidateOfferPopup } =
    useDebtHeloanConsolidateOfferPopup();

  return (
    <a
      onClick={() => buildDebtHeloanConsolidateOfferPopup()}
      role="button"
      className="tw-text-link-rest hover:tw-text-link-hover active:tw-text-link-pressed tw-cursor-pointer"
    >
      {children}
    </a>
  );
};

export default DebtHeloanConsolidateDisclaimerCta;
