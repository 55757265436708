import { ReactNode } from "react";
import Modal, { ModalProps } from "react-bootstrap/Modal";
import { ReactComponent as CloseIcon } from "purple-rain/assets/icons/modal-close.svg";
import { Button } from "../../atoms/button";
import { cn } from "ui";
import { FEATURE_GATES, useFeatureGate } from "../../../hooks/use-feature-gate";

/**
 * BaseModalProps defines the properties for the BaseModal component.
 *
 * @property {() => void} [onClose] - Function called to close the modal.
 * @property {() => void} [onCloseButtonClick] - Function called when the close button is clicked. This will call handleClose after execution.
 */
export type BaseModalProps = {
  id?: string;
  title?: string | ReactNode;
  titleClassName?: string;
  show?: boolean;
  onClose?: () => void;
  onCloseButtonClick?: () => void;
  children?: ReactNode;
  buttons?: ReactNode;
  headerClassName?: string;
  bodyClassName?: string;
  footerClassName?: string;
} & ModalProps;

const BaseModal = ({
  title,
  titleClassName,
  children,
  buttons,
  onClose,
  headerClassName,
  bodyClassName,
  footerClassName,
  onCloseButtonClick,
  ...modalProps
}: BaseModalProps) => {
  const isLakeviewV2Enabled = useFeatureGate(FEATURE_GATES.ENABLE_LAKEVIEW_2_0);

  const currentTitleClassName =
    titleClassName || "mb-0 typeface-h3 text-typography-surface-high-emphasis";

  const titleClassNameV2 =
    "tw-text-h3 tw-mb-0 tw-mr-4 tw-text-heading tw-font-serif tw-font-normal";

  const bodyClassNameV2 =
    "tw-p-0 tw-text-regular tw-text-primary tw-font-sans tw-font-normal [&_a:not([type='button'])]:tw-text-link-rest [&_a:not([type='button']):hover]:tw-text-link-hover [&_a:not([type='button']):active]:tw-text-link-pressed";

  return (
    <Modal onHide={onClose} centered {...modalProps}>
      {(title || onCloseButtonClick) && (
        <Modal.Header className={headerClassName}>
          {title && (
            <h4
              className={cn(
                currentTitleClassName,
                isLakeviewV2Enabled ? titleClassNameV2 : ""
              )}
              data-testid="modal-title"
            >
              {title}
            </h4>
          )}
          {onCloseButtonClick && (
            <Button
              variant="ghost"
              size="sm"
              aria-label="Close"
              onClick={onCloseButtonClick}
            >
              <CloseIcon />
            </Button>
          )}
        </Modal.Header>
      )}
      <Modal.Body
        className={cn(
          bodyClassName,
          isLakeviewV2Enabled ? bodyClassNameV2 : ""
        )}
      >
        {children}
      </Modal.Body>
      {buttons && (
        <Modal.Footer className={footerClassName}>{buttons}</Modal.Footer>
      )}
    </Modal>
  );
};

export default BaseModal;
