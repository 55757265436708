// Include our external dependencies.
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { handleLogOut } from "../helpers/auth";
import dashApi from "../helpers/dash-api";
import { AsyncStatus, User } from "../types";
import { updateTCModal } from "./sso-user-slice";

// Shortcut(s) to our API functions
const getUser = dashApi.path("/user").method("get").create();
const patchUser = dashApi.path("/user").method("patch").create();
const putResetPassword = dashApi.path("/user/password").method("put").create();

// - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -|
// User Slice (State)
// - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -|
export interface UserState {
  user: User | undefined;
  initialized: boolean;
  updateState: AsyncStatus;
  resetPasswordState: AsyncStatus;
  status: "loading" | "unauthenticated" | "authenticated" | "failed";
}
const initialState: UserState = {
  user: undefined,
  initialized: false,
  updateState: "idle",
  resetPasswordState: "idle",
  status: "unauthenticated",
};

// Type for /user errors
// TODO: This can be customized to include error messages according to backend's error codes
interface UserFetchError {
  status: number;
}

// Check Current User - Are we logged in?
export const checkForUser = createAsyncThunk(
  "user/checkForUser",
  async function (_: undefined, { rejectWithValue }) {
    return await getUser({}).catch((err) => {
      // send the status code to the rejected reducer
      return rejectWithValue({ status: err.status });
    });
  }
);

// Refresh user - silently update user information
export const refreshUser = createAsyncThunk(
  "user/refreshUser",
  async function (_: undefined, { rejectWithValue }) {
    return await getUser({}).catch((err) => {
      // send the status code to the rejected reducer
      return rejectWithValue({ status: err.status });
    });
  },
  {
    condition: (_: undefined, { getState }) => {
      // do not refresh the user if the checkForUser is still loading
      // or if there is no user saved in the store
      const { user, status } = getState() as UserState;
      if (status === "loading" || !user) {
        return false;
      }
    },
  }
);

// Update User Info
export const updateUser = createAsyncThunk(
  "user/updateUser",
  async function (props: Partial<User>, { dispatch }) {
    return await patchUser({ ...props })
      .then((response) => {
        dispatch(updateTCModal(false));

        return response;
      })
      .catch((err) => {
        throw err;
      });
  }
);

// Send reset password request
export const resetPassword = createAsyncThunk(
  "user/resetPassword",
  async function () {
    return await putResetPassword(null);
  }
);

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    clearUser(state) {
      state.user = undefined;
    },
    onSSOUserCreated(state, action) {
      const payload = action.payload as Partial<User>;
      state.user = {
        ...state.user,
        has_fastlane_account: true,
        disclaimer_acceptances: payload.disclaimer_acceptances,
        marketing_communication: payload.marketing_communication,
      };
    },
  },
  extraReducers: (builder) => {
    builder

      // checkForUser
      .addCase(checkForUser.pending, (state) => {
        state.status = "loading";
      })
      .addCase(checkForUser.fulfilled, (state, action) => {
        state.status = "authenticated";
        state.user = action.payload.data.data;
        state.initialized = true;
      })
      .addCase(checkForUser.rejected, (state, action) => {
        const payload = action.payload as UserFetchError;

        // accept 401 and 403 responses as valid indications of no session
        // mark the user as unauthenticated instead of failed
        if (payload.status === 401 || payload.status === 403) {
          state.status = "unauthenticated";
          state.user = undefined;
          state.initialized = true;
        } else {
          state.status = "failed";
          state.user = undefined;
        }
      })

      // update user
      .addCase(updateUser.pending, (state) => {
        state.updateState = "loading";
      })
      .addCase(updateUser.rejected, (state) => {
        state.updateState = "failed";
      })
      .addCase(updateUser.fulfilled, (state, action) => {
        state.updateState = "loaded";
        state.user = action.payload.data.data;
      })

      // refresh user information without triggering loading states
      .addCase(refreshUser.fulfilled, (state, action) => {
        state.user = action.payload.data.data;
      })
      .addCase(refreshUser.rejected, (state, action) => {
        const payload = action.payload as UserFetchError;

        // having errors 401 and 403 when refreshing the user information means the session expired
        if (payload.status === 403 || payload.status === 401) {
          // force user logout
          handleLogOut();
        }

        // if the user fails to refresh for other reason, we do nothing else
      })

      // reset Password
      .addCase(resetPassword.pending, (state) => {
        state.resetPasswordState = "loading";
      })
      .addCase(resetPassword.rejected, (state) => {
        state.resetPasswordState = "failed";
      })
      .addCase(resetPassword.fulfilled, (state) => {
        state.resetPasswordState = "loaded";
      });
  },
});

export const { onSSOUserCreated } = userSlice.actions;
