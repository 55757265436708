import { FormEvent } from "react";
import { translate as t } from "../../../helpers/i18n";
import TermConditionsDigitalComponent from "../terms-and-conditions-digital-component";
import {
  ACCOUNT_TYPES,
  DIGITAL_TERMS_CONDITIONS_LAST_UPDATED,
  TERMS_CONDITIONS_LAST_UPDATED,
} from "../../../helpers/constants";
import DigitalTermsCheckboxGroup from "../terms-and-conditions-digital-component/digital-terms-checkbox-group";
import ModalButtonsWrapper from "../modal/components/modal-buttons-wrapper";
import { Button } from "../../atoms/button";
import { MIXPANEL_EVENTS } from "analytics";
import mixpanel from "mixpanel-browser";
import { useLocation } from "react-router-dom";
import TermAndConditionsDashboardComponentV2 from "../terms-and-conditions-dashboard-component/terms-and-conditions-dashboard-component-v2";
import DashboardTermsCheckboxGroupV2, {
  TermsData,
} from "../terms-and-conditions-dashboard-component/dashboard-terms-checkbox-group-v2";
import { UITheme } from "ui";
import GenericBaseModal from "../modal/generic-base-modal";

interface AcceptTCModalProps {
  module: string;
  show: boolean;
  onClose: () => void;
  onSubmit: (e: FormEvent<HTMLFormElement>) => void;
  isLoading: boolean;
  isFailure: boolean;
  isUpdatedTermsAndConditions?: boolean;
  termsData: TermsData;
  accountType: `${ACCOUNT_TYPES}`;
  handleChange: (termDataName: string, currentValue: boolean) => void;
  viewSource?: string;
  isFormInvalid: boolean;
}

const AcceptTCModalV2 = ({
  module,
  show,
  onClose,
  onSubmit,
  isLoading,
  isFailure,
  isUpdatedTermsAndConditions,
  handleChange,
  termsData,
  accountType,
  viewSource,
  isFormInvalid,
}: AcceptTCModalProps) => {
  const { pathname } = useLocation();

  const handleShow = () => {
    mixpanel.track(MIXPANEL_EVENTS.MODULE_SERVED, {
      module,
      path: pathname,
      ...(viewSource && { view_source: viewSource }),
    });
  };

  const isDigitalAccount = accountType === ACCOUNT_TYPES.DIGITAL_ACCOUNT;

  const handleCloseModal = (from: string) => {
    if (!isDigitalAccount)
      mixpanel.track(MIXPANEL_EVENTS.CLICK, {
        module: module,
        button: from,
        path: pathname,
      });

    onClose();
  };

  const lastUpdated =
    accountType === ACCOUNT_TYPES.DIGITAL_ACCOUNT
      ? DIGITAL_TERMS_CONDITIONS_LAST_UPDATED
      : TERMS_CONDITIONS_LAST_UPDATED;

  return (
    <GenericBaseModal
      show={show}
      onShow={handleShow}
      onClose={() => handleCloseModal("off-module-click-to-close")}
      onCloseButtonClick={() => handleCloseModal("close")}
      fullscreen="md-down"
      gridSize={10}
      title={
        isUpdatedTermsAndConditions
          ? t("updated_terms_conditions.modal_title")
          : t("terms_conditions.modal_title")
      }
      buttons={
        <form onSubmit={onSubmit} noValidate className="tw-w-full tw-z-2">
          <div className="tw-grid tw-gap-3 tw-mb-6">
            {isDigitalAccount ? (
              <DigitalTermsCheckboxGroup />
            ) : (
              <DashboardTermsCheckboxGroupV2
                termsData={termsData}
                handleChange={handleChange}
                isFormInvalid={isFormInvalid}
              />
            )}

            {isFailure && (
              <div className="d-block invalid-feedback">
                {t("error.regular_message")}
              </div>
            )}
          </div>
          <ModalButtonsWrapper>
            <Button
              data-testid="terms-conditions-agree"
              isLoading={isLoading}
              type="submit"
            >
              {t("terms_conditions.continue_button_v2")}
            </Button>
            <Button
              variant="secondary"
              theme={UITheme.LAKEVIEW_BLUE}
              onClick={() => handleCloseModal("cancel")}
              // explicitly set as non-submit button
              type="button"
            >
              {t("terms_conditions.cancel_button_v2")}
            </Button>
          </ModalButtonsWrapper>
        </form>
      }
    >
      <div className="tw-mb-8 tw-text-secondary">
        <p>{lastUpdated}</p>
      </div>
      <div className="terms-accordion tw-z-1">
        {isDigitalAccount ? (
          <TermConditionsDigitalComponent isContainedInModal />
        ) : (
          <TermAndConditionsDashboardComponentV2 />
        )}
      </div>
    </GenericBaseModal>
  );
};

export default AcceptTCModalV2;
