import {
  ChangeEvent,
  FormEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  getFormValues,
  handleValidation,
} from "../../../helpers/form-validation";
import {
  ACCOUNT_TYPES,
  AFBA_VERSION,
  DIGITAL_ACCOUNT_VERSIONS,
  TERMS_CONDITIONS_OPEN_QUERY_PARAM_NAME,
  TERMS_CONDITIONS_VERSION,
  USER_DISCLAIMERS,
} from "../../../helpers/constants";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../stores";
import { useLocation, useSearchParams } from "react-router-dom";
import { useSSOValues } from "../../../hooks/use-sso-values";
import { createSSOUser } from "../../../stores/sso-user-slice";
import mixpanel from "mixpanel-browser";
import { MIXPANEL_EVENTS } from "analytics";
import AccountCreatedModal from "./account-created-modal";
import AcceptTCModal from "./accept-tc-modal";
import DeclinedTCConfirmationModal from "./declined-tc-confirmation-modal";
import AcceptAfBAModal from "./accept-afba-modal";
import AcceptAfBAModalV2 from "./accept-afba-modal-v2";
import { CreateSSOAccountDetails, User } from "../../../types";
import { updateUser } from "../../../stores/user-slice";
import { isMajorVersionDifferent } from "../../../helpers/utils";
import { pages } from "../../../helpers/pages";
import { FEATURE_GATES, useFeatureGate } from "../../../hooks/use-feature-gate";
import AcceptTCModalV2 from "./accept-tc-modal-v2";

interface Props {
  module?: string;
  viewSource?: string;
  accountCreatedModule?: string;
  password?: string;
  isUpdatedTermsAndConditions?: boolean;
  isUncompletedProfile?: boolean;
  onCloseTermsModal?: () => void;
  onCloseAccountCreation?: () => void;
  onSubmitAccountCreation?: () => void;
  shouldClearModalState?: boolean;
}

const TermsAndConditionsModal = ({
  module = "sso-terms-conditions",
  accountCreatedModule = "sso-account-creation-success",
  password,
  isUpdatedTermsAndConditions,
  isUncompletedProfile,
  onCloseTermsModal,
  onCloseAccountCreation,
  onSubmitAccountCreation,
  shouldClearModalState,
  viewSource,
}: Props) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useDispatch<AppDispatch>();
  const { pathname } = useLocation();
  const {
    user: userState,
    ssoUser: ssoUserState,
    tavant: { isTavantProcess },
  } = useSelector((state: RootState) => state);

  const [showAcceptTCModal, setShowAcceptTCModal] = useState<boolean>(
    !!isUpdatedTermsAndConditions
  );

  const [showDeclinedTCConfirmationModal, setShowDeclinedTCConfirmationModal] =
    useState<boolean>(false);
  const [showAcceptAfBAModal, setShowAcceptAfBAModal] =
    useState<boolean>(false);
  // this termsData state is only really here for legacy reasons; not used to save data to the API
  const [termsData, setTermsData] = useState({
    agreement_terms: false,
    credit_reports: false,
    electronic_signatures: false,
    marketing_telephone_communication: false,
  });

  const [isFormInvalid, setIsFormInvalid] = useState(false);

  const handleLakeviewTermsV2 = (
    termDataName: string,
    currentValue: boolean
  ) => {
    setTermsData((prev) => ({
      ...prev,
      [termDataName]: currentValue,
    }));
  };

  const handleLakeviewTerms = (
    e: ChangeEvent<HTMLInputElement>,
    currentValue: boolean
  ) => {
    setTermsData((prev) => ({
      ...prev,
      [e.target.name]: !currentValue,
    }));
  };

  const { isPendingFastlaneAccount } = useSSOValues();

  const { user, updateState } = userState;
  const ssoUserStatus = ssoUserState.status;
  const isUserAfBAVersionOutdated = isMajorVersionDifferent(
    AFBA_VERSION,
    user?.disclaimer_acceptances?.AFBA?.version
  );

  const isIdling = ssoUserStatus === "idle" || updateState === "idle";
  const hasFailed = ssoUserStatus === "failed" || updateState === "failed";
  const isLoading = ssoUserStatus === "loading" || updateState === "loading";
  const isModalOpen =
    searchParams.get(TERMS_CONDITIONS_OPEN_QUERY_PARAM_NAME) === "true";

  const showTermsModal =
    (isPendingFastlaneAccount && isModalOpen && !!password) ||
    showAcceptTCModal;

  const showCreatedAccountModal = ssoUserState.showCreatedAccountModal;

  const accountType = useMemo(
    () =>
      !!user?.disclaimer_acceptances?.DIGITAL_ACCOUNT_TERMS_AND_CONDITIONS
        ?.version
        ? ACCOUNT_TYPES.DIGITAL_ACCOUNT
        : ACCOUNT_TYPES.FULL_ACCOUNT,

    [user]
  );

  const isLakeviewV2Enabled = useFeatureGate(FEATURE_GATES.ENABLE_LAKEVIEW_2_0);

  const handleCloseTermsModal = useCallback(() => {
    if (isModalOpen) {
      setSearchParams({});
    }
  }, [setSearchParams, isModalOpen]);

  // after the update or create request is fulfilled we remove the query param
  useEffect(() => {
    if (!ssoUserState.showTCModal) {
      handleCloseTermsModal();
    }
  }, [ssoUserState.showTCModal, handleCloseTermsModal]);

  // if account is created we update the user and close the modal
  useEffect(() => {
    if (showCreatedAccountModal) {
      handleCloseTermsModal();
    }
  }, [
    showCreatedAccountModal,
    handleCloseTermsModal,
    accountCreatedModule,
    showTermsModal,
  ]);

  useEffect(() => {
    if (showDeclinedTCConfirmationModal) {
      mixpanel.track(MIXPANEL_EVENTS.MODULE_SERVED, {
        module: "confirm-stop-completing-profile",
        path: pathname,
        view_source: module,
      });
    }
  }, [showDeclinedTCConfirmationModal, module, pathname]);

  const handleDismissModal = () => {
    setShowAcceptTCModal(false);
    handleCloseTermsModal();
    setTermsData({
      agreement_terms: false,
      credit_reports: false,
      electronic_signatures: false,
      marketing_telephone_communication: false,
    });
    setShowDeclinedTCConfirmationModal(true);
  };

  /**
   * Considering the account type, build a T&C object that uses the
   * disclaimer_acceptances structure
   * @param {string | undefined} marketingComms
   */
  const constructTermsObject = (marketingComms: string | undefined) => {
    let termsData: Partial<User> = {};

    // pull the optional field from marketingTelephoneComms
    if (accountType === ACCOUNT_TYPES.DIGITAL_ACCOUNT) {
      termsData = {
        disclaimer_acceptances: {
          ...user?.disclaimer_acceptances,
          [USER_DISCLAIMERS.DIGITAL_ACCOUNT_TERMS_AND_CONDITIONS]: {
            version: DIGITAL_ACCOUNT_VERSIONS.TERMS_AND_CONDITIONS,
          },
          [USER_DISCLAIMERS.DIGITAL_ACCOUNT_USE_ELECTRONIC_RECORDS]: {
            version: DIGITAL_ACCOUNT_VERSIONS.USE_ELECTRONIC_RECORDS,
          },
        },
      };

      if (marketingComms) {
        termsData!.disclaimer_acceptances![
          USER_DISCLAIMERS.DIGITAL_ACCOUNT_TELEPHONE_COMMUNICATIONS
        ] = {
          version: DIGITAL_ACCOUNT_VERSIONS.TELEPHONE_COMMUNICATIONS,
        };
      }
    } else {
      // full account terms
      termsData = {
        disclaimer_acceptances: {
          ...user?.disclaimer_acceptances,
          [USER_DISCLAIMERS.TERMS_AND_CONDITIONS]: {
            version: TERMS_CONDITIONS_VERSION,
          },
          [USER_DISCLAIMERS.USE_ELECTRONIC_SIGNATURES]: {
            version: TERMS_CONDITIONS_VERSION,
          },
          [USER_DISCLAIMERS.OBTAIN_CREDIT_REPORTS]: {
            version: TERMS_CONDITIONS_VERSION,
          },
        },
      };

      if (marketingComms) {
        /**
         * @note this field is currently not part of disclaimer_acceptances
         * structure, and there are no plans to move it */
        termsData.marketing_telephone_communication = true;
      }
    }

    return termsData;
  };

  const handleCreateSSOUser = () => {
    const data: Partial<CreateSSOAccountDetails> = {
      password,
      disclaimer_acceptances: {
        ...user?.disclaimer_acceptances,
        TERMS_AND_CONDITIONS: {
          version: TERMS_CONDITIONS_VERSION,
        },
        OBTAIN_CREDIT_REPORTS: {
          version: TERMS_CONDITIONS_VERSION,
        },
        USE_ELECTRONIC_SIGNATURES: {
          version: TERMS_CONDITIONS_VERSION,
        },
      },
      marketing_telephone_communication:
        termsData.marketing_telephone_communication,
      marketing_communication: true,
    };

    mixpanel.track(MIXPANEL_EVENTS.FORM_SUBMIT, {
      module: module,
      fields: {
        disclaimer_acceptances: {
          TERMS_AND_CONDITIONS: {
            version: TERMS_CONDITIONS_VERSION,
          },
          OBTAIN_CREDIT_REPORTS: {
            version: TERMS_CONDITIONS_VERSION,
          },
          USE_ELECTRONIC_SIGNATURES: {
            version: TERMS_CONDITIONS_VERSION,
          },
        },
        marketing_telephone_communication:
          termsData.marketing_telephone_communication,
      },
    });

    dispatch(
      createSSOUser({
        ...data,
      })
    );
  };

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    setIsFormInvalid(false);
    const isFormValid = handleValidation(e);

    if (!isFormValid) {
      setIsFormInvalid(true);
      return;
    }

    let marketingComms: string | undefined;

    if (accountType === ACCOUNT_TYPES.DIGITAL_ACCOUNT) {
      const formData = getFormValues<{
        terms_and_conditions: string;
        use_electronic_records: string;
        telephone_communications?: string;
      }>(e);

      marketingComms = formData.telephone_communications;
    } else {
      const formData = getFormValues<{
        agreement_terms: string;
        electronic_signatures: string;
        credit_reports: string;
        marketing_telephone_communication?: string;
      }>(e);

      marketingComms = formData.marketing_telephone_communication;
    }

    setShowAcceptTCModal(false);
    createOrUpdateUser(marketingComms);
    onSubmitAccountCreation?.();

    mixpanel.track(MIXPANEL_EVENTS.CLICK, {
      module: module,
      button: "agree-&-continue",
      path: pathname,
    });

    /**
     * Tracking for the "sso-account-creation-success" is now triggered when the T&C are accepted
     * and user creation is successfully completed, instead of on the first render.
     * This ensures the event is not tracked multiple times due to re-renders caused by setting
     * "has_fastlane_account" to "true". By tracking it only after user creation, we avoid
     * redundant event triggers
     */
    mixpanel.track(MIXPANEL_EVENTS.MODULE_SERVED, {
      module: accountCreatedModule,
    });
  };

  // Update the user's terms and conditions version
  const createOrUpdateUser = (marketingComms: string | undefined) => {
    // Only proceed if the button hasn't been clicked before (isIdling) or if the previous attempt failed (hasFailed)
    // This prevents unnecessary API calls and ensures we only create/update when needed
    if (!isIdling && !hasFailed) {
      return;
    }

    if (isUncompletedProfile) {
      handleCreateSSOUser();
      return;
    }

    const data: Partial<User> = constructTermsObject(marketingComms);
    dispatch(updateUser(data));
  };

  // Go back confirmation button logic
  const handleGoBackTCConfirmation = () => {
    setShowDeclinedTCConfirmationModal(false);
    setShowAcceptTCModal(true);

    mixpanel.track(MIXPANEL_EVENTS.CLICK, {
      module: "confirm-stop-completing-profile",
      button: "complete-my-profile",
      path: pathname,
    });
  };

  const handleExitProfileSetup = () => {
    setShowDeclinedTCConfirmationModal(false);
    handleCloseTermsModal();
    onCloseTermsModal?.();

    mixpanel.track(MIXPANEL_EVENTS.CLICK, {
      module: "confirm-stop-completing-profile",
      button: "close",
      path: pathname,
    });
  };

  const handleCloseAfBA = () => {
    setShowAcceptAfBAModal(false);
  };

  const handleAcceptAfBA = () => {
    const data: Partial<User> = {
      disclaimer_acceptances: {
        ...user?.disclaimer_acceptances,
        AFBA: { version: AFBA_VERSION },
      },
    };

    dispatch(updateUser(data));
    setShowAcceptAfBAModal(false);
  };

  const handleShowAfBAModal = () => {
    if (isUserAfBAVersionOutdated && !isTavantProcess) {
      setShowAcceptAfBAModal(true);
    }

    onCloseAccountCreation?.();
  };

  const acceptAfBAModalProps = {
    show: showAcceptAfBAModal,
    onCancel: handleCloseAfBA,
    onAccept: handleAcceptAfBA,
    isLoading,
    shouldClearModalState,
    eventParams: { path: pages.home, link: pages.home, view_source: module },
  };

  const acceptTCModalProps = {
    module: module,
    show: showTermsModal,
    onClose: handleDismissModal,
    onSubmit: handleSubmit,
    isLoading: isLoading,
    isFailure: hasFailed,
    termsData: termsData,
    handleChange: handleLakeviewTerms,
    isUpdatedTermsAndConditions: isUpdatedTermsAndConditions,
    accountType: accountType,
    viewSource: viewSource,
    isFormInvalid: isFormInvalid,
  };

  const acceptTCModalPropsV2 = {
    module: module,
    show: showTermsModal,
    onClose: handleDismissModal,
    onSubmit: handleSubmit,
    isLoading: isLoading,
    isFailure: hasFailed,
    termsData: termsData,
    handleChange: handleLakeviewTermsV2,
    isUpdatedTermsAndConditions: isUpdatedTermsAndConditions,
    accountType: accountType,
    viewSource: viewSource,
    isFormInvalid: isFormInvalid,
  };

  return (
    <>
      <AccountCreatedModal
        show={showCreatedAccountModal}
        module={accountCreatedModule}
        onClose={handleShowAfBAModal}
      />
      {isLakeviewV2Enabled ? (
        <AcceptTCModalV2 {...acceptTCModalPropsV2} />
      ) : (
        <AcceptTCModal {...acceptTCModalProps} />
      )}
      {isLakeviewV2Enabled ? (
        <AcceptAfBAModalV2 {...acceptAfBAModalProps} />
      ) : (
        <AcceptAfBAModal {...acceptAfBAModalProps} />
      )}
      <DeclinedTCConfirmationModal
        show={showDeclinedTCConfirmationModal}
        onGoBack={handleGoBackTCConfirmation}
        isUncompletedProfile={isUncompletedProfile}
        onExitProfileSetup={handleExitProfileSetup}
      />
    </>
  );
};

export default TermsAndConditionsModal;
