import { ReactComponent as StandaloneLogoLakeviewSVG } from "purple-rain/assets/standalone-logo-lakeview.svg";
import { ReactComponent as LogoLakeviewUnauthenticatedRebrandSVG } from "purple-rain/assets/logo-lakeview-unauthenticated-rebrand.svg";
import { translate as t } from "../../../helpers/i18n";
import { Link } from "react-router-dom";
import { pages } from "../../../helpers/pages";
import LegalDisclaimer from "./legal-disclaimer";
import FooterBase from "./footer-base";
import useStandaloneMode from "../../../hooks/use-standalone";
import { EXPERIMENTS, useABTest } from "../../../hooks/use-ab-test";
import mixpanel from "mixpanel-browser";
import { MIXPANEL_EVENTS } from "analytics";
import {
  Theme,
  ThemeType,
  useThemeContext,
} from "../../../contexts/theme-context";
import { cva } from "class-variance-authority";
import { useDashboardVersion } from "../../../hooks";
import { DashboardType } from "../../../types";
import {
  NewExperimentNameGroup,
  NoneExperimentGroup,
} from "../../../helpers/experiment-exposure";
import useExperimentManualExposure from "../../../hooks/use-experiment-manual-exposure";
import { FEATURE_GATES, useFeatureGate } from "../../../hooks/use-feature-gate";
import { cn, UILogo } from "ui";
import { EXTERNAL_LINKS } from "../../../helpers/constants";
import { ExternalLink } from "../../../components/external-link";

const MIXPANEL_MODULE_NAME = "footer";

interface ListItem {
  translationKey: string;
  path: string;
  options?: {
    isExternal?: boolean;
    featureGate?: string;
    mixpanelButtonName?: string;
  };
}

const PRODUCT_LIST = (includeHomeEquityLoan?: boolean): ListItem[] => [
  // Cash Out Refinance
  {
    translationKey: "layout.products_list.cash_out_refinance.secondary_title",
    path: pages.products.cashOutRefinance,
    options: {
      mixpanelButtonName: "go-to-cashout-pdp",
    },
  },
  {
    translationKey: "layout.products_list.home_equity_loan.secondary_title",
    path: includeHomeEquityLoan
      ? pages.products.homeEquityLoan
      : pages.products.cashOutRefinance,
    options: {
      mixpanelButtonName: "go-to-heloan-pdp",
    },
  },

  // Get a Mortgage
  {
    translationKey: "layout.products_list.get_mortgage.secondary_title",
    path: pages.products.getAMortgage,
    options: {
      mixpanelButtonName: "go-to-mortgage-pdp",
    },
  },
  // Rate Term Refinance
  {
    translationKey: "layout.products_list.rate_term_refinance.secondary_title",
    path: pages.products.rateTermRefinance,
    options: {
      mixpanelButtonName: "go-to-rtr-pdp",
    },
  },
];

const RESOURCES_LIST: ListItem[] = [
  {
    translationKey: "layout.footer.resource_hub",
    path: pages.resourceCenter,
  },
  {
    translationKey: "layout.footer.faq_and_support",
    path: pages.frequentlyAskedQuestions,
  },
  {
    translationKey: "layout.footer.hmda",
    path: EXTERNAL_LINKS.HMDA,
    options: {
      isExternal: true,
      featureGate: FEATURE_GATES.ENABLE_LAKEVIEW_PDPS,
    },
  },
  {
    translationKey: "layout.footer.licensing",
    path: EXTERNAL_LINKS.LICENSING,
    options: {
      isExternal: true,
      featureGate: FEATURE_GATES.ENABLE_LAKEVIEW_PDPS,
    },
  },
  {
    translationKey: "layout.footer.terms_and_conditions",
    path: pages.termsConditions,
  },
];

const productListContainerItemContentVariants = cva(
  "d-flex flex-column  align-items-start",
  {
    variants: {
      theme: {
        [Theme.PURPLE_RAIN]: "gap-3",
        [Theme.LAKEVIEW_BLUE]: "gap-2 gap-md-3",
      },
    },
  }
);

const productListItemContentVariants = cva("", {
  variants: {
    theme: {
      [Theme.PURPLE_RAIN]: "text-indigo-2",
      [Theme.LAKEVIEW_BLUE]: "",
    },
  },
});

const renderItemList = (
  itemList: ListItem[],
  mixpanelPageName: `/${DashboardType}`,
  theme: ThemeType,
  experimentManualExposure,
  groupName: NewExperimentNameGroup | NoneExperimentGroup = "None"
) => {
  const filteredList = itemList.filter(
    (item) =>
      !item.options?.featureGate ||
      useFeatureGate(item.options?.featureGate || "")
  );

  const handleClick = (item: ListItem) => {
    const link = `${window.location.origin}${item.path}`;

    if (item.options?.mixpanelButtonName) {
      mixpanel.track(MIXPANEL_EVENTS.CLICK, {
        module: MIXPANEL_MODULE_NAME,
        button: item.options.mixpanelButtonName,
        page: mixpanelPageName,
        link,
      });
    }

    if (
      t(item.translationKey) ===
      t("layout.products_list.home_equity_loan.secondary_title")
    ) {
      experimentManualExposure(EXPERIMENTS.TAVANT_DIGITAL_HELOAN, groupName, [
        "Control1",
        "Treatment1",
      ]);
    }
  };

  const renderItem = (item: ListItem, index: number) => {
    const commonLinkProps = {
      key: item.translationKey,
      className: cn(
        productListItemContentVariants({ theme }),
        index === 0 ? "m-0" : ""
      ),
      onClick: () => handleClick(item),
    };

    return item.options?.isExternal ? (
      <ExternalLink {...commonLinkProps} href={item.path} targetBlank>
        {t(item.translationKey)}
      </ExternalLink>
    ) : (
      <Link {...commonLinkProps} to={item.path}>
        {t(item.translationKey)}
      </Link>
    );
  };

  return (
    <div className={productListContainerItemContentVariants({ theme })}>
      {filteredList.map((item, index) => renderItem(item, index))}
    </div>
  );
};

const Footer = ({ showProducts = true, showResources = true }) => {
  const isStandalone = useStandaloneMode();
  const { isExperimentAvailable: isHeloanHomeEquityLoanActive, groupName } =
    useABTest(EXPERIMENTS.TAVANT_DIGITAL_HELOAN);
  const isLPbFLogoUpdateEnabled = useFeatureGate(
    FEATURE_GATES.ENABLE_LPBF_LOGO_UPDATE
  );
  const { mixpanelPageName } = useDashboardVersion();
  const { theme } = useThemeContext();
  const experimentManualExposure = useExperimentManualExposure();

  const titleContentVariants = cva(
    "tw-col-span-12 mb-3 typeface-h6 fw-bold text-uppercase",
    {
      variants: {
        theme: {
          [Theme.PURPLE_RAIN]: "text-indigo-2",
          [Theme.LAKEVIEW_BLUE]: "",
        },
      },
    }
  );
  const iconLakeviewLogo = (theme: ThemeType) => {
    const iconByTheme = {
      [Theme.PURPLE_RAIN]: isLPbFLogoUpdateEnabled ? (
        <UILogo logo="lakeviewFastlane" />
      ) : (
        <StandaloneLogoLakeviewSVG width={250} height="100%" />
      ),
      [Theme.LAKEVIEW_BLUE]: (
        <LogoLakeviewUnauthenticatedRebrandSVG width={110} height="100%" />
      ),
    };

    return iconByTheme[theme] || iconByTheme[Theme.PURPLE_RAIN];
  };

  if (isStandalone) return <footer className="bg-primary w-full py-2" />;

  const iconStylesGap = !showProducts && !showResources ? "" : "gap-6";

  return (
    <FooterBase includeDisclosures>
      <div className="d-flex flex-column flex-md-row w-100 gap-5">
        <div
          className={`d-flex flex-column flex-xl-row w-100 ${iconStylesGap} gap-xl-7`}
        >
          <div className="pe-xl-5 align-self-start">
            {iconLakeviewLogo(theme)}
          </div>
          <div className="d-flex flex-column flex-md-row w-100 gap-5">
            {showProducts && (
              <div className="w-100">
                <div className={titleContentVariants({ theme })}>
                  {t("layout.footer.products_title")}
                </div>
                {renderItemList(
                  PRODUCT_LIST(isHeloanHomeEquityLoanActive),
                  mixpanelPageName,
                  theme,
                  experimentManualExposure,
                  groupName
                )}
              </div>
            )}
            {showResources && (
              <div className="w-100">
                <div className={titleContentVariants({ theme })}>
                  {t("layout.footer.resources_title")}
                </div>
                <div className="d-flex flex-column gap-3 align-items-start ">
                  {renderItemList(
                    RESOURCES_LIST,
                    mixpanelPageName,
                    theme,
                    experimentManualExposure
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      <hr className="w-100 text-light-pink opacity-100 m-0" />

      {/* Legal disclaimer */}
      <LegalDisclaimer />
    </FooterBase>
  );
};

export default Footer;
