// Include our external dependencies.
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { DisclosureTypeKey } from "../modules/layout/disclosures/disclosures-content";

// - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -|
// Disclosures Slice (State)
// - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -|
interface DisclosureState {
  disclosures: Array<DisclosureTypeKey>;
  popupDisclosures: Array<DisclosureTypeKey>;
}
const initialState: DisclosureState = {
  disclosures: [],
  popupDisclosures: [],
};

export const disclosuresSlice = createSlice({
  name: "disclosure",
  initialState,
  reducers: {
    enableDisclosure(state, action: PayloadAction<DisclosureTypeKey>) {
      const disclosureId = action.payload;
      if (!state.disclosures.includes(disclosureId)) {
        state.disclosures = [...state.disclosures, disclosureId];
      }
    },
    enablePopupDisclosure(state, action: PayloadAction<DisclosureTypeKey>) {
      const disclosureId = action.payload;
      if (!state.popupDisclosures.includes(disclosureId)) {
        state.popupDisclosures = [...state.popupDisclosures, disclosureId];
      }
    },
    disableDisclosureById(state, action: PayloadAction<DisclosureTypeKey>) {
      const disclosureId = action.payload;
      if (state.disclosures.includes(disclosureId)) {
        state.disclosures = state.disclosures.filter(
          (item) => item !== disclosureId
        );
      }
    },
    disablePopupDisclosureById(
      state,
      action: PayloadAction<DisclosureTypeKey>
    ) {
      const disclosureId = action.payload;
      if (state.popupDisclosures.includes(disclosureId)) {
        state.popupDisclosures = state.popupDisclosures.filter(
          (item) => item !== disclosureId
        );
      }
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  enableDisclosure,
  enablePopupDisclosure,
  disableDisclosureById,
  disablePopupDisclosureById,
} = disclosuresSlice.actions;
