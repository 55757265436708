import LPbF from "../../atoms/lpbf";

const ConsentToObtainCreditReport = () => (
  <>
    <p className="mb-s24">
      <b>
        <LPbF /> Dashboard Consent to Obtain Credit Reports
      </b>
    </p>
    <p className="mb-4">
      <span className="fw-bold">Your Consent.</span> By participating in the{" "}
      <LPbF /> Dashboard, you are providing written instructions in accordance
      with the Fair Credit Reporting Act and other applicable laws authorizing
      Lakeview Loan Servicing, LLC (“Lakeview”), and their respective
      affiliates, subsidiaries, and service providers, including Fastlane
      Technology Solutions, LLC, (“Fastlane”) (collective, “we,” “us,” and
      “our”) to obtain consumer reports and related information about you at any
      time from one or more consumer reporting agencies and/or other third
      parties for any lawful purpose. Such lawful purposes include evaluating
      your credit information to: (1) determine your eligibility for Products
      and Services (as defined here or in the <LPbF /> Dashboard Terms of Use)
      in which we think you might be interested, (ii) when considering you for
      referrals and making referrals of Products or Services, (iii) in
      connection with processing any application you submit for a Product or
      Service, and (iv) for credit and risk modelling. In connection with any
      Product or Service you request, you also authorize such parties described
      above to contact any third party to verify information you have submitted
      in order to receive such Product or Service. You understand that credit
      inquiries have the potential to impact your credit score.
    </p>
    <p className="mb-4">
      <span className="fw-bold">Scope of Consent.</span> Your consent applies as
      long as you have a <LPbF /> Dashboard. Your consent is between Lakeview
      Loan Servicing, LLC (“Lakeview”), and their affiliates, subsidiaries, and
      service providers (collectively, “We,” “Us,” and “Our”) which includes
      Fastlane Technology Solutions, LLC (“Fastlane”), and their affiliates,
      subsidiaries, and service providers. Fastlane is the technology service
      provider for the digital experience for Lakeview
    </p>
    <p className="mb-4">
      <span className="fw-bold">Withdrawing Consent.</span> In order to withdraw
      your consent, you must terminate your <LPbF /> Dashboard profile. You may
      do so by contacting Our support team at fastlanesupport@lakeview.com.
      Please note that withdrawing your consent will not affect any other
      consent you may have provided to us, a third- party service provider or
      third-party originating bank, or other third party to obtain consumer
      reports about you, such as in an application for credit regarding a loan.
      You will need to refer to the appropriate Consent to Obtain Credit Reports
      agreement for instructions on how to withdraw those consents. You may
      always apply via lakeview.com or by calling our experienced licensed
      mortgage originators at 1-844-992-1361 if you do not want to continue with
      your <LPbF /> Dashboard experiences.
    </p>
    <p className="mb-4">
      <span className="fw-bold">Federal Equal Credit Opportunity Act.</span> The
      Federal Equal Credit Opportunity Act prohibits from discriminating against
      credit applicants on the basis of race, color, religion, national origin,
      sex, marital status, age (provided the applicant has the capacity to enter
      into a binding contract); because all or part of the applicant’s income
      derives from any public assistance program; or because the applicant has
      in good faith exercised any right under the Consumer Credit Protection
      Act.
    </p>
  </>
);

export default ConsentToObtainCreditReport;
