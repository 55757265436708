import { t } from "i18next";
import React, { useState } from "react";
import { handleValidation } from "../../../helpers/form-validation";
import { Button } from "../../atoms/button";
import PasswordValidationGroupV2 from "../password-validation-form/password-validation-group-v2";
import { UITheme, UITooltip } from "ui";

interface PasswordFormProps {
  email: string;
  eyebrow?: string;
  showEmailText?: boolean;
  saveButtonText: string;
  cancelButtonText: string;
  onSubmit: (isFormValid: boolean, password: string) => void;
  onDismiss: () => void;
}

// Max char number for Email that fits in the password box without ellipsis
const MAX_EMAIL_STR_SIZE_FIT = 40;

const PasswordFormV2 = ({
  email = "",
  eyebrow = "",
  showEmailText = false,
  saveButtonText,
  cancelButtonText,
  onSubmit,
  onDismiss,
}: PasswordFormProps) => {
  const [password, setPassword] = useState("");

  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const isFormValid = handleValidation(event);
    onSubmit(isFormValid, password);
  };

  const emailLabel = showEmailText ? `${t("global.email")}:` : "";
  const emailEyebrowLength = showEmailText
    ? emailLabel.length + email.length
    : email.length;

  return (
    <form onSubmit={handleSubmit} noValidate className="tw-w-full">
      <div className="tw-flex tw-flex-col tw-gap-3">
        <div>
          {eyebrow && (
            <p className="tw-m-0 tw-uppercase tw-font-semibold">{eyebrow}</p>
          )}
          {
            <UITooltip
              tooltipTrigger={
                <p className="tw-text-ellipsis tw-overflow-x-hidden tw-text-nowrap">
                  {emailLabel} {email}
                </p>
              }
              align="start"
              side="top"
              contentId="subservicer-sso-banner-email"
              tooltipContent={
                <p className="tw-text-primary tw-text-small">{email}</p>
              }
              contentType="secondary"
              showContent={emailEyebrowLength > MAX_EMAIL_STR_SIZE_FIT}
            />
          }
        </div>

        <PasswordValidationGroupV2
          password={password}
          handlePassword={handlePasswordChange}
          isDarkBackground
        />

        <div className="tw-flex tw-flex-col tw-gap-3">
          <Button
            size="lg"
            type="submit"
            theme={UITheme.LAKEVIEW_BLUE}
            variant="secondary"
          >
            {saveButtonText}
          </Button>
          <Button size="lg" theme={UITheme.LAKEVIEW_BLUE} onClick={onDismiss}>
            {cancelButtonText}
          </Button>
        </div>
      </div>
    </form>
  );
};

export default PasswordFormV2;
