import dashApi from "../helpers/dash-api";
import { API_ERROR_TYPES } from "../helpers/constants";
import {
  AsyncStatus,
  MortgageSearchParams,
  MortgageSearchResult,
} from "../types";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

type SearchStatus =
  | "initial-state"
  | "try-again"
  | "out-of-attempts"
  | "has-results"
  | "general-error"
  | "session-expired";

/**
 * The set of statuses from the mortgage search that call for a redirect to the next step
 *
 * @note we might want to handle general-error separately at some point
 * */
export const REDIRECT_STATUSES: Partial<SearchStatus>[] = [
  "has-results",
  "out-of-attempts",
  "general-error",
];

// - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -|
// Endpoints
// - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -|
export const mortgageSearchRequest = dashApi
  .path("/v2/mortgage-search")
  .method("post")
  .create();

// - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -|
// State
// - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -|
export interface DACMortgageSearchResultsState {
  mortgages: MortgageSearchResult[];
  status: AsyncStatus;
  searchStatus: SearchStatus;
}

const initialDACMortgageSearchResults: DACMortgageSearchResultsState = {
  mortgages: [],
  status: "idle",
  searchStatus: "initial-state",
};

// - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -|
// API Calls
// - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -|
export const mortgageSearch = createAsyncThunk(
  "mortgageSearch/search",
  async function (
    {
      body,
      nonce,
    }: {
      body: MortgageSearchParams;
      nonce: string;
    },
    { rejectWithValue }
  ) {
    try {
      const response = await mortgageSearchRequest(body, {
        headers: {
          nonce,
        },
      });

      return response;
    } catch (error) {
      const isInvalidNonceError =
        error?.data?.error_code === API_ERROR_TYPES.INVALID_NONCE;

      // need to set the status code artificially here
      return rejectWithValue({
        status: error.status,
        ...(isInvalidNonceError && { showToast: false }),
      });
    }
  }
);

// - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -|
// Slices
// - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -|
export const mortgageSearchSlice = createSlice({
  name: "mortgageSearch",
  initialState: initialDACMortgageSearchResults,
  reducers: {
    resetMortgageSearch: () => initialDACMortgageSearchResults,
  },
  extraReducers: (builder) => {
    builder
      .addCase(mortgageSearch.pending, (state) => {
        state.status = "loading";
      })
      .addCase(mortgageSearch.fulfilled, (state, action) => {
        state.status = "loaded";
        const hasRemainingAttempts = action.payload.data.has_remaining_attempts;
        const mortgages = action.payload.data.data as MortgageSearchResult[];
        state.mortgages = mortgages ?? [];

        if (!hasRemainingAttempts) {
          state.searchStatus = "out-of-attempts";
          return;
        }

        state.searchStatus = !mortgages?.length ? "try-again" : "has-results";
      })
      .addCase(mortgageSearch.rejected, (state, action) => {
        const error = action.payload as { status: number };
        state.status = "failed";
        state.mortgages = [];

        switch (error.status) {
          case 429:
            // nonce is valid, but can't be used for searches anymore
            state.searchStatus = "out-of-attempts";
            return;
          case 401:
            // request was made with an invalid nonce
            state.searchStatus = "session-expired";
            return;
          case 400:
            // request was made without a nonce
            state.searchStatus = "session-expired";
            return;
          default:
            state.searchStatus = "general-error";
        }
      });
  },
});

// Action creators are generated for each case reducer function
export const { resetMortgageSearch } = mortgageSearchSlice.actions;
