import {
  ChangeEventHandler,
  MutableRefObject,
  useEffect,
  useRef,
  useState,
} from "react";
import { isTestPasswordValid } from "../helpers/constants";
import { ReactComponent as HiddenSVG } from "purple-rain/icons/hidden.svg";
import { ReactComponent as VisibleSVG } from "purple-rain/icons/visible.svg";
import { translate as t } from "../helpers/i18n";

interface PasswordInputProps {
  password: string | number;
  handlePassword: ChangeEventHandler<HTMLInputElement>;
  placeholder: string;
  label: string;
  id: string;
  required?: boolean;
  invalidFeedback?: string;
  pattern?: string;
  minLength?: number;
  maxLength?: number;
  invalidFeedbackClassName?: string;
  isPasswordInput?: boolean;
  dataTestId?: string;
}

interface Length {
  minLength?: number;
  maxLength?: number;
}

interface Pattern {
  pattern?: string;
}

interface Ref {
  ref?: MutableRefObject<null | HTMLInputElement>;
}

const PasswordInput = ({
  required = true,
  invalidFeedbackClassName = "",
  isPasswordInput,
  ...props
}: PasswordInputProps) => {
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const {
    password,
    handlePassword,
    placeholder,
    label,
    invalidFeedback,
    id,
    dataTestId,
  } = props;
  const passwordRef = useRef(null);

  let length: Length = {};

  if (props?.minLength) length.minLength = props.minLength;
  if (props?.maxLength) length.maxLength = props.maxLength;

  let pattern: Pattern = {};

  if (props?.pattern && !isPasswordInput) pattern.pattern = props?.pattern;

  let ref: Ref = {};

  if (isPasswordInput) ref.ref = passwordRef;

  const handlePasswordVisibility = () =>
    setIsPasswordVisible(!isPasswordVisible);

  useEffect(() => {
    if (passwordRef?.current && isPasswordInput) {
      const passwordInput = passwordRef.current as HTMLInputElement;
      const valid = isTestPasswordValid(password);
      passwordInput.setCustomValidity(valid ? "" : "not valid");
    }
  }, [passwordRef, password, isPasswordInput]);

  return (
    <>
      <input
        type={isPasswordVisible ? "text" : "password"}
        className="form-control form-password"
        id={id}
        placeholder={placeholder}
        value={password}
        onChange={handlePassword}
        required={required}
        ref={passwordRef}
        data-testid={dataTestId}
        {...length}
        {...pattern}
        {...ref}
      />
      <label htmlFor={id}>{label}</label>
      <span
        onClick={handlePasswordVisibility}
        className="text-typography-surface-medium-emphasis position-absolute top-0 end-0 p-3 pe-12px"
        aria-label={t("accessibility.toggle_password")}
        role="button"
      >
        {isPasswordVisible ? (
          <>
            <HiddenSVG />
            <span className="visually-hidden">
              {t("accessibility.hide_password")}
            </span>
          </>
        ) : (
          <>
            <VisibleSVG />
            <span className="visually-hidden">
              {t("accessibility.show_password")}
            </span>
          </>
        )}
      </span>
      {invalidFeedback && (
        <div className={`invalid-feedback ${invalidFeedbackClassName}`}>
          {invalidFeedback}
        </div>
      )}
    </>
  );
};

export default PasswordInput;
