import { useEffect, useState } from "react";
import { PASSWORD_MIN_LENGTH, SPECIAL_CHARACTERS } from "../helpers/constants";

const usePasswordValidation = (password: string) => {
  const [hasLength, setHasLength] = useState(false);
  const [hasLowercase, setHasLowercase] = useState(false);
  const [hasUpperCase, setHasUpperCase] = useState(false);
  const [hasNumber, setHasNumber] = useState(false);
  const [hasSpecialChar, setHasSpecialChar] = useState(false);

  useEffect(() => {
    const specialChars = new RegExp(`(.*?[${SPECIAL_CHARACTERS}])`).test(
      password
    );
    setHasLength(password.length >= PASSWORD_MIN_LENGTH);
    setHasLowercase(/([a-z])/.test(password));
    setHasUpperCase(/([A-Z])/.test(password));
    setHasNumber(/([0-9])/.test(password));
    setHasSpecialChar(specialChars);
  }, [password]);

  const hasAtLeast3Conditions =
    +hasLowercase + +hasUpperCase + +hasNumber + +hasSpecialChar >= 3;

  const hasAllConditions =
    hasLength && hasLowercase && hasUpperCase && hasNumber && hasSpecialChar;

  return {
    hasLength,
    hasLowercase,
    hasUpperCase,
    hasNumber,
    hasSpecialChar,
    hasAllConditions,
    hasAtLeast3Conditions,
  };
};

export default usePasswordValidation;
