import React, { ReactNode, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { generateId } from "../../../helpers/utils";
import { useSSOValues } from "../../../hooks";
import { useDashboardVersion } from "../../../hooks";
import { AppDispatch, RootState } from "../../../stores";
import {
  displayFirstTimeBanner,
  hideSSOBanner,
  StepType,
} from "../../../stores/sso-user-slice";
import BenefitsBox from "./benefits-box";
import BannerContainer from "./banner-container";
import PasswordBox from "./password-box";
import mixpanel from "mixpanel-browser";
import { MIXPANEL_EVENTS } from "analytics";
import { getSubservicerName } from "../../../helpers/subservicer";
import TermsAndConditionsModal from "../terms-and-conditions-modal";
import { FEATURE_GATES, useFeatureGate } from "../../../hooks/use-feature-gate";
import usePopupRules from "../../../hooks/use-popup-rules";

const initialPasswordFormId = generateId();

export const stepBoxContent = (
  stepType: StepType,
  key: string,
  handleDismiss: () => void,
  openTermsModal: (password: string) => void,
  isLakeviewV2Enabled: boolean
) => {
  const content: Record<StepType, ReactNode> = {
    introduction: <BenefitsBox />,
    password_setup: (
      <PasswordBox
        key={key}
        onDismiss={handleDismiss!}
        openTermsModal={openTermsModal}
        isLakeviewV2Enabled={isLakeviewV2Enabled}
      />
    ),
  };

  return content[stepType];
};

const SSOBanner = () => {
  const dispatch = useDispatch<AppDispatch>();

  const { confirmedPassword, openTermsModal } = useSSOValues();

  const {
    ssoUser: { showSSOBanner, isFirstTimeBanner, currentStep },
    user: { user: userData },
  } = useSelector((state: RootState) => state);

  const { mixpanelPageName } = useDashboardVersion();

  const subservicerName = useMemo(
    () => getSubservicerName(userData),
    [userData]
  );

  const [passwordFormKey, setPasswordFormKey] = useState(initialPasswordFormId);
  const [hasExplicitlyDismissed, setHasExplicitlyDismissed] =
    useState<boolean>(false);

  const isLakeviewV2Enabled = useFeatureGate(FEATURE_GATES.ENABLE_LAKEVIEW_2_0);
  const { shouldShowSSOBanner } = usePopupRules();

  /**
   * Track when the banner is displayed
   */
  useEffect(() => {
    if (showSSOBanner && subservicerName) {
      const step = currentStep === "introduction" ? 1 : 2;
      // Track event
      mixpanel.track(MIXPANEL_EVENTS.MODULE_SERVED, {
        module: "subservice-sso-banner",
        step,
        subservicerName,
      });
    }
  }, [showSSOBanner, currentStep, subservicerName]);

  /**
   * Banner behavior when user navigates
   * Only show the banner if
   * 1. User is on home page
   * 2. Is first time SSO user
   * 3. Has not explicitly dismissed the banner
   */
  useEffect(() => {
    if (hasExplicitlyDismissed) {
      return;
    }
    const isCurrentlyShowing = showSSOBanner;
    if (shouldShowSSOBanner !== isCurrentlyShowing) {
      dispatch(
        shouldShowSSOBanner ? displayFirstTimeBanner() : hideSSOBanner()
      );
    }
  }, [dispatch, hasExplicitlyDismissed, shouldShowSSOBanner, showSSOBanner]);

  const handleDismissBanner = () => {
    let firstDismiss = false;

    setHasExplicitlyDismissed(true);
    dispatch(hideSSOBanner());

    // We regenerate the key in the password form
    // to reset the component and erase its state
    // when the banner is dismissed
    setPasswordFormKey(generateId());

    mixpanel.track(MIXPANEL_EVENTS.CLICK, {
      module: "subservice-sso-banner",
      step: 2,
      button: "maybe-later",
      page: mixpanelPageName,
      firstDismiss,
    });
  };

  const boxContent = stepBoxContent(
    currentStep,
    passwordFormKey,
    handleDismissBanner,
    openTermsModal,
    isLakeviewV2Enabled
  );

  return (
    <>
      <TermsAndConditionsModal
        password={confirmedPassword}
        isUncompletedProfile
        viewSource="subservice-sso-banner"
      />
      <BannerContainer
        show={showSSOBanner}
        showSteps={isFirstTimeBanner}
        step={currentStep}
        boxContent={boxContent}
        isLakeviewV2Enabled={isLakeviewV2Enabled}
      />
    </>
  );
};

export default SSOBanner;
