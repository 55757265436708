import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { MIXPANEL_EVENTS } from "analytics";
import mixpanel from "mixpanel-browser";

import { ABOUT_LAKEVIEW_VIDEO_MODAL } from "../helpers/constants";
import { closeModal } from "./modal-slice";
import { RootState } from ".";

const initialState = {
  timeWatched: 0,
  watchedFullVideo: false,
  showVideoPopup: true,
};

export const handleCloseModal = createAsyncThunk(
  "lakeviewVideo/handleCloseModal",
  (
    { from, pathname }: { from: string; pathname: string },
    { getState, dispatch }
  ) => {
    const {
      lakeviewVideoState: { timeWatched, watchedFullVideo },
    } = getState() as RootState;

    mixpanel.track(MIXPANEL_EVENTS.CLICK, {
      module: ABOUT_LAKEVIEW_VIDEO_MODAL,
      button: from,
      path: pathname,
      time_watched: timeWatched,
      watched_full_video: watchedFullVideo,
    });

    dispatch(closeModal());
  }
);

export const lakeviewVideoSlice = createSlice({
  name: "lakeviewVideoSlice",
  initialState,
  reducers: {
    setElapsedTime(state, action) {
      Object.assign(state, action.payload);
    },
    setWatchedFullVideo(state, action) {
      Object.assign(state, action.payload);
    },
    clearVideoSlice(state) {
      Object.assign(state, initialState);
    },
    setShowVideoPopup(state, action) {
      Object.assign(state, action.payload);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(handleCloseModal.fulfilled, (state) => {
      Object.assign(state, initialState);
    });
  },
});

export const {
  setElapsedTime,
  setWatchedFullVideo,
  clearVideoSlice,
  setShowVideoPopup,
} = lakeviewVideoSlice.actions;
