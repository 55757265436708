import ArbitrationAgreement from "./arbitration-agreement";
import ConsentToElectronicRecords from "./consent-to-electronic-records";
import ConsentToObtainCreditReport from "./consent-to-obtain-credit-reports";
import ConsentToReceiveTelephoneComms from "./consent-to-receive-telephone-comms";
import PrivacyPolicy from "./privacy-policy";
import TermsOfUse from "./terms-of-use";

import {
  UIAccordion,
  UIAccordionContent,
  UIAccordionItem,
  UIAccordionTrigger,
} from "ui";

const TermAndConditionsDashboardComponentV2 = () => {
  const TermsAndConditions = [
    { title: "A. Terms of Use", component: <TermsOfUse /> },
    { title: "B. Privacy Policy", component: <PrivacyPolicy /> },
    { title: "C. Arbitration Agreement", component: <ArbitrationAgreement /> },
    {
      title: "D. Consent to Electronic Records",
      component: <ConsentToElectronicRecords />,
    },
    {
      title: "E. Consent to Obtain Credit Reports",
      component: <ConsentToObtainCreditReport />,
    },
    {
      title: "F. Consent to Receive Telephone Communications",
      component: <ConsentToReceiveTelephoneComms />,
    },
  ];

  return (
    <UIAccordion
      type="multiple"
      id="termsConditions"
      data-testid="terms-conditions-section-dashboard"
    >
      {TermsAndConditions.map((item, i) => (
        <UIAccordionItem key={i} value={`item-${i + 1}`}>
          <UIAccordionTrigger>{item.title}</UIAccordionTrigger>
          <UIAccordionContent>{item.component}</UIAccordionContent>
        </UIAccordionItem>
      ))}
    </UIAccordion>
  );
};

export default TermAndConditionsDashboardComponentV2;
