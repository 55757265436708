import {
  DynamicConfig,
  PrecomputedEvaluationsInterface,
} from "@statsig/react-bindings";
import { EXPERIMENTS } from "../hooks/use-ab-test";
import reduxStore, { RootState } from "../stores";
import { User } from "../types";

export type ExperimentNameValue =
  (typeof EXPERIMENTS)[keyof typeof EXPERIMENTS];

// Define the types for old groups and new groups
type OldExperimentNameGroup = "Control" | "Test" | "Treatment";
export type NewExperimentNameGroup =
  | "Control1"
  | "Control2"
  | "Treatment1"
  | "Treatment2";
export type NoneExperimentGroup = "None";

// Mapping object to convert old groups to new groups
const groupNameMapping: Record<OldExperimentNameGroup, NewExperimentNameGroup> =
  {
    Control: "Control1",
    Test: "Treatment1",
    Treatment: "Treatment1",
  };
// set of new groups and none group for validation
const NewExperimentNameGroupSet: Set<NewExperimentNameGroup> = new Set([
  "Control1",
  "Control2",
  "Treatment1",
  "Treatment2",
] as NewExperimentNameGroup[]);
const NoneExperimentGroupSet: Set<NoneExperimentGroup> =
  new Set<NoneExperimentGroup>(["None"]);

// type guard to check if a value is an OldGroup
const isOldExperimentNameGroup = (
  group: string
): group is OldExperimentNameGroup => {
  return group in groupNameMapping;
};
// type guard to check if a value is a NewExperimentNameGroup
const isNewExperimentNameGroup = (
  group: string
): group is NewExperimentNameGroup => {
  return NewExperimentNameGroupSet.has(group as NewExperimentNameGroup);
};

// type guard to check if a value is a NoneExperimentGroup
const isNoneGroup = (group: string): group is NoneExperimentGroup => {
  return NoneExperimentGroupSet.has(group as NoneExperimentGroup);
};

// function to map old groups and new groups
export const mapExperimentNameGroup = (
  group: string
): NewExperimentNameGroup | NoneExperimentGroup => {
  // if the group is an old group, map it using the groupNameMapping object
  if (isOldExperimentNameGroup(group)) {
    return groupNameMapping[group];
  }
  // if the group is already a new group, return it as is
  if (isNewExperimentNameGroup(group) || isNoneGroup(group)) {
    return group;
  }
  // throw an error if the group is unknown
  throw new Error(`Unknown group: ${group}`);
};

export const getUserId = (userData: User) => {
  const userID = userData?.id ?? "";
  const unAuthSessionID = localStorage.getItem("session_id") ?? "";
  return { id: userID || unAuthSessionID, isAuth: !!userID };
};

export const getSessionKey = (
  id: string,
  isAuth: boolean,
  experimentName: ExperimentNameValue
): string => {
  const AUTH_EXPERIMENT_EXPOSURE_VALUE = "_stsg_exp_";
  const UNAUTH_EXPERIMENT_EXPOSURE_VALUE = "_unauth_stsg_exp_";
  return `${id}${
    isAuth ? AUTH_EXPERIMENT_EXPOSURE_VALUE : UNAUTH_EXPERIMENT_EXPOSURE_VALUE
  }${experimentName}`;
};

export const logExperimentExposure = (
  experimentName: ExperimentNameValue,
  sessionKey: string,
  clientConfig: PrecomputedEvaluationsInterface
) => {
  const config: DynamicConfig = clientConfig.getExperiment(experimentName, {
    disableExposureLog: true,
  });
  if (!config.__evaluation?.is_experiment_active) return; // Exit if the experiment is shipped
  const isSessionStorageSet = sessionStorage.getItem(experimentName);
  if (isSessionStorageSet) return; // Exit if the exposure was already done in this session

  sessionStorage.setItem(sessionKey, "true");
  clientConfig.getExperiment(experimentName); // Log experiment exposure
};

export const experimentManualExposure = (
  experimentName: ExperimentNameValue,
  experimentGroupName: NewExperimentNameGroup | NoneExperimentGroup,
  selectedGroupsToMakeExposure: (
    | NewExperimentNameGroup
    | NoneExperimentGroup
  )[],
  experimentConditionToMakeExposure: boolean = experimentGroupName !== "None",
  config: PrecomputedEvaluationsInterface
) => {
  if (
    !experimentConditionToMakeExposure ||
    !selectedGroupsToMakeExposure.includes(experimentGroupName)
  )
    return;
  const state = reduxStore.getState() as RootState;
  const { user: userData } = state.user;
  const { id, isAuth } = getUserId(userData as User);
  const sessionKey = getSessionKey(id, isAuth, experimentName);
  logExperimentExposure(experimentName, sessionKey, config);
};
