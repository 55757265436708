import { useEffect } from "react";
import {
  LAKEVIEW_VIDEO_POPUP_MIXPANEL_MODULE,
  NOTIFICATION_TYPES,
} from "../../../helpers/constants";
import LakeviewVideoPopupContainer from "./lakeview-video-popup-container";
import mixpanel from "mixpanel-browser";
import { MIXPANEL_EVENTS } from "analytics";
import { pages } from "../../../helpers/pages";
import usePopupRules from "../../../hooks/use-popup-rules";
import { dispatch } from "../../../stores";
import { updateNotifications } from "../../../stores/notifications-slice";

const LakeviewVideoPopup = () => {
  const { shouldShowVideoPopup } = usePopupRules();

  useEffect(() => {
    if (shouldShowVideoPopup) {
      mixpanel.track(MIXPANEL_EVENTS.MODULE_SERVED, {
        module: LAKEVIEW_VIDEO_POPUP_MIXPANEL_MODULE,
        path: pages.home,
      });

      dispatch(
        updateNotifications({
          notification_type: NOTIFICATION_TYPES.VIDEO_LAKEVIEW_INTRO_POPUP,
          metadata: {},
        })
      );
    }
  }, [shouldShowVideoPopup]);

  return shouldShowVideoPopup && <LakeviewVideoPopupContainer />;
};

export default LakeviewVideoPopup;
