import { useRef } from "react";
import { OnProgressProps } from "react-player/base";
import { useLocation } from "react-router-dom";
import { MIXPANEL_EVENTS } from "analytics";
import mixpanel from "mixpanel-browser";
import ReactPlayer from "react-player";

import { roundToNDecimals } from "../../../helpers/utils";
import { dispatch } from "../../../stores";
import {
  ABOUT_LAKEVIEW_VIDEO_MODAL,
  LAKEVIEW_VIDEO_URL,
} from "../../../helpers/constants";
import {
  setElapsedTime,
  setWatchedFullVideo,
} from "../../../stores/lakeview-video-slice";

interface Props {
  module?: string;
}

const LakeviewVideoPlayer = ({
  module = ABOUT_LAKEVIEW_VIDEO_MODAL,
}: Props) => {
  const playerRef = useRef<ReactPlayer | null>(null);
  const { pathname } = useLocation();

  const getWatchedTime = () =>
    roundToNDecimals(playerRef.current?.getCurrentTime() || 0, 2);

  const handleVideoInteraction = (interaction: string) => {
    const timeWatched = getWatchedTime();

    mixpanel.track(MIXPANEL_EVENTS.CLICK, {
      module,
      button: interaction,
      path: pathname,
      time_watched: timeWatched,
    });
  };

  const handleOnProgress = (e: OnProgressProps) =>
    dispatch(
      setElapsedTime({ timeWatched: roundToNDecimals(e.playedSeconds, 2) })
    );

  const handleOnEnded = () => {
    dispatch(setWatchedFullVideo({ watchedFullVideo: true }));
  };

  return (
    <ReactPlayer
      ref={playerRef}
      url={LAKEVIEW_VIDEO_URL}
      width="100%"
      height="100%"
      controls
      playing
      onPlay={() => handleVideoInteraction("play")}
      onPause={() => handleVideoInteraction("pause")}
      onProgress={handleOnProgress}
      onEnded={handleOnEnded}
      config={{ youtube: { playerVars: { rel: 0 } } }}
    />
  );
};

export default LakeviewVideoPlayer;
