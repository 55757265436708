import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import dashApi from "../helpers/dash-api";
import { Notification, AsyncStatus } from "../types";

// - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -|
// Notifications Slice (Endpoints)
// - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -|
export const getNotifications = dashApi
  .path("/notifications")
  .method("get")
  .create();

export const postNotifications = dashApi
  .path("/notifications")
  .method("post")
  .create();

// - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -|
// Notifications Slice (State)
// - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -|
export interface NotificationsState {
  notifications: Array<Notification> | undefined;
  status: AsyncStatus;
  initialized: boolean;
}

const initialNotificationsState: NotificationsState = {
  notifications: undefined,
  status: "idle",
  initialized: false,
};

// - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -|
// Get Notifications
// - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -|
export const loadNotifications = createAsyncThunk(
  "notifications/getNotifications",
  async function () {
    try {
      const response = await getNotifications({});
      return response.data;
    } catch (err) {
      if (err.status === 404) {
        return undefined;
      }
      throw new Error(err);
    }
  }
);

// - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -|
// Update Notifications
// - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -|
export const updateNotifications = createAsyncThunk(
  "notifications/updateNotification",
  async function (props: Notification) {
    const response = await postNotifications(props);
    return response.data;
  }
);

export const notificationsSlice = createSlice({
  name: "notifications",
  initialState: initialNotificationsState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(loadNotifications.pending, (state) => {
        if (!state.initialized) {
          state.status = "loading";
        }
      })
      .addCase(loadNotifications.fulfilled, (state, action) => {
        state.status = "loaded";
        state.initialized = true;
        state.notifications = action.payload;
      })
      .addCase(loadNotifications.rejected, (state) => {
        if (!state.initialized) {
          state.status = "failed";
          state.notifications = undefined;
        }
      })

      .addCase(updateNotifications.pending, (state) => {
        state.status = "loading";
      })
      .addCase(updateNotifications.fulfilled, (state) => {
        state.status = "loaded";
      })
      .addCase(updateNotifications.rejected, (state) => {
        state.status = "failed";
      });
  },
});
