import { ElementType, PropsWithChildren, ReactElement } from "react";
import { Trans } from "react-i18next";
import { cx } from "class-variance-authority";

import { Account } from "../../../types";
import { MortgageUpdatedInfo } from "./mortgage-updated-info";
import { formatCurrency } from "../../../helpers/currencyFormatter";

interface MortgageCardLayoutProps {
  account: Account;
  Icon: ElementType<{
    className?: string;
  }>;
  Buttons: ReactElement;
  isSingleCard: boolean;
}

export default function MortgageCardLayout({
  account,
  Icon,
  Buttons,
  isSingleCard,
  children,
}: PropsWithChildren<MortgageCardLayoutProps>) {
  return (
    <div className="bg-primary-01 p-4 h-100 d-flex flex-column ">
      <div
        data-name="card-header-body"
        className="d-flex flex-column h-100 gap-3"
      >
        <div
          data-name="card-header"
          className="typeface-body-small text-uppercase fw-normal text-indigo-2"
        >
          <Trans
            i18nKey="home_value.mortgage.original_loan_amount"
            values={{
              originalPrincipal: formatCurrency(account?.original_principal),
            }}
          />
        </div>
        <div
          data-name="card-body"
          className={cx(
            isSingleCard && "flex-lg-row",
            "d-flex flex-column justify-content-between h-100 gap-4"
          )}
        >
          <div data-name="main-content" className="d-flex flex-row gap-4">
            <div>
              <Icon />
            </div>
            {children}
          </div>
          <div
            data-name="buttons"
            className={cx(
              isSingleCard && "flex-lg-row align-items-lg-center",
              "h-100 d-flex flex-column justify-content-end gap-12px"
            )}
          >
            {Buttons}
          </div>
        </div>
      </div>
      <div data-name="card-footer">
        <hr className="mb-3 mt-5" />
        <p className="m-0 typeface-body-small">
          <MortgageUpdatedInfo account={account} shouldRenderSubservicerLink />
        </p>
      </div>
    </div>
  );
}
