import { Navigate, Outlet, useLocation } from "react-router-dom";
import { cashOutPages, pages } from "../../helpers/pages";
import Footer from "./footer";
import FooterV2 from "./footer-v2";
import Toasts from "./toasts";
import Banners from "./banners";
import { useSelector } from "react-redux";
import { dispatch, RootState } from "../../stores";
import SSOBanner from "../../atomic/organisms/sso-banner";
import ContentBlockOverlay from "./content-block-overlay";
import { CASH_OUT_SESSION_STORAGE_KEYS } from "../../helpers/constants";
import { getCashOutFullPath, shouldShowProfilePage } from "../../helpers/utils";
import GenericBaseModal from "../../atomic/organisms/modal/generic-base-modal";
import Header from "./header";
import HeaderV2 from "./header/header-v2";
import InitialTermsConditions from "./initial-terms-conditions";
import { cn } from "ui";
import { FEATURE_GATES, useFeatureGate } from "../../hooks/use-feature-gate";
import LakeviewVideoPopup from "../../atomic/organisms/lakeview-video-popup";
import { useSSOValues } from "../../hooks";
import { useEffect } from "react";
import { setShowVideoPopup } from "../../stores/lakeview-video-slice";

type Props = {
  className?: string;
  displayAuthInfo: boolean;
};

const Layout = ({ className = "", displayAuthInfo }: Props) => {
  const {
    app: { isPDPStickyBanner },
    user: { user },
    modalState,
  } = useSelector((state: RootState) => state);
  const { isPendingFastlaneAccount } = useSSOValues();
  const { pathname } = useLocation();
  const { showVideoPopup } = useSelector(
    (state: RootState) => state.lakeviewVideoState
  );

  const isLakeviewV2Enabled = useFeatureGate(FEATURE_GATES.ENABLE_LAKEVIEW_2_0);

  // Clean the video banner store if it is not the home page and the banner is showing
  useEffect(() => {
    const isHomePage = pathname === pages.home;
    if (!isHomePage && showVideoPopup) {
      dispatch(setShowVideoPopup({ showVideoPopup: false }));
    }
  }, [pathname, showVideoPopup]);

  /** if started the cash-out flow process and signed in, redirect back to the cash-out flow */
  if (
    sessionStorage.getItem(CASH_OUT_SESSION_STORAGE_KEYS.SIGN_IN) === "true"
  ) {
    return <Navigate to={getCashOutFullPath(cashOutPages.steps.signIn)} />;
  }

  return (
    <main
      className={cn(
        className,
        "tw-flex-grow tw-flex min-h-screen tw-flex-col",
        isPDPStickyBanner && "tw-pb-40 md:tw-pb-[89px]"
      )}
    >
      {isLakeviewV2Enabled ? (
        <HeaderV2
          displayAuthInfo={displayAuthInfo}
          showProfileLink={shouldShowProfilePage(user)}
        />
      ) : (
        <Header
          displayAuthInfo={displayAuthInfo}
          showProfileLink={shouldShowProfilePage(user)}
        />
      )}
      <InitialTermsConditions />
      <Banners />
      {isPendingFastlaneAccount && <SSOBanner />}
      <LakeviewVideoPopup />
      <div className="tw-flex-grow tw-bg-white tw-relative tw-flex tw-flex-col tw-justify-center tw-align-center">
        <ContentBlockOverlay className="tw-h-full">
          <Outlet />
        </ContentBlockOverlay>
      </div>
      {isLakeviewV2Enabled ? <FooterV2 /> : <Footer />}
      <Toasts />
      <GenericBaseModal {...modalState} />
    </main>
  );
};

export default Layout;
