import { Trans } from "react-i18next";

import {
  PERSONALIZED_OFFER_DEBT_HELOAN_CONSOLIDATE_POPUP,
  PERSONALIZED_OFFER_DEBT_HELOAN_CONSOLIDATE_POPUP_ESTIMATED_AMOUNT,
  PERSONALIZED_OFFER_DEBT_HELOAN_CONSOLIDATE_POPUP_LOWN_AMOUNT,
} from "../../../../helpers/constants";
import { translate as t } from "../../../../helpers/i18n";

import DebtHeloanConsolidateDisclaimerCta from "./debt-heloan-consolidate-offer-disclaimer-cta";
import { BackLink } from "../../../layout/disclosures/disclosure-link";
import { ExternalLink } from "../../../../components/external-link";
import NMLSFooterV2 from "../../../../modules/layout/footer-v2/nmls-footer-v2";

const DISCLOSURE_24 = PERSONALIZED_OFFER_DEBT_HELOAN_CONSOLIDATE_POPUP;
const DISCLOSURE_25 =
  PERSONALIZED_OFFER_DEBT_HELOAN_CONSOLIDATE_POPUP_ESTIMATED_AMOUNT;
const DISCLOSURE_26 =
  PERSONALIZED_OFFER_DEBT_HELOAN_CONSOLIDATE_POPUP_LOWN_AMOUNT;

const DebtHeloanConsolidateOfferPopupDisclaimer = ({
  apr,
  sourceDate,
}: {
  apr: string;
  sourceDate: string;
}) => {
  return (
    <section className="tw-flex tw-flex-col tw-gap-8 tw-mb-8">
      <div className="tw-flex tw-flex-col tw-gap-2">
        <span>
          <Trans
            i18nKey={"layout.footer.disclosures.personalized_offers.item_24"}
            components={{
              backLink: <BackLink disclosureType={DISCLOSURE_24} />,
              link1: <DebtHeloanConsolidateDisclaimerCta />,
            }}
            values={{ apr }}
          />
        </span>
        <span>
          <Trans
            i18nKey={"layout.footer.disclosures.personalized_offers.item_25"}
            components={{
              backLink: <BackLink disclosureType={DISCLOSURE_25} />,
            }}
            values={{ source_date: sourceDate }}
          />
        </span>
        <span>
          <Trans
            i18nKey={"layout.footer.disclosures.personalized_offers.item_26"}
            components={{
              backLink: <BackLink disclosureType={DISCLOSURE_26} />,
            }}
          />
        </span>
      </div>
      <div className="tw-border tw-border-solid tw-border-border-info tw-rounded tw-p-4 tw-text-regular tw-font-bold tw-text-primary">
        <Trans
          i18nKey={
            "product_offers.personalized.heloan_debt_consolidate_popup.disclaimer_section.opt_out_disclaimer_2"
          }
          components={{
            link1: (
              <ExternalLink
                href="https://www.optoutprescreen.com/"
                targetBlank
              />
            ),
          }}
        />
      </div>
      <div className="tw-text-regular tw-text-primary">
        <p className="tw-font-bold">
          {t(
            "product_offers.personalized.heloan_debt_consolidate_popup.disclaimer_section.terms_&_conditions.title"
          )}
        </p>
        <p>
          {t(
            "product_offers.personalized.heloan_debt_consolidate_popup.disclaimer_section.terms_&_conditions.content"
          )}
        </p>
      </div>
      <div className="tw-flex tw-flex-col tw-mt-2">
        <hr className="tw-text-border-lines tw-opacity-100 tw-mt-0 tw-mb-4" />
        <NMLSFooterV2
          iconFirst
          isContentVerticallyCentered
          contentClassName="!tw-justify-start"
        />
      </div>
    </section>
  );
};

export default DebtHeloanConsolidateOfferPopupDisclaimer;
