import { translate as t } from "../../../helpers/i18n";
import { Trans } from "react-i18next";
import { Link } from "react-router-dom";
import { UICheckboxGroup, UICheckboxItem } from "ui";

export type TermsData = {
  agreement_terms: boolean;
  electronic_signatures: boolean;
  credit_reports: boolean;
  marketing_telephone_communication: boolean;
};

type Props = {
  termsData: TermsData;
  handleChange: (termDataName: string, currentValue: boolean) => void;
  isFormInvalid: boolean;
};

const electronicSignaturesLink =
  "/assets/terms-and-agreement/D_Dashboard_Consent_to_Electronic_Records_Final_September_2024.pdf";
const creditReportsLink =
  "/assets/terms-and-agreement/E_Dashboard_Consent_to_Obtain_Credit_Reports_Final_September_2024.pdf";
const receiveTelephoneCommunicationsLink =
  "/assets/terms-and-agreement/F_Dashboard_TCPA_Consent_Final_September_2024.pdf";

const DashboardTermsCheckboxGroupV2 = ({
  termsData,
  handleChange,
  isFormInvalid,
}: Props) => {
  return (
    <div className="tw-w-full" data-testid="dashboard-terms-checkbox-group">
      <div className="tw-bg-surface-secondary tw-rounded tw-p-2">
        <UICheckboxGroup
          variant="vertical"
          errorMessage={t("sign_up.terms_conditions.validations.terms_message")}
          invalid={isFormInvalid}
        >
          <UICheckboxItem
            checked={termsData.agreement_terms}
            onClick={(newValue) => handleChange("agreement_terms", newValue)}
            label={t("sign_up.terms_conditions.usage")}
            required
          />
          <UICheckboxItem
            checked={termsData.electronic_signatures}
            onClick={(newValue) =>
              handleChange("electronic_signatures", newValue)
            }
            label={
              <Trans
                i18nKey={"sign_up.terms_conditions.electronic_signatures"}
                components={{
                  pdf: (
                    <Link
                      className="tw-text-link-rest hover:tw-text-link-hover active:tw-text-link-pressed"
                      to={electronicSignaturesLink}
                      target="_blank"
                      rel="noreferrer"
                    />
                  ),
                }}
              />
            }
            required
          />
          <UICheckboxItem
            checked={termsData.credit_reports}
            onClick={(newValue) => handleChange("credit_reports", newValue)}
            label={
              <Trans
                i18nKey={"sign_up.terms_conditions.credit_reports"}
                components={{
                  pdf: (
                    <Link
                      className="tw-text-link-rest hover:tw-text-link-hover active:tw-text-link-pressed"
                      to={creditReportsLink}
                      target="_blank"
                      rel="noreferrer"
                    />
                  ),
                }}
              />
            }
            required
          />
        </UICheckboxGroup>
      </div>
      <div className="tw-p-2">
        <UICheckboxItem
          checked={termsData.marketing_telephone_communication}
          onClick={(e) => handleChange("marketing_telephone_communication", e)}
          label={
            <Trans
              i18nKey={
                "sign_up.terms_conditions.receive_telephone_communications"
              }
              components={{
                pdf: (
                  <Link
                    className="tw-text-link-rest hover:tw-text-link-hover active:tw-text-link-pressed"
                    to={receiveTelephoneCommunicationsLink}
                    target="_blank"
                    rel="noreferrer"
                  />
                ),
              }}
            />
          }
        />
      </div>
    </div>
  );
};

export default DashboardTermsCheckboxGroupV2;
