import { useDispatch, useSelector } from "react-redux";
import { Trans } from "react-i18next";
import { AppDispatch, RootState } from "../../../stores";
import { useDashboardVersion, useMortgageValues } from "../../../hooks";
import { translate as t } from "../../../helpers/i18n";
import { Account } from "../../../types";
import MortgageCardLayout from "./mortgage-card-layout";
import { ManageSubservicerButton, PayNowButton } from "./cta-buttons";
import { ReactComponent as NoBalanceDueSVG } from "../../../images/mortgage/no-balance-due.svg";
import { ReactComponent as LimitedInfoSVG } from "../../../images/mortgage/limited-info.svg";
import { ReactComponent as MonthlyPaymentSVG } from "../../../images/mortgage/monthly-payment.svg";

import { SubservicerLink } from "../../../components/subservicer-link";
import {
  getSubservicerName,
  isMrCooper,
  REDIRECT_TARGET,
} from "../../../helpers/subservicer";
import {
  formatCurrency,
  mortgageValueCurrencyFormat,
} from "../../../helpers/currencyFormatter";
import { formatDate } from "../../../helpers/dateFormatter";
import { useEffect } from "react";
import { updateUIState } from "../../../stores/app-slice";
import mixpanel from "mixpanel-browser";
import { MIXPANEL_EVENTS } from "analytics";
import { cx } from "class-variance-authority";
import FloatingCTABannerSection from "../../../atomic/organisms/sso-banner/floating-cta-banner-section";

interface Props {
  account: Account;
  onOpenCanvas: () => void;
  isSingleCard: boolean;
}

export const FLOATING_BUTTON_MODULE_NAME = "floating-pay-my-mortgage";

const MortgageCard = ({ account, onOpenCanvas, isSingleCard }: Props) => {
  const dispatch = useDispatch<AppDispatch>();
  const { hasPrincipalBalance, isCurrent, isPaidOff } = useMortgageValues(
    account.id
  );
  const { user: userState } = useSelector((state: RootState) => state);
  const { isFloatingButton } = useSelector((state: RootState) => state.app);
  const { isContentBlockOverlay } = useSelector(
    (state: RootState) => state.app
  );
  const { showSSOBanner, currentStep } = useSelector(
    (state: RootState) => state.ssoUser
  );
  const subservicerName = getSubservicerName(userState.user);
  const nextPayment = account?.next_payment;
  const dueAmount = formatCurrency(
    nextPayment?.due_amount,
    undefined,
    mortgageValueCurrencyFormat
  );
  const dueDate = formatDate(nextPayment?.due_date);
  const isMrCooperSubservicer = isMrCooper(userState.user);
  const { mixpanelPageName } = useDashboardVersion();
  const isFloatingButtonVisibleWithOverlayAndSSO =
    !isContentBlockOverlay || showSSOBanner;

  useEffect(() => {
    dispatch(
      updateUIState({
        isFloatingButton:
          hasPrincipalBalance &&
          !isPaidOff &&
          isCurrent &&
          isFloatingButtonVisibleWithOverlayAndSSO,
      })
    );
  }, [
    dispatch,
    hasPrincipalBalance,
    isCurrent,
    isPaidOff,
    isFloatingButtonVisibleWithOverlayAndSSO,
  ]);

  useEffect(() => {
    if (
      isFloatingButton &&
      mixpanelPageName &&
      account?.subservicer_loan_number
    ) {
      mixpanel.track(MIXPANEL_EVENTS.MODULE_SERVED, {
        module: FLOATING_BUTTON_MODULE_NAME,
        subservicer_loan_number: account.subservicer_loan_number,
        page: mixpanelPageName,
        path: location.pathname,
      });
    }
  }, [account.subservicer_loan_number, isFloatingButton, mixpanelPageName]);

  if (!hasPrincipalBalance || isPaidOff) {
    return (
      <MortgageCardLayout
        account={account}
        Icon={NoBalanceDueSVG}
        Buttons={
          <ManageSubservicerButton
            subservicerLoanNumber={account?.subservicer_loan_number}
            eventParams={{
              page: mixpanelPageName,
            }}
          />
        }
        isSingleCard={isSingleCard}
      >
        <div>
          <h5 className="typeface-h3 tw-text-green-700">
            {t("home_value.mortgage.no_balance_due_heading")}
          </h5>
          <p className="w-100 m-0">
            <Trans
              i18nKey="home_value.mortgage.no_balance_due_caption"
              values={{
                subservicerName,
              }}
              shouldUnescape
            />
          </p>
        </div>
      </MortgageCardLayout>
    );
  }

  if (isCurrent) {
    return (
      <>
        <MortgageCardLayout
          account={account}
          Icon={MonthlyPaymentSVG}
          Buttons={
            <>
              <PayNowButton
                subservicerLoanNumber={account?.subservicer_loan_number}
                eventParams={{
                  page: mixpanelPageName,
                }}
              />
              <ManageSubservicerButton
                subservicerLoanNumber={account?.subservicer_loan_number}
                eventParams={{
                  page: mixpanelPageName,
                }}
              />
            </>
          }
          isSingleCard={isSingleCard}
        >
          <div className="d-flex flex-column">
            <p className="mb-2 typeface-body-large tw-text-green-700 fw-normal">
              {dueAmount}
            </p>
            <p
              role="button"
              className="btn-link fw-medium text-primary typeface-body-small m-0"
              onClick={onOpenCanvas}
            >
              {t("home_value.mortgage.view_details")}
            </p>
            <p className="m-0 typeface-body-small">
              <Trans
                i18nKey="home_value.mortgage.payment_due"
                values={{
                  dueDate,
                }}
                shouldUnescape
              />
            </p>
          </div>
        </MortgageCardLayout>
        {isFloatingButton && (
          <div
            className={cx("mortgage-card__floating-button", {
              "!tw-hidden": showSSOBanner,
              "md:!tw-block": showSSOBanner,
            })}
          >
            <SubservicerLink
              isLinkTag={false}
              translationKey="home_value.mortgage.pay_my_mortgage"
              module={FLOATING_BUTTON_MODULE_NAME}
              subservicerLoanNumber={account?.subservicer_loan_number}
              redirectTarget={REDIRECT_TARGET.PAYMENT}
              hasIcon
              isButton
              eventParams={{
                button: "pay-my-mortgage",
                subservicer_loan_number: account?.subservicer_loan_number,
                page: mixpanelPageName,
                path: location.pathname,
              }}
              buttonProps={{
                variant: "floating",
                size: "lg",
                fullWidth: "mobile",
              }}
            />
          </div>
        )}
        {/* 
        This conditional component is exclusively for handling the "Pay my mortgage"
        button on mobile through the SSO banner
        */}
        {showSSOBanner && (
          <FloatingCTABannerSection step={currentStep} account={account} />
        )}
      </>
    );
  }

  return (
    <MortgageCardLayout
      account={account}
      Icon={LimitedInfoSVG}
      Buttons={
        <ManageSubservicerButton
          subservicerLoanNumber={account?.subservicer_loan_number}
          eventParams={{
            page: mixpanelPageName,
          }}
        />
      }
      isSingleCard={isSingleCard}
    >
      <div>
        <h5 className="typeface-h4">
          {t("home_value.mortgage.limited_info_heading")}
        </h5>
        <div className="w-100 typeface-body-small">
          <p className="m-0">
            {t("home_value.mortgage.visit_subservicer_caption", {
              subservicerName,
              interpolation: { escapeValue: false },
            })}
          </p>
          <SubservicerLink
            isMortgageAssistance={!isMrCooperSubservicer}
            translationKey="home_value.mortgage.mortgage_assistance"
            module="manage-your-mortgage"
            subservicerLoanNumber={account?.subservicer_loan_number}
            redirectTarget={REDIRECT_TARGET.MORTGAGE_ASSISTANCE}
            eventParams={{
              page: mixpanelPageName,
            }}
          />
        </div>
      </div>
    </MortgageCardLayout>
  );
};

export default MortgageCard;
