import { Button } from "../../atoms/button";
import { buildPayMortgageModal } from "../../../components/modals";
import { getSubservicerLink } from "../../../stores/subservicer-slice";
import {
  getSubservicerName,
  isMrCooper,
  REDIRECT_TARGET,
} from "../../../helpers/subservicer";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../stores";
import mixpanel from "mixpanel-browser";
import { MIXPANEL_EVENTS } from "analytics";
import { pages } from "../../../helpers/pages";
import { LakeviewEventParams } from "../../../components/confirm-leave-modal";
import { UIButtonProps, UIIcon } from "ui";

interface PayMortgageButtonProps extends UIButtonProps {
  text: string;
  eventParams?: LakeviewEventParams;
  fullWidth?: string;
}

const PayMortgageButton = ({
  text,
  eventParams,
  fullWidth = "full",
  ...props
}: PayMortgageButtonProps) => {
  const dispatch = useDispatch<AppDispatch>();
  const { user: userState } = useSelector((state: RootState) => state);

  // Subservicer information
  const subservicerName = getSubservicerName(userState.user);
  const isMrCooperSubservicer = isMrCooper(userState.user);

  const getSubservicerInfo = () => {
    const params = isMrCooperSubservicer
      ? { redirect_target: REDIRECT_TARGET.PAYMENT }
      : {};
    dispatch(getSubservicerLink(params));
  };

  const handleButtonClick = () => {
    const offerType = eventParams?.offer_type
      ? { offer_type: eventParams?.offer_type }
      : {};
    const module = eventParams?.module ? { module: eventParams?.module } : {};

    mixpanel.track(MIXPANEL_EVENTS.CLICK, {
      path: pages.home,
      button: "pay-my-mortgage",
      subservicer_name: subservicerName,
      ...offerType,
      ...module,
    });

    getSubservicerInfo();
    buildPayMortgageModal({
      subservicerName: subservicerName,
      isLoading: true,
      eventParams,
    });
  };

  return (
    <Button
      variant="secondary"
      fullWidth={fullWidth}
      size="lg"
      onClick={handleButtonClick}
      {...props}
    >
      <UIIcon icon="externalLink" />
      {text}
    </Button>
  );
};

export default PayMortgageButton;
