import { ReactNode, useEffect, useMemo } from "react";
import {
  StatsigProvider,
  StatsigUser,
  useClientAsyncInit,
} from "@statsig/react-bindings";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "./stores";
import Loader from "./components/loader";
import useFetchUserData from "./hooks/use-fetch-user-data";
import useGlobalErrors from "./hooks/use-global-errors";
import { useIsUserAuthenticated } from "./hooks/use-is-user-authenticated";
import { getOrCreateSessionId } from "analytics";
import { loadAccounts } from "./stores/account-slice";
import { useSession } from "./hooks/use-session";

const STATSIG_CLIENT_CONFIG = {
  // setting enviroment
  environment: { tier: process.env.REACT_APP_STATSIG_ENVIROMENT_TIER },
  // in local mode it will not send the metrics
  localMode: !!process.env.REACT_APP_STATSIG_LOCAL_MODE,
};

interface Props {
  children: ReactNode;
}

const AppInitializer = ({ children }: Props) => {
  // Global fetch of user data
  useFetchUserData();

  // Track global errors.
  useGlobalErrors();

  // Initialize session handling
  useSession();

  const isUserAuthenticated = useIsUserAuthenticated();
  const dispatch = useDispatch<AppDispatch>();

  const {
    user: { user: userData, status },
    account: { status: accountStatus },
  } = useSelector((state: RootState) => state);

  const statsigUser: StatsigUser = useMemo(() => {
    const unAuthSessionID = getOrCreateSessionId();

    return {
      userID: isUserAuthenticated ? userData?.id : unAuthSessionID,
      custom: {
        isUserAuthenticated,
      },
    };
  }, [isUserAuthenticated, userData?.id]);

  const { client, isLoading } = useClientAsyncInit(
    process.env.REACT_APP_STATSIG_CLIENT_API_KEY || "",
    statsigUser,
    STATSIG_CLIENT_CONFIG
  );

  // Load account data only when the user is authenticated and account status is idle
  useEffect(() => {
    if (status === "authenticated" && accountStatus === "idle") {
      dispatch(loadAccounts());
    }
  }, [dispatch, status, accountStatus]);

  // show loader until auth user data an account information are fully loaded
  if (
    status === "loading" ||
    (status === "authenticated" &&
      (accountStatus === "loading" || accountStatus === "idle") &&
      isLoading)
  ) {
    return <Loader />;
  }

  const isUserDetected =
    status === "authenticated" || status === "unauthenticated";

  return (
    <StatsigProvider client={client} loadingComponent={<Loader />}>
      {!isUserDetected ? <Loader /> : children}
    </StatsigProvider>
  );
};

export default AppInitializer;
