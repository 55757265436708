import { useState } from "react";
import { translate as t } from "../../../helpers/i18n";
import { Button } from "../../atoms/button";
import LakeviewVideoThumbnailV2 from "../lakeview-video-v2/lakeview-video-thumbnail-v2";
import { GridContainer, GridCol, UITheme } from "ui";
import LakeviewVideoPlayer from "../lakeview-video/lakeview-video-player";
import { dispatch, RootState } from "../../../stores";
import { setShowVideoPopup } from "../../../stores/lakeview-video-slice";
import {
  EVENT_SOURCES,
  EVENT_TYPES,
  LAKEVIEW_VIDEO_POPUP_MIXPANEL_MODULE,
} from "../../../helpers/constants";
import { updateNotifications } from "../../../stores/notifications-slice";
import mixpanel from "mixpanel-browser";
import { MIXPANEL_EVENTS } from "analytics";
import { pages } from "../../../helpers/pages";
import { useSelector } from "react-redux";

const LakeviewVideoPopupContainer = () => {
  const [showVideoPlayer, setShowVideoPlayer] = useState<boolean>(false);
  const { timeWatched, watchedFullVideo } = useSelector(
    (state: RootState) => state.lakeviewVideoState
  );

  const handleWatchVideo = () => {
    setShowVideoPlayer(true);
    handleNotifyVideo();

    mixpanel.track(MIXPANEL_EVENTS.CLICK, {
      module: LAKEVIEW_VIDEO_POPUP_MIXPANEL_MODULE,
      button: "watch-video-to-learn",
      path: pages.home,
    });
  };

  const handlePlayVideo = () => {
    setShowVideoPlayer(true);
    handleNotifyVideo();

    mixpanel.track(MIXPANEL_EVENTS.CLICK, {
      module: LAKEVIEW_VIDEO_POPUP_MIXPANEL_MODULE,
      button: "play-button",
      path: pages.home,
    });
  };

  const handleDismissVideo = () => {
    dispatch(setShowVideoPopup({ showVideoPopup: false }));

    mixpanel.track(MIXPANEL_EVENTS.CLICK, {
      module: LAKEVIEW_VIDEO_POPUP_MIXPANEL_MODULE,
      button: "dismiss",
      path: pages.home,
      time_watched: timeWatched,
      watched_full_video: watchedFullVideo,
    });
  };

  const handleNotifyVideo = () => {
    dispatch(
      updateNotifications({
        notification_type: EVENT_TYPES.VIDEO_LAKEVIEW_INTRO_PLAYED,
        metadata: {
          source: EVENT_SOURCES.VIDEO_LAKEVIEW_INTRO_BANNER as never,
        },
      })
    );
  };

  return (
    <div
      className="tw-text-white tw-bg-gradient-brand"
      data-testid="video-popup-container"
    >
      <GridContainer>
        <GridCol className="tw-py-10 xl:tw-py-16 xl:tw-px-0">
          <div className="tw-flex tw-flex-col lg:tw-flex-row tw-gap-6">
            <div className="tw-flex tw-flex-col tw-justify-center tw-w-full lg:tw-w-1/2">
              <h3 className="tw-text-h3 tw-mb-0 tw-text-white">
                {t("lakeview_video_popup.title")}
              </h3>
              <div className="tw-hidden lg:tw-flex tw-flex-col lg:tw-flex-row tw-pt-10 tw-gap-4">
                <Button
                  onClick={handleWatchVideo}
                  variant="secondary"
                  theme={UITheme.LAKEVIEW_BLUE}
                  size="lg"
                >
                  {t("lakeview_video_popup.watch_cta")}
                </Button>
                <Button
                  onClick={handleDismissVideo}
                  size="lg"
                  theme={UITheme.LAKEVIEW_BLUE}
                  variant="primary"
                  className="border"
                >
                  {t("lakeview_video_popup.dismiss_cta")}
                </Button>
              </div>
            </div>
            <div className="tw-bg-black tw-h-[187] md:tw-h-[362px] tw-flex tw-w-full lg:tw-w-1/2">
              {showVideoPlayer ? (
                <LakeviewVideoPlayer
                  module={LAKEVIEW_VIDEO_POPUP_MIXPANEL_MODULE}
                />
              ) : (
                <LakeviewVideoThumbnailV2 onClick={handlePlayVideo} />
              )}
            </div>
            <div className="tw-flex lg:tw-hidden tw-flex-col lg:tw-flex-row tw-gap-4">
              <Button
                onClick={handleWatchVideo}
                variant="secondary"
                theme={UITheme.LAKEVIEW_BLUE}
                size="lg"
              >
                {t("lakeview_video_popup.watch_cta")}
              </Button>
              <Button
                onClick={handleDismissVideo}
                size="lg"
                theme={UITheme.LAKEVIEW_BLUE}
                variant="primary"
                className="border"
              >
                {t("lakeview_video_popup.dismiss_cta")}
              </Button>
            </div>
          </div>
        </GridCol>
      </GridContainer>
    </div>
  );
};

export default LakeviewVideoPopupContainer;
