import { Dict } from "mixpanel-browser";
import { SubservicerLink } from "../../../components/subservicer-link";
import {
  getSubservicerName,
  REDIRECT_TARGET,
} from "../../../helpers/subservicer";
import { useSelector } from "react-redux";
import { RootState } from "../../../stores";
import { ButtonProps } from "../../../atomic/atoms/button";

const MODULE = "manage-your-mortgage";

interface CTAButtons {
  translationKey?: string;
  subservicerLoanNumber?: string;
  eventParams?: Dict;
  buttonProps?: ButtonProps;
}

export const PayNowButton = ({
  subservicerLoanNumber = "",
  eventParams = {},
  buttonProps,
}: CTAButtons) => (
  <SubservicerLink
    isLinkTag={false}
    translationKey="home_value.mortgage.pay_now"
    module={MODULE}
    subservicerLoanNumber={subservicerLoanNumber}
    redirectTarget={REDIRECT_TARGET.PAYMENT}
    hasIcon
    isButton
    eventParams={{
      ...eventParams,
      button: "make-a-payment",
      subservicer_loan_number: subservicerLoanNumber,
    }}
    buttonProps={buttonProps}
  />
);

export const ManageSubservicerButton = ({
  translationKey = "home_value.mortgage.see_more",
  subservicerLoanNumber = "",
  eventParams = {},
}: CTAButtons) => {
  const { user: userState } = useSelector((state: RootState) => state);
  const subservicerName = getSubservicerName(userState.user);

  return (
    <SubservicerLink
      isLinkTag={false}
      translationKey={translationKey}
      module={MODULE}
      subservicerLoanNumber={subservicerLoanNumber}
      hasIcon
      isButton
      buttonProps={{ variant: "secondary" }}
      eventParams={{
        ...eventParams,
        button: `manage-on-${subservicerName}`,
        subservicer_loan_number: subservicerLoanNumber,
      }}
    />
  );
};
