import { ReactNode, useEffect } from "react";
import { cx } from "class-variance-authority";
import { MIXPANEL_EVENTS } from "analytics";
import { useSelector } from "react-redux";
import mixpanel from "mixpanel-browser";

import { closeModal } from "../../../stores/modal-slice";
import { dispatch, RootState } from "../../../stores";
import { kebabCase } from "../../../helpers/utils";
import { pages } from "../../../helpers/pages";
import {
  DEEP_LINKING_IDS,
  PERSONALIZED_OFFERS_MIXPANEL_MODULE,
  PERSONALIZED_OFFERS_POPUP_MIXPANEL_MODULE,
  SECTIONS_IDS,
} from "../../../helpers/constants";

import { ReactComponent as LogoLakeviewUnauthenticatedRebrandSVG } from "purple-rain/assets/logo-lakeview-unauthenticated-rebrand.svg";
import { ReactComponent as CloseIcon } from "purple-rain/assets/icons/modal-close.svg";
import { Button } from "../../../atomic/atoms/button";
import { GridCol, GridContainer, GridRow } from "ui";

interface GenericOfferCardProps {
  backgroundImageSrc: string;
  superTitle?: string;
  primaryTitle: ReactNode;
  body: ReactNode;
  footerBody?: ReactNode;
  ctaSection: ReactNode;
  disclaimerSection?: ReactNode;
  showFooterDivider?: boolean;
  isModal?: boolean;
}

const GenericOfferCard = ({
  backgroundImageSrc,
  superTitle,
  primaryTitle,
  body,
  footerBody,
  ctaSection,
  disclaimerSection,
  showFooterDivider,
  isModal,
}: GenericOfferCardProps) => {
  const refinance = useSelector(
    (state: RootState) => state.personalizeOffers?.refinance
  );
  const offerType = refinance?.offer_type as string;

  useEffect(() => {
    const module = isModal
      ? PERSONALIZED_OFFERS_POPUP_MIXPANEL_MODULE
      : PERSONALIZED_OFFERS_MIXPANEL_MODULE;

    mixpanel.track(MIXPANEL_EVENTS.MODULE_SERVED, {
      module,
      offer_type: kebabCase(offerType),
      path: pages.home,
    });
  }, [isModal, offerType]);

  const handleCloseButton = () => {
    mixpanel.track(MIXPANEL_EVENTS.CLICK, {
      offer_type: kebabCase(offerType),
      module: isModal
        ? PERSONALIZED_OFFERS_POPUP_MIXPANEL_MODULE
        : PERSONALIZED_OFFERS_MIXPANEL_MODULE,
      path: pages.home,
      button: "x",
      link: pages.home,
    });
    dispatch(closeModal());
  };

  return (
    <GridContainer containerClassName={cx(isModal && "tw-p-0")}>
      <GridCol>
        <div
          className="tw-relative tw-flex tw-justify-center tw-items-center tw-px-4 md:tw-px-0 tw-py-8 md:tw-py-16"
          id={DEEP_LINKING_IDS.GENERIC_PERSONALIZED_OFFERS_MODULE_ID}
        >
          <img
            className="tw-absolute tw-w-full tw-h-full tw-object-cover"
            src={backgroundImageSrc}
            alt=""
          />
          <GridRow>
            <GridCol xs={12} md={{ span: 10, offset: 1 }}>
              <div className="tw-relative tw-bg-white tw-py-10 tw-rounded-lg tw-text-center">
                {isModal && (
                  <Button
                    className="!tw-absolute tw-top-4 tw-end-4"
                    variant="ghost"
                    size="sm"
                    onClick={handleCloseButton}
                    aria-label="Close"
                  >
                    <CloseIcon />
                  </Button>
                )}
                <GridContainer>
                  <GridRow className="tw-text-pretty">
                    <GridCol xs={12} md={{ span: 10, offset: 1 }}>
                      <div className="tw-mb-8">
                        <LogoLakeviewUnauthenticatedRebrandSVG
                          width={110}
                          height="100%"
                        />
                      </div>
                      {superTitle && (
                        <h5 className="tw-text-h5 tw-text-secondary tw-font-sans tw-uppercase tw-font-bold tw-mb-4">
                          {superTitle}
                        </h5>
                      )}
                      <h3 className="tw-text-h3 tw-font-serif tw-text-heading tw-mb-6 md:tw-mb-8">
                        {primaryTitle}
                      </h3>
                    </GridCol>
                    <GridCol xs={12} md={{ span: 10, offset: 1 }}>
                      <div className="tw-text-large tw-mb-6 md:tw-mb-8 tw-text-primary">
                        {body}
                      </div>
                      {showFooterDivider && (
                        <hr className="tw-hidden md:tw-block tw-text-border-lines tw-opacity-100 tw-mb-6 md:tw-mb-8" />
                      )}
                    </GridCol>
                    {footerBody && (
                      <GridCol xs={12} md={{ span: 10, offset: 1 }}>
                        {footerBody}
                      </GridCol>
                    )}
                    <GridCol
                      xs={12}
                      md={
                        isModal
                          ? { span: 8, offset: 2 }
                          : { span: 10, offset: 1 }
                      }
                      xl={isModal ? { span: 6, offset: 3 } : undefined}
                    >
                      {ctaSection}
                    </GridCol>
                    {disclaimerSection && (
                      <GridCol xs={12} md={{ span: 10, offset: 1 }}>
                        <div
                          className="tw-pt-6 tw-mt-6 md:tw-pt-8 md:tw-mt-8 tw-border-solid tw-border-0 tw-border-t tw-border-border-lines tw-text-start tw-text-fine tw-text-secondary"
                          id={
                            SECTIONS_IDS.GENERIC_PERSONALIZED_OFFERS_POPUP_DISCLAIMER_ID
                          }
                        >
                          {disclaimerSection}
                        </div>
                      </GridCol>
                    )}
                  </GridRow>
                </GridContainer>
              </div>
            </GridCol>
          </GridRow>
        </div>
      </GridCol>
    </GridContainer>
  );
};

export default GenericOfferCard;
