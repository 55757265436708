import { ReactComponent as LogoLakeviewUnauthenticatedRebrandSVG } from "purple-rain/assets/logo-lakeview-unauthenticated-rebrand.svg";
import { translate as t } from "../../../helpers/i18n";
import { Link } from "react-router-dom";
import { pages } from "../../../helpers/pages";
import LegalDisclaimerV2 from "./legal-disclaimer-v2";
import FooterBaseV2 from "./footer-base-v2";
import useStandaloneMode from "../../../hooks/use-standalone";
import { EXPERIMENTS, useABTest } from "../../../hooks/use-ab-test";
import mixpanel from "mixpanel-browser";
import { MIXPANEL_EVENTS } from "analytics";
import { useDashboardVersion } from "../../../hooks";
import { DashboardType } from "../../../types";
import {
  NewExperimentNameGroup,
  NoneExperimentGroup,
} from "../../../helpers/experiment-exposure";
import useExperimentManualExposure from "../../../hooks/use-experiment-manual-exposure";
import { FEATURE_GATES, useFeatureGate } from "../../../hooks/use-feature-gate";
import { GridCol, GridContainer, UILogo } from "ui";
import { EXTERNAL_LINKS } from "../../../helpers/constants";
import { ExternalLink } from "../../../components/external-link";
import { useIsUserAuthenticated } from "../../../hooks/use-is-user-authenticated";

const MIXPANEL_MODULE_NAME = "footer";

interface ListItem {
  translationKey: string;
  path: string;
  options?: {
    isExternal?: boolean;
    featureGate?: string;
    mixpanelButtonName?: string;
  };
}

const PRODUCT_LIST = (includeHomeEquityLoan?: boolean): ListItem[] => [
  // Cash Out Refinance
  {
    translationKey: "layout.products_list.cash_out_refinance.secondary_title",
    path: pages.products.cashOutRefinance,
    options: {
      mixpanelButtonName: "go-to-cashout-pdp",
    },
  },
  {
    translationKey: "layout.products_list.home_equity_loan.secondary_title",
    path: includeHomeEquityLoan
      ? pages.products.homeEquityLoan
      : pages.products.cashOutRefinance,
    options: {
      mixpanelButtonName: "go-to-heloan-pdp",
    },
  },

  // Get a Mortgage
  {
    translationKey: "layout.products_list.get_mortgage.secondary_title",
    path: pages.products.getAMortgage,
    options: {
      mixpanelButtonName: "go-to-mortgage-pdp",
    },
  },
  // Rate Term Refinance
  {
    translationKey: "layout.products_list.rate_term_refinance.secondary_title",
    path: pages.products.rateTermRefinance,
    options: {
      mixpanelButtonName: "go-to-rtr-pdp",
    },
  },
];

const RESOURCES_LIST: ListItem[] = [
  {
    translationKey: "layout.footer.resource_hub",
    path: pages.resourceCenter,
  },
  {
    translationKey: "layout.footer.faq_and_support",
    path: pages.frequentlyAskedQuestions,
  },
  {
    translationKey: "layout.footer.hmda",
    path: EXTERNAL_LINKS.HMDA,
    options: {
      isExternal: true,
      featureGate: FEATURE_GATES.ENABLE_LAKEVIEW_PDPS,
    },
  },
  {
    translationKey: "layout.footer.licensing",
    path: EXTERNAL_LINKS.LICENSING,
    options: {
      isExternal: true,
      featureGate: FEATURE_GATES.ENABLE_LAKEVIEW_PDPS,
    },
  },
  {
    translationKey: "layout.footer.terms_and_conditions",
    path: pages.termsConditions,
  },
];

const renderItemList = (
  itemList: ListItem[],
  mixpanelPageName: `/${DashboardType}`,
  experimentManualExposure,
  groupName: NewExperimentNameGroup | NoneExperimentGroup = "None"
) => {
  const filteredList = itemList.filter(
    (item) =>
      !item.options?.featureGate ||
      useFeatureGate(item.options?.featureGate || "")
  );

  const handleClick = (item: ListItem) => {
    const link = `${window.location.origin}${item.path}`;

    if (item.options?.mixpanelButtonName) {
      mixpanel.track(MIXPANEL_EVENTS.CLICK, {
        module: MIXPANEL_MODULE_NAME,
        button: item.options.mixpanelButtonName,
        page: mixpanelPageName,
        link,
      });
    }

    if (
      t(item.translationKey) ===
      t("layout.products_list.home_equity_loan.secondary_title")
    ) {
      experimentManualExposure(EXPERIMENTS.TAVANT_DIGITAL_HELOAN, groupName, [
        "Control1",
        "Treatment1",
      ]);
    }
  };

  const renderItem = (item: ListItem) => {
    const commonLinkProps = {
      key: item.translationKey,
      onClick: () => handleClick(item),
      className: "tw-no-underline tw-text-deepBlue-950 tw-font-sans",
    };

    return item.options?.isExternal ? (
      <ExternalLink {...commonLinkProps} href={item.path} targetBlank>
        {t(item.translationKey)}
      </ExternalLink>
    ) : (
      <Link {...commonLinkProps} to={item.path}>
        {t(item.translationKey)}
      </Link>
    );
  };

  return (
    <div className="tw-flex tw-flex-col tw-align-start tw-gap-3">
      {filteredList.map((item) => renderItem(item))}
    </div>
  );
};

const FooterV2 = ({ showProducts = true, showResources = true }) => {
  const isStandalone = useStandaloneMode();
  const { isExperimentAvailable: isHeloanHomeEquityLoanActive, groupName } =
    useABTest(EXPERIMENTS.TAVANT_DIGITAL_HELOAN);
  const experimentManualExposure = useExperimentManualExposure();
  const { mixpanelPageName } = useDashboardVersion();
  const isUserAuthenticated = useIsUserAuthenticated();

  const iconLakeviewLogo = () => {
    if (isUserAuthenticated) return <UILogo logo="lakeviewFastlane" />;

    return <LogoLakeviewUnauthenticatedRebrandSVG width={110} />;
  };

  if (isStandalone)
    return <footer className="tw-bg-slateGray-50 tw-w-full tw-py-2" />;

  const itemsStyle =
    "tw-mb-4 tw-text-h6 tw-font-bold tw-font-sans tw-uppercase tw-text-slateGray-625";

  return (
    <FooterBaseV2 includeDisclosures>
      <GridContainer containerClassName="tw-p-0" gridClassName="tw-gap-y-8">
        <GridCol className="md:tw-col-span-3">{iconLakeviewLogo()}</GridCol>
        {showProducts && (
          <GridCol className="md:tw-col-span-4 xl:tw-col-span-3">
            <h6 className={itemsStyle}>{t("layout.footer.products_title")}</h6>
            {renderItemList(
              PRODUCT_LIST(isHeloanHomeEquityLoanActive),
              mixpanelPageName,
              experimentManualExposure,
              groupName
            )}
          </GridCol>
        )}
        {showResources && (
          <GridCol className="md:tw-col-span-4 xl:tw-col-span-3">
            <h6 className={itemsStyle}>{t("layout.footer.resources_title")}</h6>
            {renderItemList(
              RESOURCES_LIST,
              mixpanelPageName,
              experimentManualExposure
            )}
          </GridCol>
        )}
      </GridContainer>
      <hr className="tw-w-full tw-text-slateGray-125 tw-opacity-100 tw-m-0" />
      {/* Legal disclaimer */}
      <LegalDisclaimerV2 />
    </FooterBaseV2>
  );
};

export default FooterV2;
