import { closeModal, GenericModalInitialConfig } from "../stores/modal-slice";
import { dispatch } from "../stores";
import { translate as t } from "./i18n";
import { GenericBaseModalProps } from "../atomic/organisms/modal/generic-base-modal";
import { updateHomeRenovationExitModalState } from "../stores/home-renovation-slice";
import { cn } from "ui";
import { Button } from "../atomic/atoms/button";
import ModalButtonsWrapper from "../atomic/organisms/modal/components/modal-buttons-wrapper";
import mixpanel from "mixpanel-browser";
import { MIXPANEL_EVENTS } from "analytics";
import {
  KUKUN_MIXPANEL_BUTTON,
  KUKUN_MIXPANEL_MODULES,
  KUKUN_MIXPANEL_SHARED_PROPERTIES,
} from "./constants";

export const OfferModalConfig: GenericBaseModalProps = {
  contentClassName: "tw-p-0",
  gridSize: 12,
  onCloseButtonClick: undefined,
  centered: false,
  scrollable: false,
  onHide: () => dispatch(closeModal()),
};

export const KukunHomeEstimationConfig: GenericBaseModalProps = {
  bodyClassName: cn(
    GenericModalInitialConfig.bodyClassName,
    "tw-font-sans tw-text-primary"
  ),
  title: t("home_value.home_renovation.modal.title"),
  titleClassName:
    "tw-grow tw-mb-0 tw-mr-4 !tw-font-sans tw-text-h6 tw-text-secondary tw-uppercase tw-font-normal",
  gridSize: 12,
  centered: false,
  onCloseButtonClick: () => {
    mixpanel.track(MIXPANEL_EVENTS.CLICK, {
      module: KUKUN_MIXPANEL_MODULES.HOME_RENOVATION_POPUP,
      button: KUKUN_MIXPANEL_BUTTON.CLOSE_POPUP,
      ...KUKUN_MIXPANEL_SHARED_PROPERTIES,
    });
    dispatch(updateHomeRenovationExitModalState({ isExitModalVisible: true }));
  },
  onHide: () => {
    mixpanel.track(MIXPANEL_EVENTS.CLICK, {
      module: KUKUN_MIXPANEL_MODULES.HOME_RENOVATION_POPUP,
      button: KUKUN_MIXPANEL_BUTTON.MODAL_BACKDROP,
      ...KUKUN_MIXPANEL_SHARED_PROPERTIES,
    });
    dispatch(updateHomeRenovationExitModalState({ isExitModalVisible: true }));
  },
};

export const KukunErrorModalConfig: GenericBaseModalProps = {
  title: t("home_value.home_renovation.modal.error.title"),
  gridSize: 6,
  centered: false,
  onCloseButtonClick: () => {
    mixpanel.track(MIXPANEL_EVENTS.CLICK, {
      module: KUKUN_MIXPANEL_MODULES.HOME_RENOVATION_ERROR_POPUP,
      button: KUKUN_MIXPANEL_BUTTON.CLOSE_POPUP,
      ...KUKUN_MIXPANEL_SHARED_PROPERTIES,
    });
    dispatch(closeModal());
  },
  children: <p>{t("home_value.home_renovation.modal.error.description")}</p>,
  buttons: (
    <ModalButtonsWrapper>
      <Button
        onClick={() => {
          mixpanel.track(MIXPANEL_EVENTS.CLICK, {
            module: KUKUN_MIXPANEL_MODULES.HOME_RENOVATION_ERROR_POPUP,
            button: t("home_value.home_renovation.modal.error.button.primary"),
            ...KUKUN_MIXPANEL_SHARED_PROPERTIES,
          });
          dispatch(closeModal());
        }}
      >
        {t("home_value.home_renovation.modal.error.button.primary")}
      </Button>
    </ModalButtonsWrapper>
  ),
};

export const GoalsModalConfig: GenericBaseModalProps = {
  contentClassName: "tw-p-0",
  gridSize: 12,
  onCloseButtonClick: undefined,
  centered: false,
  scrollable: false,
  onHide: () => dispatch(closeModal()),
};
