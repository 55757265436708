import { Trans } from "react-i18next";
import { SubservicerLink } from "../../../components/subservicer-link";
import { formatDate } from "../../../helpers/dateFormatter";
import { translate as t } from "../../../helpers/i18n";
import { Account } from "../../../types";
import { useDashboardVersion } from "../../../hooks";

interface MortgageUpdatedInfoProps {
  account: Account;
  lineBreak?: boolean;
  shouldRenderSubservicerLink?: boolean;
}

export const MortgageUpdatedInfo = ({
  account,
  shouldRenderSubservicerLink = true,
  lineBreak = false,
}: MortgageUpdatedInfoProps) => {
  const lastUpdated = formatDate(account?.last_updated_at);
  const { mixpanelPageName } = useDashboardVersion();

  return (
    <span className="text-indigo-2">
      {t("home_value.mortgage.last_updated", {
        interpolation: { escapeValue: false },
        lastUpdated,
      })}
      {lineBreak ? <br /> : ". "}
      {shouldRenderSubservicerLink && (
        <Trans
          i18nKey="home_value.mortgage.latest_information"
          components={{
            subservicer: (
              <SubservicerLink
                translationKey="home_value.mortgage.visit_subservicer"
                module="manage-your-mortgage"
                subservicerLoanNumber={account.subservicer_loan_number}
                eventParams={{
                  page: mixpanelPageName,
                }}
              />
            ),
          }}
          transSupportBasicHtmlNodes
        />
      )}
    </span>
  );
};
