import { useCallback, useRef, useState } from "react";
import { Link } from "react-router-dom";
import Offcanvas from "react-bootstrap/Offcanvas";
import Collapse from "react-bootstrap/Collapse";
import Overlay from "react-bootstrap/Overlay";
import { ReactComponent as CloseIconSVG } from "purple-rain/assets/close-icon.svg";
import { ReactComponent as HamburgerSVG } from "purple-rain/icons/hamburger.svg";
import { translate as t } from "../../../helpers/i18n";
import { pages } from "../../../helpers/pages";
import { AuthenticatedButton, HeaderLoginLink } from "./header-auth";
import {
  HeaderProductMenuItem,
  HOME_EQUITY_LOAN_TRANSLATION_KEY,
  PRODUCT_LIST,
} from "../../../helpers/constants";
import { EXPERIMENTS, useABTest } from "../../../hooks/use-ab-test";
import useExperimentManualExposure from "../../../hooks/use-experiment-manual-exposure";
import mixpanel from "mixpanel-browser";
import { MIXPANEL_EVENTS } from "analytics";
import { useDashboardVersion } from "../../../hooks";
import { cx } from "class-variance-authority";
import { Chevron, cn, UITheme } from "ui";
import { useIsUserAuthenticated } from "../../../hooks/use-is-user-authenticated";
import { FEATURE_GATES, useFeatureGate } from "../../../hooks/use-feature-gate";

const MIXPANEL_MODULE_NAME = "header";

const trackProductClick = (
  productMenuItem: HeaderProductMenuItem,
  mixpanelPageName: string
) => {
  const link = `${window.location.origin}${productMenuItem.path}`;

  mixpanel.track(MIXPANEL_EVENTS.CLICK, {
    module: MIXPANEL_MODULE_NAME,
    button: productMenuItem.mixpanelButtonName,
    page: mixpanelPageName,
    link,
  });
};

// Dropdown Menu displayed on the header for desktop resolutions
export const ProductsMenuV2 = () => {
  const [showMenu, setShowMenu] = useState(false);
  const { isExperimentAvailable: isHeloanHomeEquityLoanActive, groupName } =
    useABTest(EXPERIMENTS.TAVANT_DIGITAL_HELOAN);
  const { mixpanelPageName } = useDashboardVersion();
  const experimentManualExposure = useExperimentManualExposure();
  const isUserAuthenticated = useIsUserAuthenticated();

  // Ref for the product menu
  const productMenu = useRef(null);

  const handleToggleMenu = useCallback(
    () => setShowMenu(!showMenu),
    [showMenu]
  );
  const handleCloseMenu = useCallback(
    (translationKey?: string) => {
      const experimentConditionToMakeExposure =
        groupName !== "None" &&
        translationKey === HOME_EQUITY_LOAN_TRANSLATION_KEY;
      experimentManualExposure(
        EXPERIMENTS.TAVANT_DIGITAL_HELOAN,
        groupName,
        ["Control1", "Treatment1"],
        experimentConditionToMakeExposure
      );
      setShowMenu(false);
    },
    [groupName, experimentManualExposure]
  );

  const homeUrl = isUserAuthenticated ? pages.home : pages.index;

  const handleProductClick = (productMenuItem: HeaderProductMenuItem) => {
    trackProductClick(productMenuItem, mixpanelPageName);
    handleCloseMenu(productMenuItem.translationKey);
  };

  const handleResourceHubClick = () => {
    mixpanel.track(MIXPANEL_EVENTS.CLICK, {
      module: MIXPANEL_MODULE_NAME,
      button: "resource-hub",
      page: mixpanelPageName,
    });

    handleCloseMenu();
  };

  const productMenuLinksStyle =
    "tw-text-nowrap tw-no-underline tw-font-sans tw-text-primary";

  return (
    <>
      {/* Menu bar */}
      <div className="tw-hidden lg:tw-flex lg:tw-px-16 lg:tw-items-center lg:tw-gap-x-8">
        <Link
          to={homeUrl}
          onClick={() => handleCloseMenu()}
          className={productMenuLinksStyle}
        >
          <span>{t("layout.header.links.home")}</span>
        </Link>
        <div
          role="button"
          onClick={handleToggleMenu}
          className={cx(productMenuLinksStyle, "tw-border-0 tw-bg-transparent")}
        >
          <span>{t("layout.header.links.products")}</span>
          <span className="tw-ml-2">
            <Chevron variant="rebrand" direction={showMenu ? "up" : "down"} />
          </span>
        </div>
        <Link
          to={pages.resourceCenter}
          onClick={handleResourceHubClick}
          className={productMenuLinksStyle}
        >
          <span>{t("layout.header.links.resource_hub")}</span>
        </Link>
      </div>

      {/* Collapsible menu container */}
      <div
        ref={productMenu}
        className={cx(
          "tw-absolute tw-w-full tw-left-0 tw-bg-slateGray-50 tw-z-1000",
          isUserAuthenticated ? "tw-top-[92px]" : "tw-top-[76px]"
        )}
      />

      {/* Collapsible menu content */}
      <Overlay
        target={productMenu}
        container={productMenu}
        containerPadding={-5}
        show={showMenu}
        onHide={() => handleCloseMenu()}
        placement="bottom"
        rootClose
        rootCloseEvent="click"
        transition={Collapse}
      >
        <div
          id="products-menu"
          className="tw-w-full tw-z-100 tw-bg-slateGray-50"
        >
          <div className="tw-container">
            <div className="tw-gap-4 tw-py-8 tw-hidden lg:tw-flex tw-justify-center tw-flex-wrap ">
              {PRODUCT_LIST(isHeloanHomeEquityLoanActive).map((product) => (
                <Link
                  to={product.path}
                  onClick={() => handleProductClick(product)}
                  key={product.translationKey}
                  className="tw-flex-1 tw-no-underline tw-rounded tw-p-4 hover:tw-shadow-1 hover:tw-border-transparent hover:tw-bg-white tw-transition-all tw-duration-200 tw-ease-in-out "
                >
                  <div className="tw-font-bold tw-text-primary">
                    {t(
                      `layout.products_list.${product.translationKey}.secondary_title`
                    )}
                  </div>
                  <div className="tw-text-primary">
                    {t(
                      `layout.products_list.${product.translationKey}.description`
                    )}
                  </div>
                </Link>
              ))}
            </div>
          </div>
        </div>
      </Overlay>
    </>
  );
};

// This will be rebranded on https://fastlanefi.atlassian.net/browse/CLF-4499

// Hamburger menu displayed on the header for mobile resolutions
export const HamburgerMenuV2 = () => {
  const [showMenu, setShowMenu] = useState(false);
  const { isExperimentAvailable: isHeloanHomeEquityLoanActive, groupName } =
    useABTest(EXPERIMENTS.TAVANT_DIGITAL_HELOAN);
  const { mixpanelPageName } = useDashboardVersion();
  const experimentManualExposure = useExperimentManualExposure();
  const isUserAuthenticated = useIsUserAuthenticated();

  const isLakeviewV2Enabled = useFeatureGate(FEATURE_GATES.ENABLE_LAKEVIEW_2_0);

  const handleCloseMenu = useCallback(
    (translationKey?: string) => {
      experimentManualExposure(
        EXPERIMENTS.TAVANT_DIGITAL_HELOAN,
        groupName,
        ["Control1", "Treatment1"],
        groupName !== "None" &&
          translationKey === HOME_EQUITY_LOAN_TRANSLATION_KEY
      );
      setShowMenu(false);
    },
    [groupName, experimentManualExposure]
  );

  const handleProductClick = (productMenuItem: HeaderProductMenuItem) => {
    trackProductClick(productMenuItem, mixpanelPageName);
    handleCloseMenu(productMenuItem.translationKey);
  };

  const handleResourceHubClick = () => {
    mixpanel.track(MIXPANEL_EVENTS.CLICK, {
      module: MIXPANEL_MODULE_NAME,
      button: "resource-hub",
      page: mixpanelPageName,
    });

    handleCloseMenu();
  };

  const handleShow = useCallback(() => setShowMenu(true), []);
  const homeUrl = isUserAuthenticated ? pages.home : pages.index;

  const linkContainerStyle =
    "tw-block tw-w-full tw-rounded tw-no-underline tw-text-primary tw-font-sans tw-px-4 tw-py-3 hover:tw-bg-white hover:tw-shadow-1";

  const divider = (
    <div className="tw-py-2">
      <hr className="tw-text-slateGray-125 tw-opacity-100 tw-w-full tw-my-0" />
    </div>
  );

  return (
    <>
      <div
        className="tw-text-primary tw-my-auto tw-mr-6 tw-inline lg:tw-hidden"
        onClick={handleShow}
        aria-label={t("layout.header.hamburger.label")}
        role="button"
      >
        <HamburgerSVG width={24} height={24} className="tw-text-primary" />
      </div>
      <Offcanvas
        placement="start"
        show={showMenu}
        onHide={() => handleCloseMenu()}
        className="lg:tw-hidden tw-bg-slateGray-50"
        backdropClassName={cn(
          "lg:tw-hidden",
          isLakeviewV2Enabled
            ? "tw-bg-surface-overlay tw-opacity-40"
            : "tw-bg-darkMutedBlue-800/50"
        )}
      >
        <Offcanvas.Header className="tw-p-4">
          <div onClick={() => handleCloseMenu()} role="button">
            <CloseIconSVG
              className="tw-text-deepBlue-625"
              width={16}
              height={16}
            />
          </div>
        </Offcanvas.Header>
        <Offcanvas.Body className="lg:tw-hidden tw-p-4 tw-pt-0">
          <div className="tw-h-full tw-flex tw-flex-col tw-justify-between">
            {/* Links section */}
            <div className="tw-flex tw-flex-col tw-gap-0.5">
              <Link
                to={homeUrl}
                onClick={() => handleCloseMenu()}
                className={linkContainerStyle}
              >
                {t("layout.header.links.home")}
              </Link>
              {divider}
              <h6 className="tw-text-h6 tw-text-primary tw-font-sans tw-font-normal tw-uppercase tw-py-4 tw-m-0">
                {t(`layout.header.links.products`)}
              </h6>
              <div className="tw-flex tw-flex-col tw-items-start">
                {PRODUCT_LIST(isHeloanHomeEquityLoanActive).map((product) => (
                  <Link
                    to={product.path}
                    onClick={() => handleProductClick(product)}
                    key={product.translationKey}
                    className={linkContainerStyle}
                  >
                    {t(
                      `layout.products_list.${product.translationKey}.secondary_title`
                    )}
                  </Link>
                ))}
              </div>
              {divider}
              <Link
                to={pages.resourceCenter}
                onClick={handleResourceHubClick}
                className={linkContainerStyle}
              >
                {t("layout.header.links.resource_hub")}
              </Link>
            </div>
            {/* Login/Logout section */}
            {isUserAuthenticated ? (
              <AuthenticatedButton
                className="tw-w-full"
                theme={UITheme.LAKEVIEW_BLUE}
                variant="primary"
              />
            ) : (
              <HeaderLoginLink
                className="tw-w-full"
                theme={UITheme.LAKEVIEW_BLUE}
                variant="primary"
              />
            )}
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};
